import {
  GET_INVESTMENTS_ATTEMPT,
  GET_INVESTMENTS_SUCCESS,
  GET_INVESTMENTS_FAILURE,
  GET_DASHBOARD_ATTEMPT,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_SUCCESS,
  CLEAR_STATE,
  SECONDARY_SALE_ATTEMPT,
  SECONDARY_SALE_SUCCESS,
  SECONDARY_SALE_FAILURE,
  EXPORT_PDF,
} from './actionTypes';

export const initialState = {
  investments: null,
  dashboard: null,
  attempting: false,
  errorCode: null,
  exportPdf: false,
  secondarySaleRequested: {},
  lastDashboardFetch: null,
};

export const DashboardReducer = (state = initialState, action) => {
  let secondarySaleRequested = { ...state.secondarySaleRequestedSuccess };

  switch (action.type) {
    case GET_INVESTMENTS_ATTEMPT:
      return {
        ...state,
        investments: null,
        attempting: true,
        errorCode: null,
      };
    case GET_INVESTMENTS_SUCCESS:
      return {
        ...state,
        investments: action.investments,
        attempting: false,
        errorCode: null,
      };
    case GET_INVESTMENTS_FAILURE:
      return {
        ...state,
        investments: null,
        attempting: false,
        errorCode: action.errorCode,
      };

    case GET_DASHBOARD_ATTEMPT:
      return {
        ...state,
        dashboard: null,
        attempting: true,
        errorCode: null,
        lastDashboardFetch: new Date().getTime(), // Store the current time when the dashboard was fetched
      };

    case GET_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboard: null,
        attempting: false,
        errorCode: action.errorCode,
      };

    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.dashboard,
        attempting: false,
        errorCode: null,
        secondarySaleRequested: {},
      };
    case SECONDARY_SALE_ATTEMPT:
      secondarySaleRequested[action.investmentId] = { Success: false, Failure: null, Attempting: true };
      return {
        ...state,
        secondarySaleRequested: secondarySaleRequested,
      };

    case SECONDARY_SALE_FAILURE:
      secondarySaleRequested[action.investmentId] = {
        Success: false,
        Failure: action.errorCode,
        Attempting: false,
      };
      return {
        ...state,
        secondarySaleRequested: secondarySaleRequested,
      };
    case SECONDARY_SALE_SUCCESS:
      secondarySaleRequested[action.investmentId] = { Success: action.result, Failure: null, Attempting: false };
      return {
        ...state,
        secondarySaleRequested: secondarySaleRequested,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    case EXPORT_PDF:
      return {
        ...state,
        exportPdf: action.exportPdf,
      };
    default:
      return state;
  }
};
