import {
  GET_ROUNDS_ATTEMPT,
  GET_ROUNDS_SUCCESS,
  GET_ROUNDS_FAILURE,
  SUBMIT_ATTEMPT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,
  CLEAR_UPLOADER_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  rounds: null,
  submitRes: null,
  attempting: false,
  attemptingSubmit: false,
  errorCode: null,
};

export const DocumentsUploaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROUNDS_ATTEMPT:
      return {
        ...state,
        rounds: null,
        attempting: true,
        errorCode: null,
      };
    case GET_ROUNDS_FAILURE:
      return {
        ...state,
        rounds: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_ROUNDS_SUCCESS:
      return {
        ...state,
        rounds: action.rounds,
        errorCode: null,
        attempting: false,
      };
    case SUBMIT_ATTEMPT:
      return {
        ...state,
        submitRes: null,
        attemptingSubmit: true,
        errorCode: null,
      };
    case SUBMIT_FAILURE:
      return {
        ...state,
        submitRes: null,
        errorCode: action.errorCode,
        attemptingSubmit: false,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        submitRes: action.submitRes,
        errorCode: null,
        attemptingSubmit: false,
      };
    case CLEAR_UPLOADER_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
