import {
  IDENTIFY_USER_FAILURE_CLEAR,
  SET_FORCE_GET_USER_INFO,
  IDENTIFY_USER_ATTEMPT,
  IDENTIFY_USER_FAILURE,
  IDENTIFY_USER_SUCCESS,
  CLEAR_AUTH_STATE,
  SET_USER_ATTEMPT,
  SET_USER_FAILURE,
  SET_USER_SUCCESS,
  REFRESH_TRAITS_ATTEMPT,
  REFRESH_TRAITS_FAILURE,
  REFRESH_TRAITS_SUCCESS,
  CLEAR_USER_STATE,
  SET_AUTH_RESULT,
  CLEAR_STATE,
} from './actionTypes';

export const setAuthResult = (authResult) => ({
  type: SET_AUTH_RESULT,
  authResult,
});

export const setForceUserInfo = (forceGetUserInfo) => ({
  type: SET_FORCE_GET_USER_INFO,
  forceGetUserInfo,
});

export const identifyUserAttempt = () => ({
  type: IDENTIFY_USER_ATTEMPT,
});

export const identifyUserSuccess = (traits) => ({
  type: IDENTIFY_USER_SUCCESS,
  traits,
});

export const identifyUserFailure = (errorCode) => ({
  type: IDENTIFY_USER_FAILURE,
  errorCode,
});

export const setUserAttempt = () => ({
  type: SET_USER_ATTEMPT,
});

export const setUserSuccess = (user) => ({
  type: SET_USER_SUCCESS,
  user,
});

export const setUserFailure = (errorCode) => ({
  type: SET_USER_FAILURE,
  errorCode,
});

export const clearIdentifyFailureState = () => ({
  type: IDENTIFY_USER_FAILURE_CLEAR,
});

export const refreshTraitsAttempt = () => ({
  type: REFRESH_TRAITS_ATTEMPT,
});

export const refreshTraitsSuccess = (traits) => ({
  type: REFRESH_TRAITS_SUCCESS,
  traits,
});

export const refreshTraitsFailure = (errorCode) => ({
  type: REFRESH_TRAITS_FAILURE,
  errorCode,
});

export const clearUserState = () => ({
  type: CLEAR_USER_STATE,
});

export const clearAuthState = () => ({
  type: CLEAR_AUTH_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
