import React, { useCallback } from 'react';

// mui utils
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';

import { ACCREDITATION_PAGES } from '../../helpers/constants';

// third party lib
import PhoneInput from 'react-phone-input-2';
import _ from 'lodash';

// iangels utils
import { loadConfig } from '../../env/config_util';

// json
import countries from '../../components/SharedData/countries.json';
import states from '../../components/SharedData/states.json';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

// styles
import 'react-phone-input-2/lib/material.css';

const RegisterForm = ({
  setValues,
  values,
  termsAndConditions,
  setTermsAndConditions,
  errors,
  setErrors,
  setPhone,
  phone,
}) => {
  const isMobile = IsMobileDevice();
  const config = loadConfig();
  const marketing_website_base_url = config.config_module.marketing_website_base_url;

  const handleChange = useCallback(
    (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
      if (!/^[A-Za-z0-9\-_ ()/."\\]*$/.test(event.target.value)) {
        setErrors({
          ...errors,
          [event.target.name]: `Only English and special characters are allowed.`,
        });
      } else {
        setErrors({
          ...errors,
          [event.target.name]: '',
        });
      }
    },
    [values, setValues, errors, setErrors]
  );

  const handleCountryChange = (event) => {
    setCountryChange(event.target.value);
  };

  const setCountryChange = useCallback(
    (inputCountry) => {
      setValues({
        ...values,
        country: inputCountry,
        countryCode: inputCountry.code || '',
        stateCode: '',
      });
    },
    [values, setValues]
  );

  const handleStateChange = (event) => {
    setValues({
      ...values,
      state: event.target.value,
      stateCode: event.target.value.abbreviation,
    });
  };

  const handleTermsChange = (event) => {
    setTermsAndConditions(event.target.checked);
    errors.terms = '';
  };

  const handleReceiveEmailChange = (event) => {
    setValues({
      ...values,
      allowCommunication: event.target.value,
    });
    errors.allowCommunication = '';
  };

  const handlePhoneChange = useCallback(
    (value, _data, _e, formattedValue) => {
      const country = _.find(countries, { code: _data?.countryCode?.toUpperCase() });
      if (_e.target?.nodeName !== 'INPUT') {
        setCountryChange(country);
      }
      setPhone(formattedValue);
    },
    [setCountryChange, setPhone]
  );

  const hasState = values.country?.code === 'US';

  const getAccreditationText = useCallback(() => {
    if (!values.country?.code) {
      return <span>I am an accredited Investor under the securities laws and regulations of my country</span>;
    }
    if (ACCREDITATION_PAGES.indexOf(values.country.code) !== -1) {
      return (
        <span className="justify">
          I meet the
          <span className="g-c pointer" onClick={() => window.open(`/accreditation/${values.country?.code}`, '_blank')}>
            {' '}
            accreditation requirements{' '}
          </span>
          in {values.country.name}
        </span>
      );
    }
    return (
      <span className="justify">
        I am an accredited Investor under the securities laws and regulations of {values.country.name}
      </span>
    );
  }, [values.country]);

  const handleRadioChange = (event) => {
    setValues({
      ...values,
      accredited: event.target.value,
    });
  };

  return (
    <div
      style={
        isMobile
          ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }
          : { display: 'flex', justifyContent: 'center', flexWrap: 'wrap', maxWidth: 900 }
      }
      className="f-gl-400 lh-1-5"
    >
      <TextField
        className="text-field min-w-300 m-10"
        variant="outlined"
        margin="normal"
        required
        id="firstName"
        label="First Name"
        name="firstName"
        autoComplete="firstName"
        onChange={handleChange}
        value={values.firstName}
        error={errors.firstName !== ''}
        helperText={errors.firstName}
        autoFocus
      />
      <TextField
        className="text-field min-w-300 m-10"
        variant="outlined"
        margin="normal"
        required
        id="lastName"
        label="Last Name"
        name="lastName"
        value={values.lastName}
        autoComplete="lastName"
        onChange={handleChange}
        error={errors.lastName !== ''}
        helperText={errors.lastName}
        autoFocus
      />
      <div>
        <PhoneInput
          className="text-field min-w-300 m-10"
          containerClass="f-gl-400 phone-container"
          inputClass={`f-gl-400 phone-input" ${errors.phone !== '' ? ' phone-input-error' : ''}`}
          preferredCountries={['il', 'us', 'gb', 'ca']}
          country={phone?.countryCode || 'il'}
          value={phone}
          onChange={handlePhoneChange}
          specialLabel="Phone *"
          error={errors.phone !== ''}
          helperText={errors.phone}
        />
        {errors.phone !== '' ? (
          <div style={{ color: '#d32f2f', fontSize: 12, marginLeft: 20, marginBottom: 5 }}>{errors.phone}</div>
        ) : null}
      </div>

      <FormControl variant="outlined" className="text-field min-w-300 m-10">
        <InputLabel id="country-select-standard-label" style={{ background: '#fff', padding: '0 5px' }}>
          Country
        </InputLabel>
        <Select
          labelId="country-select-standard-label"
          id="country-select"
          value={values.country}
          onChange={handleCountryChange}
          name="country"
        >
          {countries.map((country) => (
            <MenuItem key={country.name} value={country}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className="text-field min-w-300 m-10"
        variant="outlined"
        margin="normal"
        id="city"
        label="City"
        name="city"
        autoComplete="city"
        onChange={handleChange}
        autoFocus
      />
      {hasState ? (
        <FormControl variant="outlined" className="text-field min-w-300 m-10">
          <InputLabel id="state-select-standard-label" style={{ background: '#fff', padding: '0 5px' }}>
            State
          </InputLabel>
          <Select
            labelId="state-select-standard-label"
            id="state-select"
            value={values.state}
            onChange={handleStateChange}
            name="state"
          >
            {states.map((state) => (
              <MenuItem key={state.name} value={state}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: isMobile ? 10 : 0,
          maxWidth: 600,
          justifyContent: 'start',
        }}
      >
        <RadioGroup
          style={{ marginTop: 10 }}
          aria-labelledby="investing-radio-buttons-group-label"
          defaultValue={true}
          name="radio-buttons-group"
          onChange={handleRadioChange}
        >
          <FormControlLabel value={true} control={<Radio />} label={getAccreditationText()} />
          <FormControlLabel value={false} control={<Radio />} label="I am not accredited investor" />
        </RadioGroup>
        I agree to receive emails on updates and investment opportunities
        <FormControl variant="standard" sx={{ m: 1, width: 120 }}>
          <Select onChange={handleReceiveEmailChange}>
            <MenuItem value={true} className="bold">
              Yes
            </MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
        {errors.allowCommunication !== '' ? (
          <div style={{ color: '#d32f2f', fontSize: 12, lineHeight: 0.5, paddingTop: 0, paddingBottom: 20 }}>
            {errors.allowCommunication}
          </div>
        ) : null}
        <div className="f-size-12">
          *Please note that if you choose "No" you will be opting out of receiving any future emails including new
          investment opportunities
        </div>
        <div
          style={{
            alignItems: 'center',
            fontSize: 12,
          }}
        >
          <Checkbox
            checked={termsAndConditions}
            onChange={handleTermsChange}
            inputProps={{ 'aria-label': 'controlled' }}
            style={{ paddingLeft: 0 }}
          />
          I agree to our&nbsp;
          <span
            className="g-c pointer"
            onClick={() => window.open(marketing_website_base_url + 'terms-of-service/', '_blank')}
          >
            Terms & Conditions&nbsp;
          </span>
          and&nbsp;
          <span
            className="g-c pointer"
            onClick={() => window.open(marketing_website_base_url + 'privacy-policy/', '_blank')}
          >
            Privacy Policy
          </span>
          {errors.terms !== '' ? (
            <div style={{ color: '#d32f2f', fontSize: 12, lineHeight: 0.5, paddingTop: 0, paddingBottom: 20 }}>
              {errors.terms}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
