import { createSelector } from 'reselect';

const getRoundsSuccess = (state) => state.documentsUploader.rounds;
const getRoundsAttempting = (state) => state.documentsUploader.attempting;
const getRoundsError = (state) => state.documentsUploader.errorCode;

export const getRoundsSelector = createSelector(getRoundsSuccess, (rounds) => rounds);
export const getRoundsAttemptingSelector = createSelector(getRoundsAttempting, (attempting) => attempting);
export const getRoundsErrorSelector = createSelector(getRoundsError, (errorCode) => errorCode);

const getSubmitSuccess = (state) => state.documentsUploader.submitRes;
const getSubmitAttempting = (state) => state.documentsUploader.attemptingSubmit;
const getSubmitError = (state) => state.documentsUploader.errorCode;

export const getSubmitSuccessSelector = createSelector(getSubmitSuccess, (submitRes) => submitRes);
export const getSubmitAttemptingSelector = createSelector(getSubmitAttempting, (attemptingSubmit) => attemptingSubmit);
export const getSubmitErrorSelector = createSelector(getSubmitError, (errorCode) => errorCode);
