import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// mui utils
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import RemoveIcon from '@mui/icons-material/Remove';
import Accordion from '@mui/material/Accordion';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

// iangels utils
import Text from '../../components/text';
import MemoCompanyPortfolio from '../../components/Memo/MemoCompanyPortfolio';
import MemoAudio from '../../components/Memo/MemoAudio';
import MemoHtmlParser from '../../components/Memo/MemoHtmlParser';
import SignNda from '../../components/SignNda';
import ScrollToTop from '../../components/Generic/ScrollToTop';

import { INVESTMENTS_SIDE_NAV } from '../../helpers/constants';
import { track } from '../../services/analytics';
import { getStartupPageSelector } from './selectors';
import MemoTeam from '../../components/MemoTeam';
import MemoCompanySnapshot from '../../components/Memo/MemoCompanySnapshotMobile';
import MyInvestments from './MyInvestments';
import FinancialsAndTraction from '../../components/FinancialsAndTraction';

// third party utils
import { useSelector } from 'react-redux';

const SectionAccordion = ({ title, children, isFirst }) => {
  const [expanded, setExpanded] = useState(isFirst ? true : false);

  const handleChange = (event) => {
    if (!expanded) {
      track('Click On TOC', { category: 'Startup', area: 'Item', item: event?.currentTarget?.innerText });
    }
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      style={{
        boxShadow: 'none',
        borderRadius: 0,
        borderTop: expanded ? null : '1px solid #E1E1E1',
        minHeight: '55px',
      }}
    >
      <AccordionSummary
        className={expanded ? 'startup-accordion' : ''}
        expandIcon={expanded ? <RemoveIcon className="g-c" /> : <AddIcon className="g-c" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Text classes="f-tgf-400 g-c" text={title} style={{ maxWidth: 250 }} />
      </AccordionSummary>
      <AccordionDetails className="mobileSection">{children}</AccordionDetails>
    </Accordion>
  );
};

const CompanyPageMobile = (props) => {
  const { slug } = props;
  const navigate = useNavigate();
  const startupPage = useSelector(getStartupPageSelector);
  const limitedView = startupPage.isUserRestricted || !startupPage.isUserVerified;
  const showMemo = startupPage.meta.fundingStatus === 'open' && !limitedView;
  const [isNdaModalShown, setIsNdaModalShown] = useState(false);
  const canSeePublicOpenRound = () => {
    return startupPage.openRound?.investmentMemoParts?.length > 0;
  };
  const isNeedToSignNda = useMemo(() => {
    return (
      startupPage.openRound &&
      !startupPage.openRound.noNda &&
      !startupPage.openRound.ndaSigned &&
      !startupPage.openRound.additionalMaterials &&
      startupPage.openRound.investmentMemoParts.filter((part) => part.content === "hidden").length > 0 &&
      showMemo
    );
  }, [startupPage, showMemo]);

  const hasSnapShot = () => {
    return startupPage.keyBusinessUpdates?.length > 0 && startupPage.snapshotIsVisible && startupPage.isUserVerified;
  };

  const hasUpdates = () => {
    return startupPage?.updates?.length > 0 && !startupPage?.snapshotIsVisible;
  };

  const handleNdaClose = () => {
    setIsNdaModalShown(false);
  };

  useEffect(() => {
    let mounted = true;

    const timer = setTimeout(() => {
      mounted && isNeedToSignNda && setIsNdaModalShown(true);
    }, 10);
    return () => {
      clearTimeout(timer);
      mounted = false;
    };
  }, [isNeedToSignNda]);

  return (
    <div style={{ marginTop: 30, marginBottom: 30 }}>
      <ScrollToTop showBelow={250} />
      {/* investment table */}
      {startupPage.investments?.length > 0 ? (
        <SectionAccordion title={INVESTMENTS_SIDE_NAV.myInvestments} isFirst={true}>
          <MyInvestments investments={startupPage.investments} />
        </SectionAccordion>
      ) : null}

      {/*Audio*/}
      <MemoAudio startupPage={startupPage} limitedView={startupPage.isUserRestricted || !startupPage.isUserVerified} />

      {/* company Snapshot */}
      {hasSnapShot() || hasUpdates() ? (
        <SectionAccordion title={INVESTMENTS_SIDE_NAV.companySnapshot} isFirst={true}>
          <MemoCompanySnapshot
            totalCapitalRaisedToDate={startupPage.totalCapitalRaisedToDate}
            plansForFutureFunding={startupPage.plansForFutureFunding}
            keyBusinessUpdates={startupPage.keyBusinessUpdates}
            monthlyNetBurnRate={startupPage.monthlyNetBurnRate}
            operatingCashFlow={startupPage.operatingCashFlow}
            snapshotIsVisible={startupPage.snapshotIsVisible}
            isUserVerified={startupPage.isUserVerified}
            cashOnHand={startupPage.cashOnHand}
            updates={startupPage.updates}
          />
        </SectionAccordion>
      ) : null}

      {hasSnapShot() && startupPage.kpis?.length > 0 ? (
        <SectionAccordion title="Financials and Traction">
          {/* Financials And Traction */}
          {startupPage.kpis?.length > 0 ? (
            <FinancialsAndTraction
              kpis={startupPage.kpis}
              monthlySnapshotIsAvailable={startupPage.monthlySnapshotIsAvailable}
              defaultPeriod={startupPage.defaultPeriod}
              isMobile={true}
            />
          ) : null}
        </SectionAccordion>
      ) : null}

      {/* companyOverview */}
      {startupPage.companyOverview ? (
        <SectionAccordion title={startupPage.companyOverview?.title} isFirst={!hasSnapShot() && !hasUpdates()}>
          <MemoHtmlParser content={startupPage.companyOverview.content} />
        </SectionAccordion>
      ) : startupPage.description ? (
        <div id={INVESTMENTS_SIDE_NAV.description}>
          <SectionAccordion title={INVESTMENTS_SIDE_NAV.description} isFirst={true}>
            <Text text={startupPage.description} classes="f-gl-400" style={{ marginLeft: 20, marginTop: 10 }} />
          </SectionAccordion>
        </div>
      ) : null}

      {/* public memo */}
      {startupPage?.openRound?.investmentMemoParts.map((memo) => (
        (canSeePublicOpenRound() && showMemo && !memo.isPrivate) || memo.isPrivate ?
          <SectionAccordion key={memo.title} title={memo.title}>
            {memo.content === "hidden" ? <><div>
              Supplemental information including deal terms and financial information
              <strong>requires a confidentiality agreement.</strong>
            </div>
              <Button
                variant="contained"
                className="btn-sign-nda f-f-gl"
                onClick={() => {
                  track('NDA Section Button Click', { category: 'Startup', startup: slug });
                  navigate(`/startup/${slug}/nda`);
                }}
              >
                Sign NDA
              </Button> </> :
              <div dangerouslySetInnerHTML={{ __html: memo.content }} className="content-box memo-formatting" />}
          </SectionAccordion> : null
      ))
      }

      {/*  portfolio */}
      {startupPage.portfolio?.length > 0 ? (
        <SectionAccordion title={INVESTMENTS_SIDE_NAV.portfolio}>
          <MemoCompanyPortfolio title={INVESTMENTS_SIDE_NAV.portfolio} portfolio={startupPage.portfolio} />
        </SectionAccordion>
      ) : null}

      {/*  team Members */}
      {startupPage.teamMembers?.length > 0 ? (
        <SectionAccordion title={INVESTMENTS_SIDE_NAV.founders}>
          <MemoTeam id={INVESTMENTS_SIDE_NAV.founders} isMobile={true} investors={startupPage.teamMembers} />
        </SectionAccordion>
      ) : null}

      {/* latest lead investors */}
      {startupPage.latestLeadInvestors?.length > 0 ? (
        <SectionAccordion title={INVESTMENTS_SIDE_NAV.notableShareholders}>
          <MemoTeam
            title={INVESTMENTS_SIDE_NAV.notableShareholders}
            isMobile={true}
            investors={startupPage.latestLeadInvestors}
          />
        </SectionAccordion>
      ) : null}
      {/* sign nda modal */}
      <SignNda slug={slug} isNdaModalShown={isNdaModalShown} handleClose={handleNdaClose} isMobile={true} />
    </div>
  );
};

export default CompanyPageMobile;
