import React, { useEffect, useState } from 'react';

// components
import Header from '../../../components/Header/Index';
import Footer from '../../../components/Footer';
import SignUpModel from '../../SignUpModel';
import LayoutBanner from './LayoutBanner';

// css
import '../../../themes/layout.scss';

// mui utils
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// iAngels utils
import LoadingIndicator from '../../../components/Generic/LoadingIndicator';
import RoutGuard from '../../../auth/RouteGuardUniversal';
import { track, pageView } from '../../../services/analytics';

import {
  getNeedsDetailsSelector,
  getNeedsAccreditationSelector,
  getUserSelector,
  getNeedsVerifySelector,
  getAuthAttemptingSelector,
  getIsPreviewerSelector,
  getIsAdminSelector,
  getIsGuestSelector,
} from '../../App/selectors';
import  {getIsImpersonatedSelector} from  '../../Impersonate/selectors';

// third party lib
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { titleSlug } from '../../../helpers/iangels';

const MainLayout = (props) => {
  // hooks
  const params = useParams();

  // selectors
  const needsAccreditation = useSelector(getNeedsAccreditationSelector);
  const authLoading = useSelector(getAuthAttemptingSelector);
  const needDetails = useSelector(getNeedsDetailsSelector);
  const user = useSelector(getUserSelector);

  // banner selectors
  const isImpersonated = useSelector(getIsImpersonatedSelector);
  const isPreviewer = useSelector(getIsPreviewerSelector);
  const isVerified = useSelector(getNeedsVerifySelector);
  const isAdmin = useSelector(getIsAdminSelector);
  const isGuest = useSelector(getIsGuestSelector);

  // state
  const [showModeBanner, setShowModeBanner] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    if (isAdmin || isPreviewer || isImpersonated || isGuest) {
      setShowModeBanner(true);
    }
    if (!isGuest && !isAdmin && !isPreviewer && !isImpersonated) {
      setShowModeBanner(false);
    }
  }, [isAdmin, isPreviewer, isImpersonated, isGuest]);

  useEffect(() => {
    setShowFooter(!props.lockGuard || user != null);
  }, [props.lockGuard, user]);

  useEffect(() => {
    pageView(props.track, window.location.href, { routerPath: props.navigateTo });

    if (isVerified === false) track('Resend Verification Email');
  });

  const userNeedDetails = () => {
    if (!user) return false;
    if (!user?.app_metadata) return true;

    return !user?.app_metadata.has_details;
  };

  const userLevel = () => {
      if (needsAccreditation && user?.user_status==="registered" && user?.app_metadata?.level === 'unknown' ) {
      return true;
    }
    return false;
  };

  return (
    <div style={{ width: '100%', height: 'inherit' }}>
      <div className="app" style={{ height: 'inherit', justifyContent: 'space-between' }}>
        <div>
          {((needDetails && userNeedDetails()) || userLevel()) && <SignUpModel />}
          <Header
            showModeBanner={showModeBanner}
            setShowModeBanner={setShowModeBanner}
            isAdmin={isAdmin}
            isPreviewer={isPreviewer}
            isImpersonated={isImpersonated}
            isGuest={isGuest}
          />
          {isVerified === false ? <LayoutBanner email={user?.email} /> : null}

          <Helmet>
            {props.title ? (
              <title>{`${props.title} - iAngels`}</title>
            ) : params?.slug ? (
              <title>{`${titleSlug(params?.slug)} - iAngels`} </title>
            ) : (
              <title>{'iAngels'}</title>
            )}
          </Helmet>
          <RoutGuard
            lockGuard={props.lockGuard}
            navigateTo={params?.slug ? `${props.navigateTo}/${params?.slug}` : props.navigateTo}
            hasNext={props.hasNext}
            isSignup={props.isSignup}
          />
          <main
            role="main"
            className="main"
            style={{
              marginTop: isVerified === false && showModeBanner ? 130 : isVerified === false || showModeBanner ? 65 : 0,
            }}
          >
            {authLoading ? (
              <LoadingIndicator />
            ) : (
              <Box>
                <Container style={{ maxWidth: 1200, minHeight: 570 }}>
                  {React.Children.toArray(props.children)}
                </Container>
              </Box>
            )}
          </main>
        </div>

        {showFooter && (
          <footer className="footer">
            <Footer />
          </footer>
        )}
      </div>
    </div>
  );
};

export default MainLayout;
