import { call, all, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService } from '../../services/apiGateway';
import { getNdaEmbeddedUrlFailure, getNdaEmbeddedUrlSuccess } from './actions';

import { GET_NDA_EMBEDDED_URL_ATTEMPT } from './actionTypes';

function* getEmbeddedUrl(action) {
  try {
    const embeddedUrl = yield call(getDataFromService, `iangels/startups/nda/${action.id}/signing-embed-url`);
    yield put(getNdaEmbeddedUrlSuccess(embeddedUrl?.data));
  } catch (errorCode) {
    yield put(getNdaEmbeddedUrlFailure(errorCode.response || errorCode));
  }
}

function* ndaPageSage() {
  yield all([takeLatest(GET_NDA_EMBEDDED_URL_ATTEMPT, getEmbeddedUrl)]);
}

export default ndaPageSage;
