import React, { useEffect, useState } from 'react';

// mui utils
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Container from '@mui/material/Container';
import FolderOpenIcon from '@mui/icons-material/FolderOpenOutlined';
import { TextField, Autocomplete, Box, Paper, Checkbox, FormControlLabel, Divider } from '@mui/material';

// iangels utils
import { getDocumentsAttemptingSelector, getDocumentsSelector } from './selectors';
import { getUserSelector } from '../App/selectors';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import { getDocumentsAttempt } from './actions';
import DocumentsTable from './DocumentsTable';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

import { documentsTypeNamesMap, documentsTypeWeightMap } from '../../helpers/constants';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import _, { orderBy } from 'lodash';
import moment from 'moment';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Documents = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(getUserSelector);

  const loading = useSelector(getDocumentsAttemptingSelector);
  const documents = useSelector(getDocumentsSelector);
  const isMobile = IsMobileDevice();

  const [filteredDocuments, setFilteredDocuments] = useState({});
  const [filters, setFilters] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [documentTypes, setDocumentTypes] = useState([]);

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      prev ? setFilters([]) : setFilters([...documentTypes]);
      return !prev;
    });
  };

  useEffect(() => {
    if (user) {
      dispatch(getDocumentsAttempt());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (documents) setDocumentTypes(documents.map((o) => o.type).filter((x, i, a) => a.indexOf(x) === i));
  }, [documents]);

  useEffect(() => {
    if (documentTypes) setFilters([...documentTypes]);
  }, [documentTypes]);

  useEffect(() => {
    const getFilteredDocuments = () => {
      let tmpFilteredDocuments = [];

      if (filters.length === documentTypes.length) {
        tmpFilteredDocuments = [...documents];
      } else {
        filters.forEach((filterKey) => {
          tmpFilteredDocuments = tmpFilteredDocuments.concat(documents.filter((o) => o.type === filterKey));
        });
      }

      return orderBy(
        tmpFilteredDocuments.map((document) => {
          return {
            ...document,
            dateCreated: moment(document.dateCreated),
            typeWeight: documentsTypeWeightMap[document.type],
            isPending: document.isPending
          };
        }),
        ['typeWeight', 'isPending', 'dateCreated'],
        ['asc', 'asc', 'desc']
      );
    };

    if (documents) {
      setFilteredDocuments(_.groupBy(getFilteredDocuments(), 'type'));
    }
  }, [documents, filters, documentTypes]);

  const handleFilterChange = (event, options) => {
    setFilters(options);
  };

  return (
    <Container
      style={{
        padding: 0,
        marginTop: 40,
        minHeight: 800,
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <FolderOpenIcon sx={{ color: '#094E60', fontSize: 50 }} />
            </Grid>
            <Grid item className="f-f-pmp f-w-500 f-size-30 italic g4-c pl-10">
              Documents
            </Grid>
          </Grid>

          <Autocomplete
            style={{ width: '100%', margin: '10px 0' }}
            multiple
            id="checkboxes-tags-demo"
            options={documentTypes}
            disableCloseOnSelect
            value={filters}
            getOptionLabel={(option) => documentsTypeNamesMap[option]}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {documentsTypeNamesMap[option]}
              </li>
            )}
            onChange={(e, option, reason) => {
              if (reason === 'clear' || reason === 'removeOption') setSelectAll(false);
              if (reason === 'selectOption' && option.length === documentTypes.length) setSelectAll(true);
              handleFilterChange(e, option);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={documentsTypeNamesMap[option]}
                  {...getTagProps({ index })}
                  className="w-c g3-b f-f-g f-size-14 f-w-500 lh-36 pr-10 mr-5 mb-5"
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Select filter" variant="standard" className="text-field" />
            )}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault(); // prevent blur
                        handleToggleSelectAll();
                      }}
                      label="Select all"
                      control={<Checkbox id="select-all-checkbox" checked={selectAll} />}
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              );
            }}
          />
          <DocumentsTable filteredDocuments={filteredDocuments} isMobile={isMobile}></DocumentsTable>
        </>
      )}
    </Container>
  );
};

export default Documents;
