import React, { useEffect, useState } from 'react';

// mui utils
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

// third party utils
import { useSelector, useDispatch } from 'react-redux';

// iangels utils
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import { getStartupsAttempt, getSectorsAttempt } from './actions';
import CompanyCard from '../InvestmentOpportunities/CompanyCard';
import Text from '../../components/text';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

import {
  getSectorsAttemptingSelector,
  getSectorsSelector,
  getStartupsAttemptingSelector,
  getStartupsSelector,
} from './selectors';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Startups = (props) => {
  // hooks
  const isMobile = IsMobileDevice();
  const dispatch = useDispatch();

  // selectors
  const startupLoading = useSelector(getStartupsAttemptingSelector);
  const sectorsLoading = useSelector(getSectorsAttemptingSelector);
  const startups = useSelector(getStartupsSelector);
  const sectors = useSelector(getSectorsSelector);

  // state
  const [iangelsPortfolios, setIangelsPortfolios] = useState(null);
  const [syndicatePortfolios, setSyndicatePortfolios] = useState(null);
  const [querySearch, setQuerySearch] = useState('');
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    let startupsRegEx = new RegExp(querySearch, 'ig');
    let iangelsFilteredList = startups?.iangelsPortfolios?.filter(
      (company) =>
        startupsRegEx.test(company.name) &&
        (filters.length === 0 ||
          filters.find((f) => f.name.toLowerCase() === company.sector.toLowerCase()) ||
          filters.find((f) => f.name.toLowerCase() === 'all'))
    );

    let syndicateRegEx = new RegExp(querySearch, 'ig');
    let syndicateFilteredList = startups?.syndicatePortfolios?.filter(
      (company) =>
        syndicateRegEx.test(company.name) &&
        (filters.length === 0 ||
          filters.find((f) => f.name.toLowerCase() === company.sector.toLowerCase()) ||
          filters.find((f) => f.name.toLowerCase() === 'all'))
    );

    setIangelsPortfolios(iangelsFilteredList);
    setSyndicatePortfolios(syndicateFilteredList);
  }, [querySearch, startups, filters]);

  useEffect(() => {
    dispatch(getStartupsAttempt());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSectorsAttempt());
  }, [dispatch]);

  useEffect(() => {
    if (startups) {
      setIangelsPortfolios(startups.iangelsPortfolios);
      setSyndicatePortfolios(startups.syndicatePortfolios);
    }
  }, [startups]);

  useEffect(() => {
    const allIndex = sectors?.findIndex((x) => x.value === 'all');
    if (allIndex > -1)
      sectors?.splice(
        sectors?.findIndex((x) => x.value === 'all'),
        1
      );
    sectors?.unshift({ value: 'all', name: 'All' });
  }, [sectors]);

  const handleChange = (event, options) => {
    setFilters(options);
  };

  return (
    <div className="mb-20">
      {startupLoading || sectorsLoading ? (
        <LoadingIndicator />
      ) : (
        <Container
          style={{
            padding: 0,
            display: 'flex',
            flexFlow: 'row wrap',
            flexDirection: 'column',
            width: 'auto',
            marginLeft: -10,
            marginRight: -10,
            marginTop: 0,
            maxWidth: 1200,
          }}
        >
          <div
            style={
              isMobile
                ? {
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  marginTop: 15,
                  marginLeft: 10,
                }
                : {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'end',

                  margin: 15,
                }
            }
          >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} style={{ width: '100%' }}>
              <SearchIcon sx={{ mr: 1, my: 0.5 }} className="g3-c" />
              <TextField
                id="search-input"
                label="Search"
                variant="standard"
                className="text-field"
                style={{
                  width: '100%',
                  marginRight: 20,
                }}
                onChange={(e) => setQuerySearch(e.target.value)}
              />
            </Box>
            {sectors?.length > 0 ? (
              <Autocomplete
                style={{ width: '100%' }}
                multiple
                id="checkboxes-tags-demo"
                options={sectors}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props} className="f-gl-400">
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      name={option.name}
                    />
                    {option.name}
                  </li>
                )}
                onChange={(e, option) => handleChange(e, option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sector"
                    placeholder="Select sector"
                    variant="standard"
                    className="text-field"
                    style={
                      isMobile
                        ? {
                          marginLeft: 30,
                          marginRight: 20,
                          marginTop: 10,
                          width: '-webkit-fill-available',
                        }
                        : null
                    }
                  />
                )}
              />
            ) : null}
          </div>
          {iangelsPortfolios?.length > 0 ? (
            <div style={{ margin: '30px 10px' }}>
              <Text text="iAngels Opportunities - Funded Rounds" classes="f-f-gl f-w-600 f-size-24" />

              <Text
                variant="body1"
                text=" Investments made in startups that have passed our investment committee and proprietary due diligence process. The following have all completed their funding and are now closed."
                classes="f-gl-400"
                style={{ marginTop: 5 }}
              />
            </div>
          ) : null}

          <div
            style={{
              display: 'flex',
              width: '100%',
              flexFlow: 'row wrap',
            }}
          >
            {iangelsPortfolios?.length > 0
              ? iangelsPortfolios.map((element, index) => (
                <CompanyCard
                  card={element}
                  hasRoundState={false}
                  hasShortDescription={false}
                  key={index}
                  cardTo={`/startup/${element.slug}`}
                />
              ))
              : null}
          </div>
          {syndicatePortfolios?.length > 0 ? (
            <div style={{ margin: '30px 10px' }}>
              <Text text="Syndicate Deals - Funded Rounds" classes="f-f-gl f-w-600 f-size-24" />
              <Text
                variant="body1"
                text="Investments made with professional lead Angels, who conduct independent due diligence that iAngels vets and endorses. The following have all completed their funding and are now closed."
                classes="f-gl-400"
                style={{ marginTop: 5 }}
              />
            </div>
          ) : null}

          <div
            style={{
              display: 'flex',
              width: '100%',
              flexFlow: 'row wrap',
            }}
          >
            {syndicatePortfolios?.length > 0
              ? syndicatePortfolios.map((element, index) => (
                <CompanyCard
                  card={element}
                  hasRoundState={false}
                  hasShortDescription={false}
                  key={index}
                  cardTo={`/startup/${element.slug}`}
                />
              ))
              : null}
          </div>
        </Container>
      )}
    </div>
  );
};

export default Startups;
