import React from 'react';
import LoadingCircle from '../../assets/iAngels_circle.gif';
import Stack from '@mui/material/Stack';

const LoadingIndicator = () => {
  return (
    <Stack className="df-c" sx={{ width: '100%', minHeight: 600 }} spacing={2}>
      <img src={LoadingCircle} alt="loading-indicator" />
    </Stack>
  );
};

export default LoadingIndicator;
