export const GET_INVESTMENTS_ATTEMPT = 'GET_INVESTMENTS_ATTEMPT';
export const GET_INVESTMENTS_SUCCESS = 'GET_INVESTMENTS_SUCCESS';
export const GET_INVESTMENTS_FAILURE = 'GET_INVESTMENTS_FAILURE';

export const GET_DASHBOARD_ATTEMPT = 'GET_DASHBOARD_ATTEMPT';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

export const CLEAR_STATE = 'CLEAR_STATE';

export const SECONDARY_SALE_ATTEMPT = 'SECONDARY_SALE_ATTEMPT';
export const SECONDARY_SALE_SUCCESS = 'SECONDARY_SALE_SUCCESS';
export const SECONDARY_SALE_FAILURE = 'SECONDARY_SALE_FAILURE';

export const EXPORT_PDF = 'EXPORT_PDF';
