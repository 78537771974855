import React from 'react';

import MemoCompanyCard from './MemoCompanyCard';
import Text from '../text';

const MemoCompanyPortfolio = ({ title, portfolio }) => {
  return (
    <div id={title} className="d-f fdr-c" style={{ justifyContent: 'space-evenly', marginBottom: 30 }}>
      <Text text={title} classes="f-tgf-400 g4-c memo-title" />
      <div className="d-f  f-wrap" style={{ marginTop: 10, justifyContent: 'center' }}>
        {portfolio.map((element, index) => (
          <MemoCompanyCard card={element} key={index}></MemoCompanyCard>
        ))}
      </div>
    </div>
  );
};
export default MemoCompanyPortfolio;
