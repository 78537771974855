export const SUBMIT_FORM_ATTEMPT = 'SUBMIT_FORM_ATTEMPT';
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_FAILURE = 'SUBMIT_FORM_FAILURE';
export const GET_SECTORS_ATTEMPT = 'GET_SECTORS_ATTEMPT';
export const GET_SECTORS_SUCCESS = 'GET_SECTORS_SUCCESS';
export const GET_SECTORS_FAILURE = 'GET_SECTORS_FAILURE';
export const GET_PRIMARY_SECTORS_ATTEMPT = 'GET_PRIMARY_SECTORS_ATTEMPT';
export const GET_PRIMARY_SECTORS_SUCCESS = 'GET_PRIMARY_SECTORS_SUCCESS';
export const GET_PRIMARY_SECTORS_FAILURE = 'GET_PRIMARY_SECTORS_FAILURE';
export const CLEAR_STATE = 'CLEAR_STATE';
