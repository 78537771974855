import React from 'react';

import FounderWrapper from '../../components/FounderPage/FounderWrapper';
import TwoFieldStack from '../../components/FounderPage/TwoFieldStack';
import Text from '../../components/text';

const ContactInformation = ({
  contactInformation,
  contactInformationError,
  handleContactInformationChange,
  matches,
  title,
}) => {
  const nameField = {
    type: 'text',
    required: true,
    error: contactInformationError.name,
    id: 'name',
    label: 'Name',
    value: contactInformation.name,
    name: 'name',
    onChange: handleContactInformationChange,
    helperText: contactInformationError.name,
  };

  const titleField = {
    type: 'text',
    required: true,
    error: contactInformationError.title,
    id: 'title',
    label: 'Role/Title',
    value: contactInformation.title,
    name: 'title',
    onChange: handleContactInformationChange,
    helperText: contactInformationError.title,
  };

  const phoneField = {
    type: 'text',
    required: true,
    error: contactInformationError.phone,
    id: 'phone',
    label: 'Phone',
    value: contactInformation.phone,
    name: 'phone',
    onChange: handleContactInformationChange,
    helperText: contactInformationError.phone,
  };

  const emailField = {
    type: 'text',
    required: true,
    error: contactInformationError.email,
    id: 'email',
    label: 'Email',
    value: contactInformation.email,
    name: 'email',
    onChange: handleContactInformationChange,
    helperText: contactInformationError.email,
  };

  return (
    <FounderWrapper>
      <div className="founder-title-wrapper">
        <Text variant="h6" classes="f-tgf-400 g4-c" text={title} />
      </div>
      <TwoFieldStack matches={matches} fieldAObject={nameField} fieldBObject={titleField} />
      <TwoFieldStack matches={matches} fieldAObject={phoneField} fieldBObject={emailField} />
    </FounderWrapper>
  );
};

export default ContactInformation;
