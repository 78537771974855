import React from 'react';

import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';

import AccordionItem from './AccordionItem';
import { getCommaSeparatedString } from '../../helpers/iangels';
import Text from '../text';

const AccordionMobileSummary = (props) => {
  const { title, investmentSummary, iconDisabled, changeTitle, valueTitle, textClass } = props;
  const textColor = textClass ? textClass : 'g4-c';
  const titlesClass = 'f-size-12 f-g-400 ' + textColor;
  const valueClass = 'f-size-12 f-f-tgf f-w-500 ' + textColor;

  return (
    <AccordionSummary
      expandIcon={iconDisabled ? null : <ExpandMoreIcon className={textClass ? textClass : 'g-c'} />}
      aria-controls="accordion-summary-content"
      id="accordion-summary-header"
      className={`accordionMobileSummary ${iconDisabled ? 'pr-30 ' : ' '}`}
    >
      <Table id={`MyInvestmentsToPDFHeader-${title}`.replace(/ /g, '')} className="toPdf">
        <TableHead>
          <TableRow>
            <TableCell className="border-none">
              <Stack direction="column" spacing={2} justifyContent="space-evenly" alignItems="baseline" width={'100%'}>
                <AccordionItem className={`pb-0 ${textClass ? textClass : 'g-c'}`}>
                  <Text variant="h6" classes="f-tgf-400 f-size-16" text={title} />
                </AccordionItem>
                <Stack
                  direction="column"
                  spacing={2}
                  justifyContent="space-evenly"
                  alignItems="baseline"
                  width={'100%'}
                  style={{ margin: 0 }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-evenly"
                    alignItems="center"
                    width={'100%'}
                    style={{ margin: 0 }}
                  >
                    <AccordionItem width={'100%'} className="pb-0">
                      <Text variant="body2" classes={titlesClass} text="Total Invested" style={{ minWidth: 80 }} />
                    </AccordionItem>
                    <AccordionItem width={'100%'} className="pb-0">
                      <Text variant="body2" classes={titlesClass} text={valueTitle} style={{ minWidth: 80 }} />
                    </AccordionItem>
                    <AccordionItem width={'100%'} className="pb-0">
                      <Text variant="body2" classes={titlesClass} text={changeTitle} />
                    </AccordionItem>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-evenly"
                    alignItems="baseline"
                    width={'100%'}
                    style={{ margin: 0 }}
                  >
                    <AccordionItem width={'100%'}>
                      <Text
                        variant="body2"
                        classes={valueClass}
                        text={
                          !isNaN(investmentSummary?.totalInvested)
                            ? '$' + getCommaSeparatedString(investmentSummary?.totalInvested)
                            : ''
                        }
                        style={{ minWidth: 80 }}
                      />
                    </AccordionItem>
                    <AccordionItem width={'100%'}>
                      <Text
                        variant="body2"
                        classes={valueClass}
                        text={
                          !isNaN(investmentSummary?.lastValue)
                            ? '$' + getCommaSeparatedString(investmentSummary?.lastValue)
                            : ''
                        }
                        style={{ minWidth: 80 }}
                      />
                    </AccordionItem>
                    <AccordionItem width={'100%'}>
                      <Text variant="body2" classes={valueClass} text={investmentSummary?.change?.toString() + '%'} />
                    </AccordionItem>
                  </Stack>
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </AccordionSummary>
  );
};

export default AccordionMobileSummary;
