import {
  INVESTMENT_ATTEMPT,
  INVESTMENT_FAILURE,
  INVESTMENT_SUCCESS,
  GET_INVESTMENT_ENTITIES_ATTEMPT,
  GET_INVESTMENT_ENTITIES_FAILURE,
  GET_INVESTMENT_ENTITIES_SUCCESS,
  GET_SIGNING_EMBEDDED_URL_ATTEMPT,
  GET_SIGNING_EMBEDDED_URL_FAILURE,
  GET_SIGNING_EMBEDDED_URL_SUCCESS,
  CLEAR_INVESTMENT_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  investmentSuccess: null,
  attempting: false,
  errorCode: null,
  entities: null,
  urlSuccess: null,
};

export const InvestmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SIGNING_EMBEDDED_URL_ATTEMPT:
      return {
        ...state,
        urlSuccess: null,
        attempting: true,
        errorCode: null,
      };
    case GET_SIGNING_EMBEDDED_URL_FAILURE:
      return {
        ...state,
        urlSuccess: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_SIGNING_EMBEDDED_URL_SUCCESS:
      return {
        ...state,
        urlSuccess: action.urlSuccess,
        errorCode: null,
        attempting: false,
      };
    case GET_INVESTMENT_ENTITIES_ATTEMPT:
      return {
        ...state,
        entities: null,
        attempting: true,
        errorCode: null,
      };
    case GET_INVESTMENT_ENTITIES_FAILURE:
      return {
        ...state,
        entities: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_INVESTMENT_ENTITIES_SUCCESS:
      return {
        ...state,
        entities: action.entities,
        errorCode: null,
        attempting: false,
      };
    case INVESTMENT_ATTEMPT:
      return {
        ...state,
        investmentSuccess: null,
        attempting: true,
        errorCode: null,
      };
    case INVESTMENT_FAILURE:
      return {
        ...state,
        investmentSuccess: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case INVESTMENT_SUCCESS:
      return {
        ...state,
        investmentSuccess: action.investmentSuccess,
        errorCode: null,
        attempting: false,
      };
    case CLEAR_INVESTMENT_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
