import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// mui utils
import Button from '@mui/material/Button';

// iangels utils
import { TEXT_MAX_LENGTH } from '../../helpers/constants';
import ContactInformation from './ContactInformation';
import { isEmailValid, isPhoneValid, capitalize } from '../../helpers/iangels';
import CompanyOverview from './CompanyOverview';
import Team from './Team';
import Round from './Round';
import PreviousFundings from './PreviousFundings';
import Traction from './Traction';
import KeyQuestions from './KeyQuestions';
import KeyDocuments from './KeyDocuments';
//import FundingRoundSection from './FundingRoundSection';
import { uploadFile } from '../../helpers/iangels';
import useIsMounted from '../../components/IsMounted';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

import { submitFormAttempt, clearState } from './actions';
import { getSubmitFormSuccessSelector, getSubmitFormErrorSelector } from './selectors';
import { getAuthResultSelector, getUserSelector } from '../App/selectors';

const FounderForm = () => {
  const isMobile = IsMobileDevice();
  const authResult = useSelector(getAuthResultSelector);
  const user = useSelector(getUserSelector);
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const submitFormFailure = useSelector(getSubmitFormErrorSelector);
  const submitFormSuccess = useSelector(getSubmitFormSuccessSelector);
  useEffect(() => {
    if (submitFormFailure) {
      dispatch(clearState());
      navigate('/error');
    }
    if (submitFormSuccess) {
      dispatch(clearState());
      navigate(`/foundersubmitted`);
    }
  }, [submitFormFailure, submitFormSuccess, navigate, dispatch]);

  const [contactInformation, setContactInformation] = useState({
    name: user?.user_metadata?.full_name || user?.name || authResult?.name || '',
    title: '',
    phone: '',
    email: user?.email || authResult?.email || '',
  });

  const [contactInformationError, setContactInformationError] = useState({
    name: '',
    title: '',
    phone: '',
    email: '',
  });

  const [companyOverview, setCompanyOverview] = useState({
    name: '',
    dateFounded: '',
    city: '',
    country: '',
    website: '',
    oneLinePitch: '',
    primarySector: '',
    subSector: '',
    businessModel: '',
    businessStage: '',
  });

  const [companyOverviewError, setCompanyOverviewError] = useState({
    name: '',
    dateFounded: '',
    city: '',
    country: '',
    oneLinePitch: '',
    primarySector: '',
    subSector: '',
  });

  const [team, setTeam] = useState({
    numberOfFounders: '',
    numberOfEmployees: '',
  });

  const [teamError, setTeamError] = useState({
    numberOfFounders: '',
  });

  const [founders, setFounders] = useState([
    { id: 0, name: '', title: '', email: '', linkedin: '', fulltime: '' },
    { id: 1, name: '', title: '', email: '', linkedin: '', fulltime: '' },
    { id: 2, name: '', title: '', email: '', linkedin: '', fulltime: '' },
    { id: 3, name: '', title: '', email: '', linkedin: '', fulltime: '' },
    { id: 4, name: '', title: '', email: '', linkedin: '', fulltime: '' },
  ]);

  const [foundersError, setFoundersError] = useState([
    { id: 0, name: '', title: '', email: '', linkedin: '', fulltime: '' },
    { id: 1, name: '', title: '', email: '', linkedin: '', fulltime: '' },
    { id: 2, name: '', title: '', email: '', linkedin: '', fulltime: '' },
    { id: 3, name: '', title: '', email: '', linkedin: '', fulltime: '' },
    { id: 4, name: '', title: '', email: '', linkedin: '', fulltime: '' },
  ]);

  const [traction, setTraction] = useState({
    traction: '',
  });

  const [tractionError, setTractionError] = useState({
    traction: '',
  });

  const [round, setRound] = useState({
    fundingStage: '',
    amountRaising: '',
    dealType: '',
    valuation: '',
    estimatedClosingDate: null,
    leader: '',
    founderOwnership: '',
  });

  const [roundError, setRoundError] = useState({
    fundingStage: '',
    amountRaising: '',
  });

  const [previousFundings, setPreviousFundings] = useState({ fundings: '' });

  const [previousRounds, setPreviousRounds] = useState([
    { id: 0, fundingStage: '', amountRaising: '', dealType: '', valuation: '', date: null },
    { id: 1, fundingStage: '', amountRaising: '', dealType: '', valuation: '', date: null },
    { id: 2, fundingStage: '', amountRaising: '', dealType: '', valuation: '', date: null },
    { id: 3, fundingStage: '', amountRaising: '', dealType: '', valuation: '', date: null },
    { id: 4, fundingStage: '', amountRaising: '', dealType: '', valuation: '', date: null },
  ]);

  const [previousRoundsError, setPreviousRoundsError] = useState([
    { id: 0, fundingStage: '', amountRaising: '' },
    { id: 1, fundingStage: '', amountRaising: '' },
    { id: 2, fundingStage: '', amountRaising: '' },
    { id: 3, fundingStage: '', amountRaising: '' },
    { id: 4, fundingStage: '', amountRaising: '' },
  ]);

  const [keyQuestions, setKeyQuestions] = useState({
    addressableMarket: '',
    problem: '',
    landscape: '',
    plan: '',
    vision: '',
    reason: '',
    source: '',
  });

  const [keyQuestionsError, setKeyQuestionsError] = useState({
    addressableMarket: '',
    problem: '',
    landscape: '',
    plan: '',
    vision: '',
    reason: '',
    source: '',
  });

  const [fileLoading, setFileLoading] = useState({
    companyPresentation: false,
    projections: false,
  });

  const [keyDocuments, setKeyDocuments] = useState({
    companyPresentation: null,
    projections: null,
  });

  const [keyDocumentsError, setKeyDocumentsError] = useState({
    companyPresentation: '',
    projections: '',
  });

  const isValid = (text, callback, fieldName) => {
    switch (fieldName) {
      case 'Email':
        return isEmailValid(text, callback);
      case 'Phone':
        return isPhoneValid(text, callback);
      default: {
        if (typeof text === 'string') {
          if (text.length === 0) {
            callback(`${fieldName} is required.`);
            return false;
          }

          if (text.trim().length > TEXT_MAX_LENGTH) {
            callback(`${fieldName} is too long.`);
            return false;
          }
        }
      }
    }

    callback('');
    return true;
  };

  const isValidSection = (section, sectionErrors, setSectionError, isList = false) => {
    let errors = sectionErrors;
    if (isList) {
      sectionErrors.map((f) =>
        Object.keys(f).map((key) =>
          key !== 'id'
            ? isValid(
                section.find((o) => o.id === f.id)[key],
                (message) => {
                  errors.find((o) => o.id === f.id)[key] = message;
                },
                capitalize(key)
              )
            : null
        )
      );
    } else {
      Object.keys(sectionErrors).map((key) =>
        isValid(section[key], (message) => (errors[key] = message), capitalize(key))
      );
    }
    setSectionError(errors[(sectionErrors, setSectionError)]);
    setSectionError(errors);

    return isList
      ? errors.filter(({ id, ...e }) => Object.values(e).filter((value) => value !== '').length).length === 0
      : Object.values(errors).filter((value) => value !== '').length === 0;
  };

  const isValidForm = () => {
    return (
      isValidSection(contactInformation, contactInformationError, setContactInformationError) &&
      isValidSection(companyOverview, companyOverviewError, setCompanyOverviewError) &&
      isValidSection(team, teamError, setTeamError) &&
      isValidSection(
        founders?.filter((f) => f.id < Number(team?.numberOfFounders || 0)),
        foundersError?.filter((f) => f.id < Number(team?.numberOfFounders || 0)),
        setFoundersError,
        true
      ) &&
      isValidSection(round, roundError, setRoundError) &&
      isValidSection(
        previousRounds?.filter((f) => f.id < Number(previousFundings?.fundings || 0)),
        previousRoundsError?.filter((f) => f.id < Number(previousFundings?.fundings || 0)),
        setPreviousRoundsError,
        true
      ) &&
      isValidSection(traction, tractionError, setTractionError) &&
      isValidSection(keyQuestions, keyQuestionsError, setKeyQuestionsError) &&
      isValidSection(keyDocuments, keyDocumentsError, setKeyDocumentsError)
    );
  };

  const handleSubmit = () => {
    if (!isValidForm()) {
      alert('Please fill all required fields');
      return;
    }

    const form = {
      form: {
        contact: { ...contactInformation },
        ...companyOverview,
        country: companyOverview?.country?.code,
        primarySector: companyOverview?.primarySector?.value,
        subSector: companyOverview?.subSector?.value,
        businessModel: companyOverview?.businessModel.replace(/ /g, '-').toLowerCase(),
        businessStage: companyOverview?.businessStage.replace(/ /g, '-').toLowerCase(),
        teamMembers: [],
        founders: founders
          .filter((f) => f.id < Number(team.numberOfFounders || 0))
          .map(({ id, fulltime, ...rest }) => ({ fulltime: fulltime?.toLowerCase(), ...rest })),
        currentRound: round,
        previousRounds: previousRounds
          .filter((f) => f.id < Number(previousFundings.fundings || 0))
          .map(({ id, ...rest }) => ({ ...rest })),
        ...traction,
        ...keyQuestions,
        companyPresentation: keyDocuments.companyPresentation?.imageId,
        projections: keyDocuments.projections?.imageId,
      },
    };

    dispatch(submitFormAttempt(form));
  };

  const handleChange = (event, section, setSection, sectionErrors, setSectionErrors, o) => {
    const eventName = event.target ? event.target.name : o;
    const eventValue = event.target ? event.target.value : event;
    const matches = eventName.match(/^[a-z_A-Z]+\[(\d)\]\.([a-z_A-Z]+)$/);
    if (matches?.length > 2) {
      handleChangeForList(
        section,
        setSection,
        sectionErrors,
        setSectionErrors,
        Number(matches[1]),
        matches[2],
        eventValue
      );
    } else {
      setSection(
        {
          ...section,
          [eventName]: eventValue,
        },
        [section, setSection]
      );
      if (sectionErrors)
        if (sectionErrors[[eventName]])
          setSectionErrors(
            {
              ...sectionErrors,
              [eventName]: '',
            },
            [sectionErrors, setSectionErrors]
          );
    }
  };

  const handleChangeForList = (section, setSection, sectionErrors, setSectionErrors, id, attr, value) => {
    const o = section.map((f) => {
      if (f.id !== id) {
        return f;
      } else {
        return {
          ...f,
          [attr]: value,
        };
      }
    });
    setSection(o);

    if (sectionErrors[id][attr] !== undefined) {
      const err = sectionErrors.map((f) => {
        if (f.id !== id) {
          return f;
        } else {
          return {
            ...f,
            [attr]: '',
          };
        }
      });
      setSectionErrors(err);
    }
  };

  const handleContactInformationChange = (event) => {
    handleChange(event, contactInformation, setContactInformation, contactInformationError, setContactInformationError);
  };

  const handleCompanyOverviewChange = (event, o) => {
    handleChange(event, companyOverview, setCompanyOverview, companyOverviewError, setCompanyOverviewError, o);
  };

  const handleTeamChange = (event) => {
    handleChange(event, team, setTeam, teamError, setTeamError);
  };

  const handleFounderChange = (event) => {
    handleChange(event, founders, setFounders, foundersError, setFoundersError);
  };

  const handleRoundChange = (event, o) => {
    handleChange(event, round, setRound, roundError, setRoundError, o);
  };

  const handlePreviousFundingsChange = (event) => {
    handleChange(event, previousFundings, setPreviousFundings, null, null);
  };

  const handlePreviousRoundsChange = (event, o) => {
    handleChange(event, previousRounds, setPreviousRounds, previousRoundsError, setPreviousRoundsError, o);
  };

  const handleTractionChange = (event) => {
    handleChange(event, traction, setTraction, tractionError, setTractionError);
  };

  const handleKeyQuestionsChange = (event) => {
    handleChange(event, keyQuestions, setKeyQuestions, keyQuestionsError, setKeyQuestionsError);
  };

  //const [error, setError] = useState(null);

  const handleKeyDocumentsChange = (file, fileName) => {
    setFileLoading({
      ...fileLoading,
      [fileName]: true,
    });

    uploadFile(
      file,
      null,
      (response) => {
        if (isMounted()) {
          setKeyDocuments({
            ...keyDocuments,
            [fileName]: {
              imageId: response.data.imageId,
              file,
            },
          });

          //setError(false);

          setFileLoading({
            ...fileLoading,
            [fileName]: false,
          });
        }
      },
      (err) => {
        if (isMounted()) {
          //setError(err);

          setFileLoading({
            ...fileLoading,
            [fileName]: false,
          });
        }
      },
      { folder: 'Startup Applications' }
    );
  };

  return (
    <>
      <ContactInformation
        handleContactInformationChange={handleContactInformationChange}
        contactInformationError={contactInformationError}
        contactInformation={contactInformation}
        title="Contact Information"
        isMobile={isMobile}
      />
      <CompanyOverview
        companyOverview={companyOverview}
        companyOverviewError={companyOverviewError}
        handleCompanyOverviewChange={handleCompanyOverviewChange}
        title="Company Overview"
        isMobile={isMobile}
      />
      <Team
        team={team}
        teamError={teamError}
        founders={founders}
        foundersError={foundersError}
        handleTeamChange={handleTeamChange}
        handleFounderChange={handleFounderChange}
        title="Team"
        isMobile={isMobile}
      />

      <Round
        round={round}
        roundError={roundError}
        handleRoundChange={handleRoundChange}
        title="Current/Desired Round"
        isMobile={isMobile}
      />

      <PreviousFundings
        previousFundings={previousFundings}
        previousRounds={previousRounds}
        previousRoundsError={previousRoundsError}
        handlePreviousFundingsChange={handlePreviousFundingsChange}
        handlePreviousRoundsChange={handlePreviousRoundsChange}
        title="Previous Funding"
        isMobile={isMobile}
      />

      <Traction
        traction={traction}
        tractionError={tractionError}
        handleTractionChange={handleTractionChange}
        title="Traction"
        isMobile={isMobile}
      />
      <KeyQuestions
        keyQuestions={keyQuestions}
        keyQuestionsError={keyQuestionsError}
        handleKeyQuestionsChange={handleKeyQuestionsChange}
        title="Key Questions"
        isMobile={isMobile}
      />
      <KeyDocuments
        fileLoading={fileLoading}
        keyDocuments={keyDocuments}
        keyDocumentsError={keyDocumentsError}
        handleKeyDocumentsChange={handleKeyDocumentsChange}
        title="Key Documents"
        isMobile={isMobile}
      />
      <div align="right">
        <Button variant="contained" className="btn mt-20 mb-20" onClick={handleSubmit}>
          <span className="card-btn">Submit</span>
        </Button>
      </div>
    </>
  );
};

export default FounderForm;
