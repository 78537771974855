import { accreditationAffirmationFailure, accreditationAffirmationSuccess } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { postData } from '../../services/apiGateway';
import { ACCREDITATION_AFFIRMATION_ATTEMPT } from './actionTypes';

function* accreditationAffirmationAttempt(action) {
  try {
    const accreditation = yield call(postData, '/iangels/users/accreditation-affirmation', action.data);
    yield put(accreditationAffirmationSuccess(accreditation?.data));
  } catch (errorCode) {
    yield put(accreditationAffirmationFailure(errorCode.response || errorCode));
  }
}

function* accreditationAffirmationSaga() {
  yield all([takeLatest(ACCREDITATION_AFFIRMATION_ATTEMPT, accreditationAffirmationAttempt)]);
}

export default accreditationAffirmationSaga;
