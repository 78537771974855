import { MINUTE_IN_MILLISECOND } from '../../helpers/constants';

import { loadConfig } from '../../env/config_util';

import {
  IDENTIFY_USER_FAILURE_CLEAR,
  SET_FORCE_GET_USER_INFO,
  IDENTIFY_USER_ATTEMPT,
  IDENTIFY_USER_FAILURE,
  IDENTIFY_USER_SUCCESS,
  CLEAR_AUTH_STATE,
  SET_USER_ATTEMPT,
  SET_USER_FAILURE,
  SET_USER_SUCCESS,
  CLEAR_USER_STATE,
  SET_AUTH_RESULT,
  REFRESH_TRAITS_ATTEMPT,
  REFRESH_TRAITS_FAILURE,
  REFRESH_TRAITS_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

const config = loadConfig();

export const initialState = {
  traits: null,
  user: null,
  authResult: null,
  attempting: false,
  errorCode: null,
  attemptingRefresh: false,
  errorCodeRefresh: null,
  attemptingUser: false,
  expiresIn: null,
  forceGetUserInfo: false,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_RESULT:
      return {
        ...state,
        authResult: action.authResult,
        expiresIn:
          (config.config_module.auth0expiresIn ? config.config_module.auth0expiresIn : 3 * 60) * MINUTE_IN_MILLISECOND +
          new Date().getTime(),
      };
    case IDENTIFY_USER_ATTEMPT:
      return {
        ...state,
        traits: null,
        attempting: true,
      };
    case IDENTIFY_USER_FAILURE:
      return {
        ...state,
        traits: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case IDENTIFY_USER_SUCCESS:
      return {
        ...state,
        traits: action.traits,
        attempting: false,
      };
    case SET_USER_ATTEMPT:
      return {
        ...state,
        user: null,
        attemptingUser: true,
      };
    case SET_USER_FAILURE:
      return {
        ...state,
        user: null,
        errorCode: action.errorCode,
        attemptingUser: false,
      };
    case SET_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        attemptingUser: false,
      };
    case IDENTIFY_USER_FAILURE_CLEAR:
      return {
        ...state,
        errorCode: null,
        attempting: false,
      };
    case SET_FORCE_GET_USER_INFO:
      return {
        ...state,
        forceGetUserInfo: action.forceGetUserInfo,
      };
    case CLEAR_USER_STATE:
      return {
        ...state,
        errorCode: null,
        attemptingUser: false,
        user: null,
      };
    case REFRESH_TRAITS_ATTEMPT:
      return {
        ...state,
        attemptingRefresh: true,
      };
    case REFRESH_TRAITS_SUCCESS:
      if (JSON.stringify(state.traits) !== JSON.stringify(action.traits)) {
        return {
          ...state,
          attemptingRefresh: false,
          errorCodeRefresh: null,
          traits: action.traits,
        };
      } else {
        return {
          ...state,
          attemptingRefresh: false,
          errorCodeRefresh: null,
        };
      }
    case REFRESH_TRAITS_FAILURE:
      return {
        ...state,
        attemptingRefresh: false,
        errorCodeRefresh: action.errorCode,
      };
    case CLEAR_AUTH_STATE:
      return {
        ...state,
        errorCode: null,
        authResult: null,
        attempting: false,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
