import {
  GET_DOCUMENTS_ATTEMPT,
  GET_DOCUMENTS_FAILURE,
  GET_DOCUMENTS_SUCCESS,
  CLEAR_DOCUMENTS_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const getDocumentsAttempt = () => ({
  type: GET_DOCUMENTS_ATTEMPT,
});

export const getDocumentsSuccess = (documents) => ({
  type: GET_DOCUMENTS_SUCCESS,
  documents,
});

export const getDocumentsFailure = (errorCode) => ({
  type: GET_DOCUMENTS_FAILURE,
  errorCode,
});

export const clearDocumentsState = () => ({
  type: CLEAR_DOCUMENTS_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
