import React, { useEffect, useState } from 'react';

// mui utils
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmailIcon from '@mui/icons-material/Email';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// iangels utils
import { getResetPasswordAttempt, resetPasswordClearState } from './actions';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import WingsLogo from '../../assets/iAngels_green_wings_logo.png';
import ErrorAlert from '../../components/Generic/ErrorAlert';
import { isEmailValid } from '../../helpers/iangels';
import { track } from '../../services/analytics';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/text';
import SuccessIcon from '../../assets/confirm.svg';
import SocialIcon from '../../assets/social.svg';

import {
  getResetPasswordAttemptingSelector,
  getResetPasswordErrorSelector,
  getResetPasswordStatusSelector,
} from './selectors';

// third party utils
import { useSelector, useDispatch } from 'react-redux';

const ResetPassword = (props) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // selectors
  const loading = useSelector(getResetPasswordAttemptingSelector);
  const success = useSelector(getResetPasswordStatusSelector);
  const error = useSelector(getResetPasswordErrorSelector);

  // state
  const [noEmailError, setNoEmailError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({});

  useEffect(() => {
    if (error) {
      dispatch(resetPasswordClearState());
      navigate('/error');
      return;
    }
  }, [error, dispatch, navigate]);

  useEffect(() => {
    if (success) {
      if (success.status === 'no_email') {
        setNoEmailError(true);
      } else if (success.status === 'sent_email') {
        setStatus({
          icon: SuccessIcon,
          title: 'Email has been sent',
          text: "We've just sent you an email. Please check your inbox to proceed with changing your password. Click the 'Back' button to return to your dashboard",
        });
      } else if (success.status === 'social_connection_email') {
        setStatus({
          icon: SocialIcon,
          title: 'Social site login',
          text: 'You registered to the iAngels platform using once of the following: LinkedIn Google or Facebook. Since this connection provided us with your name and password, we cannot change your password here.',
        });
      }
    }
  }, [success, dispatch]);

  const resetPassword = () => {
    if (isEmailValid(email, setEmailError)) {
      dispatch(getResetPasswordAttempt(email));
    }
    track('Reset Password -> Sign In');
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleBack = (event) => {
    dispatch(resetPasswordClearState());
    navigate('/');
    return;
  };

  return (
    <Container
      style={{
        padding: 0,
        marginTop: 45,
        height: '100%',
      }}
      maxWidth="sm"
    >
      {success?.status === 'no_email' && (
        <ErrorAlert
          hasError={noEmailError}
          setHasError={setNoEmailError}
          title="Error"
          text="You have entered an email that doesn't exist in our system. If you have an account, please make sure
                you've entered your email address correctly and that you're using the email address you used for signing
                up"
        />
      )}
      {loading ? (
        <LoadingIndicator />
      ) : success && success?.status !== 'no_email' ? (
        <Stack direction="column" spacing={2} justifyContent="space-evenly" alignItems="center" style={{ margin: 5 }}>
          <img src={status.icon} alt="status icon" />
          <Text text={status.title} classes="f-tgf-400 mb-20" style={{ fontSize: 24 }} />
          {status.text && <Text text={status.text} classes="f-size-16 mb-20" style={{ textAlign: 'center' }} />}

          <div style={{ marginBottom: 40 }}>
            <Button
              variant="contained"
              className="btn"
              style={{
                minWidth: 100,
                minHeight: 40,
                borderRadius: 0,
                margin: 25,
              }}
              onClick={handleBack}
            >
              <span style={{ letterSpacing: 1 }}>Back</span>
            </Button>
          </div>
        </Stack>
      ) : (
        <Stack direction="column" spacing={2} justifyContent="space-evenly" alignItems="center" style={{ margin: 5 }}>
          <img src={WingsLogo} alt="logo" />
          <Text text="Reset Password" classes="f-f-tgf fw-500 mb-20 g3-c" style={{ fontSize: 24 }} />
          <Text
            text="Enter your email address below and we'll send you an email to reset your password."
            classes="f-size-16 mb-20"
            style={{ textAlign: 'center' }}
          />

          <div style={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <EmailIcon sx={{ mr: 1, my: 0.5 }} className="g3-c" />
              <TextField
                required
                error={emailError !== ''}
                id="email"
                label="Email"
                variant="standard"
                className="text-field"
                value={email}
                name="email"
                onChange={handleChange}
                helperText={emailError}
                style={{ width: '100%' }}
              />
            </Box>
          </div>
          <div style={{ marginBottom: 40 }}>
            <Button
              variant="contained"
              className="btn"
              style={{
                minWidth: 220,
                minHeight: 40,
                borderRadius: 0,
                margin: 25,
              }}
              endIcon={<ArrowForwardIcon />}
              onClick={resetPassword}
            >
              <span className="form-btn">Reset Password</span>
            </Button>
          </div>
        </Stack>
      )}
    </Container>
  );
};

export default ResetPassword;
