import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService } from '../../services/apiGateway';
import { getStartupsFailure, getStartupsSuccess, getSectorsFailure, getSectorsSuccess } from './actions';
import { GET_STARTUPS_ATTEMPT, GET_SECTORS_STARTUPS_ATTEMPT } from './actionTypes';

function* getStartupsList(action) {
  try {
    const startups = yield call(getDataFromService, '/iangels/startups/funded-opportunities');
    yield put(getStartupsSuccess(startups.data));
  } catch (err) {
    yield put(getStartupsFailure(err.response || err));
  }
}

function* getSectors(action) {
  try {
    const sectors = yield call(getDataFromService, '/iangels/startups/sectors');
    yield put(getSectorsSuccess(sectors.data));
  } catch (err) {
    yield put(getSectorsFailure(err.response || err));
  }
}

function* startupsSaga() {
  yield all([takeLatest(GET_STARTUPS_ATTEMPT, getStartupsList)]);
  yield all([takeLatest(GET_SECTORS_STARTUPS_ATTEMPT, getSectors)]);
}

export default startupsSaga;
