import {
  ACCREDITATION_AFFIRMATION_ATTEMPT,
  ACCREDITATION_AFFIRMATION_FAILURE,
  ACCREDITATION_AFFIRMATION_SUCCESS,
  CLEAR_ACCREDITATION_AFFIRMATION_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  errorCode: null,
  attempting: false,
  success: null,
};

export const AccreditationAffirmationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCREDITATION_AFFIRMATION_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        success: null,
      };
    case ACCREDITATION_AFFIRMATION_SUCCESS:
      return {
        ...state,
        attempting: false,
        errorCode: null,
        success: action.success,
      };
    case ACCREDITATION_AFFIRMATION_FAILURE:
      return {
        ...state,
        attempting: false,
        success: null,
        errorCode: action.errorCode,
      };
    case CLEAR_ACCREDITATION_AFFIRMATION_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
