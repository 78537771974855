import React from 'react';

// mui utils
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

// iAngels utils
import WingsLogo from '../../assets/iAngels_green_wings_logo.png';
import { MOBILE_WIDTH_460 } from '../../helpers/constants';
import { getUserSelector } from '../App/selectors';
import Enveloppe from '../../assets/enveloppe.svg';
import Text from '../../components/text';

// third party utils
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ThankYouPage = () => {
  // hooks
  const matches = useMediaQuery(MOBILE_WIDTH_460);
  const navigate = useNavigate();
  const user = useSelector(getUserSelector);

  if (!user) {
    return null;
  }

  return (
    <Container
      style={{
        padding: 0,
        marginTop: 40,
        minHeight: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={3}
        style={{
          maxWidth: 700,
          borderRadius: 0,
          padding: '60px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={WingsLogo} className="pointer" alt="logo" style={{ marginBottom: 10 }} />
        <Text classes="f-f-tgf f-f-500 f-size-16 mt-20 g3-c" style={{ fontSize: 24 }} text="Thank you for investing" />
        <Text
          classes="f-f-g f-w-400 f-size-14 g4-c"
          style={{ marginTop: 10, maxWidth: 500, textAlign: 'center', lineHeight: 1.5 }}
          variant="body2 f-size-16"
          text="We look forward to being at your service as you build your start-up investment portfolio at iAngels."
        />
        <div style={{ display: 'flex', maxWidth: 380, marginTop: 25 }}>
          <img src={Enveloppe} alt="Enveloppe" style={{ marginRight: 20 }} />
          <Text
            classes="f-f-g f-w-400 f-size-12 g4-c"
            style={{ marginTop: 10 }}
            variant="body2"
            text="An email with the wire instructions for your investment will be sent to this address: "
            extraText={user.email}
            extraClasses="g3-c"
          />
        </div>
        <div className="mt-20" style={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}>
          <Button
            variant="contained"
            className="btn f-size-12 f-w-500"
            style={{ marginTop: 15, marginLeft: 10, minWidth: 120, minHeight: 40, borderRadius: 6, marginRight: 10 }}
            onClick={() => navigate('/dashboard')}
          >
            My Portfolio
          </Button>
          <Button
            variant="contained"
            className="btn f-size-12 f-w-500"
            style={{ marginTop: 15, marginLeft: 10, minWidth: 120, minHeight: 40, borderRadius: 6, marginRight: 10 }}
            onClick={() => navigate('/investment-opportunities')}
          >
            More Opportunities
          </Button>
        </div>
      </Paper>
      <Text
        text="If you would like to share your with us about your experience so far, please leave your"
        extraText=" feedback here."
        classes="g4-c mt-20 f-size-12 italic f-f-pmp"
        handleClick={() => window.Intercom('show')}
        extraClasses="g3-c pointer"
      />
    </Container>
  );
};

export default ThankYouPage;
