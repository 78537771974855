import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { track } from '../../services/analytics';

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  zIndex: 102,
  position: 'fixed',
  bottom: '10px',
  left: 1350,
  backgroundColor: 'rgb(76,185,186)',
  color: 'white',
  '&:hover': { backgroundColor: 'rgb(107,196,196)' },
}));

const KeyboardArrowUpStyled = styled(KeyboardArrowUp)(({ theme }) => ({
  borderRadius: '50%',
  borderColor: theme.palette.primary.main,
  fontSize: '40px',
}));

const ScrollToTop = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
    track('Scroll To Top');
  };

  return (
    <div>
      {show && (
        <IconButtonStyled onClick={handleClick}>
          <KeyboardArrowUpStyled />
        </IconButtonStyled>
      )}
    </div>
  );
};

export default ScrollToTop;
