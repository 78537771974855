import { all, call, put, takeLatest } from 'redux-saga/effects';

import { GET_DASHBOARD_ATTEMPT, SECONDARY_SALE_ATTEMPT } from './actionTypes';
import { getDataFromService, postData } from '../../services/apiGateway';

import {
  getDashboardSuccess,
  getDashboardFailure,
  secondarySaleRequestedSuccess,
  secondarySaleRequestedFailure,
} from './actions';

function* getDashboard() {
  try {
    const dashboard = yield call(getDataFromService, '/dashboard');
    yield put(getDashboardSuccess(dashboard?.data));
  } catch (errorCode) {
    yield put(getDashboardFailure(errorCode.response || errorCode));
  }
}

function* secondarySellRequest(action) {
  try {
    const result = yield call(postData, '/iangels/investments/secondary', { investmentId: action.investmentId });
    yield put(secondarySaleRequestedSuccess(action.investmentId, result?.status));
  } catch (errorCode) {
    yield put(secondarySaleRequestedFailure(action.investmentId, errorCode.response || errorCode));
  }
}

function* dashboardSaga() {
  yield all([takeLatest(GET_DASHBOARD_ATTEMPT, getDashboard)]);
  yield all([takeLatest(SECONDARY_SALE_ATTEMPT, secondarySellRequest)]);
}

export default dashboardSaga;
