import React from 'react';

// mui utils
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import { styled } from '@mui/material/styles';

// iangels utils
import PropTypes from 'prop-types';
import { getCloudinaryLogo } from '../../helpers/cloudinary';
import Text from '../text';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function RequestToSellDialog({ handleClose, handleConfirm, open, logo, name, attempting }) {
  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <>
          <BootstrapDialogTitle
            className="f-gl-400 g-c"
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ marginRight: 25 }}
          >
            By approving below, you're indicating that you would like to sell your position in: {name}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={getCloudinaryLogo(logo, 45)} alt="company logo" className="table-company-img h-w-45 pointer" />
              <Text
                classes="f-gl-400 f-size-16"
                text="If confirmed, the Investor Relations Director looking after your account will be in touch with next steps."
              />
            </div>
            <div className=" m-10">
              <Text classes="f-gl-400 f-size-12" text="*This is not a guarantee of sale" />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="g-c">
              Cancel
            </Button>
            <Button
              variant="contained"
              className={attempting ? 'btn-disabled' : 'btn bold'}
              style={{
                minHeight: 40,
                borderRadius: 0,
              }}
              onClick={handleConfirm}
              disabled={attempting}
            >
              Confirm
            </Button>
          </DialogActions>
        </>
      </BootstrapDialog>
    </div>
  );
}
