import React from 'react';
import Text from '../text';
import IsMobileDevice from '../Generic/IsMobileDevice';

const AccordionItemValue = (props) => {
  const isMobile = IsMobileDevice();
  return (
    <Text
      variant="body2"
      classes={`f-f-tgf ${isMobile ? 'f-size-10 p-10' : 'f-size-16 p-10 pt-0'} lh-22 `}
      //style={{ marginTop: 7 }}
      text={props.subTitle}
    />
  );
};

export default AccordionItemValue;
