import { createSelector } from 'reselect';

const getAttemptingDashboard = (state) => state.dashboard.attempting;
const getSuccessDashboard = (state) => state.dashboard.dashboard;
const getErrorDashboard = (state) => state.dashboard.errorCode;
const getPublicHoldings = (state) => state.dashboard.dashboard?.assets?.publicHoldings;
const getIAngels = (state) => state.dashboard.dashboard?.assets?.iangels;
const getICapital = (state) => state.dashboard.dashboard?.assets?.icapital;
const getLpsData = (state) => state.dashboard.dashboard?.assets?.lpsData;
const getEntities = (state) => state.dashboard.dashboard?.entities;
const getPortfolios = (state) => state.dashboard.dashboard?.portfolios;

const getIAngelsUnrealizedInvestments = (state) => state.dashboard.dashboard?.assets?.iangels?.unrealizedInvestments;
const getICapitalUnrealizedInvestments = (state) => state.dashboard.dashboard?.assets?.icapital?.unrealizedInvestments;

const getIAngelsRealizedInvestments = (state) => state.dashboard.dashboard?.assets?.iangels?.realizedInvestments;
const getICapitalRealizedInvestments = (state) => state.dashboard.dashboard?.assets?.icapital?.realizedInvestments;
const getStockMarketsLastPulls = (state) => state.dashboard.dashboard?.stockMarketsLastPulls;

const getSecondarySaleRequested = (state) => state.dashboard.secondarySaleRequested;
const getExportToPdf = (state) => state.dashboard.exportPdf;
const getLastFetchTime = (state) => state.dashboard.lastDashboardFetch;

export const getAttemptingDashboardSelector = createSelector(getAttemptingDashboard, (attempting) => attempting);
export const getDashboardSelector = createSelector(getSuccessDashboard, (dashboard) => dashboard);
export const getErrorDashboardSelector = createSelector(getErrorDashboard, (errorCode) => errorCode);
export const getPublicHoldingsSelector = createSelector(getPublicHoldings, (publicHoldings) => publicHoldings);
export const getIAngelsSelector = createSelector(getIAngels, (iangels) => iangels);
export const getICapitalSelector = createSelector(getICapital, (icapital) => icapital);
export const getLpsDataSelector = createSelector(getLpsData, (lpsData) => lpsData);
export const getEntitiesSelector = createSelector(getEntities, (entities) => entities);
export const getPortfoliosSelector = createSelector(getPortfolios, (portfolios) => portfolios);
export const getLastFetchTimeSelector = createSelector(getLastFetchTime, (lastFetchTime) => lastFetchTime);

export const stockMarketsLastPullsSelector = createSelector(
  getStockMarketsLastPulls,
  (stockMarketsLastPulls) => stockMarketsLastPulls
);

export const getIAngelsUnrealizedInvestmentsSelector = createSelector(
  getIAngelsUnrealizedInvestments,
  (unrealizedInvestments) => unrealizedInvestments
);

export const getICapitalUnrealizedInvestmentsSelector = createSelector(
  getICapitalUnrealizedInvestments,
  (unrealizedInvestments) => unrealizedInvestments
);

export const getIAngelsRealizedInvestmentsSelector = createSelector(
  getIAngelsRealizedInvestments,
  (realizedInvestments) => realizedInvestments
);

export const getICapitalRealizedInvestmentsSelector = createSelector(
  getICapitalRealizedInvestments,
  (realizedInvestments) => realizedInvestments
);
export const getSecondarySaleRequestedSelector = createSelector(
  getSecondarySaleRequested,
  (secondarySaleRequested) => secondarySaleRequested
);
export const getExportToPdfSelector = createSelector(getExportToPdf, (exportPdf) => exportPdf);
