import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';

import {
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Button,
  CircularProgress,
  LinearProgress,
  Checkbox,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import {
  getRoundsSelector,
  getRoundsAttemptingSelector,
  getSubmitAttemptingSelector,
  getSubmitSuccessSelector,
  getSubmitErrorSelector,
} from './selectors';
import { getIsAdminSelector, getAuthAttemptingSelector } from '../App/selectors';
import { getRoundsAttempt, submitAttempt, clearUploaderState } from './actions';

const DocumentsUploader = () => {
  const initialState = {
    isCollective: 'false',
    documentType: '',
    year: '',
    month: 1,
    round: null,
    file: '',
    sendMail: true,
    notifiedTeam: true,
    attachDocument: false
  };

  const [state, setState] = useState(initialState);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAdmin = useSelector(getIsAdminSelector);
  const authLoading = useSelector(getAuthAttemptingSelector);
  const loading = useSelector(getRoundsAttemptingSelector);
  const rounds = useSelector(getRoundsSelector);
  const loadingSubmit = useSelector(getSubmitAttemptingSelector);
  const submitFailure = useSelector(getSubmitErrorSelector);
  const submitSuccess = useSelector(getSubmitSuccessSelector);

  useEffect(() => {
    if (isAdmin) dispatch(getRoundsAttempt());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (submitFailure) {
      dispatch(clearUploaderState());
      navigate('/error');
    }
    if (submitSuccess) {
      dispatch(clearUploaderState());
      navigate('/uploadersubmitted');
    }
  }, [submitFailure, submitSuccess, navigate, dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = {
      form: {
        file: state.file,
        round: state.round.id,
        year: state.year,
        month: state.month,
        documentType: state.documentType,
        isCollective: state.isCollective,
        sendMail: state.sendMail,
        notifiedTeam: state.notifiedTeam,
        attachDocument: state.attachDocument
      },
    };

    dispatch(submitAttempt(form));
  };

  const handleChange = (event) => {
    let { name, value, checked } = event.target;

    switch (name) {
      case 'documentType':
        if (value === 'k1') {
          setState({ ...state, attachDocument: false });
        }
        break;
      case 'isCollective':
        setState(initialState);
        break;
      case 'sendMail':
        value = checked;
        break;
      case 'notifiedTeam':
        value = checked;
        break;
      case 'attachDocument':
        value = checked;
        break
      default:
        break;
    }

    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));

    setDisableSubmit(Object.values({ ...state, [name]: value }).filter((d) => d === '' || d === null).length > 0);
  };

  return authLoading ? (
    <div className="p-50">
      <h1>
        Loading...
        <LinearProgress />
        <br /> Please wait
      </h1>
    </div>
  ) : isAdmin ? (
    <div style={{ scroll: 'auto' }} className="p-50">
      <h1>Documents Uploader</h1>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ m: 1 }} variant="standard">
          <RadioGroup
            row
            aria-labelledby="demo-error-radios"
            name="isCollective"
            value={state.isCollective}
            onChange={handleChange}
          >
            <FormControlLabel value={false} control={<Radio />} label="Individual" />
            <FormControlLabel value={true} control={<Radio />} label="Collective" />
          </RadioGroup>
        </FormControl>

        <br />
        <br />

        <TextField
          select
          id="documentTypes"
          name="documentType"
          value={state.documentType}
          onChange={handleChange}
          label="Document type"
          className="textfield-uploader"
        >
          <MenuItem value="k1" disabled={state.isCollective === 'true'}>
            K1
          </MenuItem>
          <MenuItem value="capital_statements_venture" disabled={state.isCollective === 'true'}>
            Capital statements - Venture
          </MenuItem>
          <MenuItem value="financial_statements_venture" disabled={state.isCollective === 'false'}>
            Financial statements - Venture
          </MenuItem>
          <MenuItem value="financial_statements_digital_assets" disabled={state.isCollective === 'true'}>
            Financial statements - Digital Assets
          </MenuItem>
          <MenuItem value="tax_letter" disabled={state.isCollective === 'true'}>
            Tax Letter
          </MenuItem>
          <MenuItem value="capital_calls" disabled={state.isCollective === 'true'}>
            Capital Calls
          </MenuItem>
          <MenuItem value="quarterly_updates" disabled={state.isCollective === 'false'}>
            Quarterly Updates
          </MenuItem>
        </TextField>
        <br />
        <br />
        <TextField
          id="year"
          name="year"
          select
          label="Year"
          value={state.year}
          onChange={handleChange}
          className="textfield-uploader"
        >
          {[...Array(3000 - 2000 + 1).keys()].map((option) => (
            <MenuItem key={option + 2000} value={option + 2000}>
              {option + 2000}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <br />
        <TextField
          id="month"
          name="month"
          select
          label="Month"
          value={state.month}
          onChange={handleChange}
          className="textfield-uploader"
          disabled={state.documentType === 'k1' || state.documentType === 'tax_letter'}
        >
          {[...Array(12).keys()].map((option) => (
            <MenuItem key={option + 1} value={option + 1}>
              {option + 1}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <br />
        {loading || !rounds ? (
          <TextField className="textfield-uploader" value="Loading rounds....." />
        ) : (
          <Autocomplete
            id="rounds"
            name="rounds"
            className="textfield-uploader"
            options={rounds}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleChange({ target: { name: 'round', value: value } })}
            value={state.round}
            renderInput={(params) => <TextField {...params} label="Round" placeholder="Select round" />}
            isOptionEqualToValue={(option, value) => value === undefined || value === '' || option.id === value.id}
          />
        )}
        <br />
        <br />
        <TextField
          className="textfield-uploader "
          value={state.file?.name}
          InputProps={{
            endAdornment: state.file ? (
              <InputAdornment position="end" onClick={() => handleChange({ target: { name: 'file', value: '' } })}>
                <ClearIcon />
              </InputAdornment>
            ) : null,
          }}
        />
        <FileUploader
          id="file"
          name="file"
          types={['ZIP', 'PDF']}
          handleChange={(file) => handleChange({ target: { name: 'file', value: file } })}
        >
          <Button className="btn ml-10" style={{ height: 50 }}>
            Upload
          </Button>
        </FileUploader>
        <br />
        <FormControlLabel
          label="Send mail to user(s)"
          control={<Checkbox checked={state.sendMail} name="sendMail" onChange={handleChange} />}
        />
        <br />
        <FormControlLabel
          label="Send mail to the team"
          control={<Checkbox checked={state.notifiedTeam} name="notifiedTeam" onChange={handleChange} />}
        />
        <br />
        <FormControlLabel
          label="Attach document" disabled={state.documentType === 'k1' || state.documentType === ''}
          control={<Checkbox checked={state.attachDocument} name="attachDocument" onChange={handleChange} />}
        />

        <br />
        <br />
        {loadingSubmit ? (
          <CircularProgress size={20} thickness={4} className="mt-10" />
        ) : (
          <Button
            id="submit"
            className={disableSubmit ? 'btn-disabled' : 'btn'}
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            Submit
          </Button>
        )}
      </form>
    </div>
  ) : (
    <div className="p-50">
      <h1> You are not authorized </h1>
    </div>
  );
};
export default DocumentsUploader;
