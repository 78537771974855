import { createSelector } from 'reselect';

const getAccreditationAffirmationAttempting = (state) => state.accreditationAffirmation.attempting;
const getAccreditationAffirmationSuccess = (state) => state.accreditationAffirmation.success;
const getAccreditationAffirmationError = (state) => state.accreditationAffirmation.errorCode;

export const getAccreditationAffirmationAttemptingSelector = createSelector(
  getAccreditationAffirmationAttempting,
  (attempting) => attempting
);

export const getAccreditationAffirmationSuccessSelector = createSelector(
  getAccreditationAffirmationSuccess,
  (success) => success
);
export const getAccreditationAffirmationErrorSelector = createSelector(
  getAccreditationAffirmationError,
  (errorCode) => errorCode
);
