import {
  GET_INVESTMENT_INVESTMENT_DATA_ATTEMPT,
  GET_INVESTMENT_INVESTMENT_DATA_FAILURE,
  GET_INVESTMENT_INVESTMENT_DATA_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const getInvestmentDataAttempt = (data) => ({
  type: GET_INVESTMENT_INVESTMENT_DATA_ATTEMPT,
  data,
});

export const getInvestmentDataSuccess = (investmentData) => ({
  type: GET_INVESTMENT_INVESTMENT_DATA_SUCCESS,
  investmentData,
});

export const getInvestmentDataFailure = (errorCode) => ({
  type: GET_INVESTMENT_INVESTMENT_DATA_FAILURE,
  errorCode,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
