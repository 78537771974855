import React from 'react';
import Paper from '@mui/material/Paper';
import MemoTitle from './MemoTitle';
import MemoSubTitle from './MemoSubTitle';
import MemoHtmlParser from './MemoHtmlParser';
import Typography from '@mui/material/Typography';

const MemoWrapper = (props) => {
  const classes = `${props.classes || ''} mb-20 p-25 f-gl-400 f-size-16 preline`;
  return (
    <Paper
      key={props.id}
      id={props.id}
      elevation={3}
      style={{ borderRadius: 0 }}
      suppressContentEditableWarning={true}
      className={classes}
    >
      {props.title ? <MemoTitle title={props.title} /> : null}
      {props.subTitle ? <MemoSubTitle title={props.subTitle} /> : null}
      {props.contentHtml ? <MemoHtmlParser content={props.contentHtml} classes={props.classesContentHtml} /> : null}
      {props.content ? <Typography classes="m-15">{props.content}</Typography> : null}
      <div>{props.children}</div>
    </Paper>
  );
};

export default MemoWrapper;
