import React, { useEffect, useState } from 'react';

// iAngels utils
import InvestmentTable from '../../../components/Dashboard/InvestmentTable';
import IangelsDigitalIcon from '../../../assets/accordion/iangels_digital assets_table.png';
import IangelsVenturesIcon from '../../../assets/accordion/iangels_ventures_table.png';
import IangelsVentures2Icon from '../../../assets/accordion/iangels_ventures2_table.png';
import IangelsIcon from '../../../assets/accordion/iangels_table.png';

import DesktopAccordion from '../../../components/Accordion/DesktopAccordion';
import MobileAccordion from '../../../components/Accordion/MobileAccordion';

import { ROWS_TYPES } from '../../../helpers/constants';

const DashboardAccordion = ({
  investments,
  mobile,
  isExpanded,
  rowType,
  tableHeadTitles,
  icon,
  title,
  totalTitle,
  changeTitle,
  valueTitle,
  stockMarketsLastPulls,
  accordionClass,
  textClass,
}) => {
  const [accordionExpanded, setAccordionExpanded] = useState(isExpanded ? isExpanded : false);

  useEffect(() => {
    setAccordionExpanded(isExpanded);
  }, [isExpanded]);

  const createInvestments = () => {
    let accordionDetails = [];
    let mobileHeaderBgColor = '#4CBCBF';
    if (investments?.getIangels?.length > 0) {
      accordionDetails.push(
        <InvestmentTable
          tableHeadTitles={tableHeadTitles}
          hasTableTitle={true}
          investments={investments.getIangels}
          title={title}
          subTitle="iAngels"
          icon={IangelsIcon}
          tableSummary={investments?.getIangelsTotalInvested}
          headerColor={mobile ? mobileHeaderBgColor : 'rgba(76,185,186,0.2)'}
          rowType={rowType}
          totalTitle={totalTitle}
          changeTitle={changeTitle}
          valueTitle={valueTitle}
          accordionExpanded={accordionExpanded}
          borderTop="border-top-iangels"
        />
      );
      mobileHeaderBgColor = null;
    }

    if (investments?.getIcapital?.length > 0) {
      accordionDetails.push(
        <InvestmentTable
          tableHeadTitles={tableHeadTitles}
          hasTableTitle={true}
          investments={investments.getIcapital}
          title={title}
          subTitle="Digital Assets"
          icon={IangelsDigitalIcon}
          tableSummary={investments.getIcapitalTotalInvested}
          headerColor={mobile ? mobileHeaderBgColor : 'rgba(244,164,129,0.2)'}
          rowType={rowType}
          totalTitle={totalTitle}
          changeTitle={changeTitle}
          valueTitle={valueTitle}
          accordionExpanded={accordionExpanded}
          isIcapital={true}
          borderTop="border-top-digital-assets"
        />
      );
      mobileHeaderBgColor = null;
    }

    if (investments?.getLpsData?.length > 0) {
      investments.getLpsData.map((section, index) =>
        accordionDetails.push(
          <InvestmentTable
            entityName={section?.entityName}
            tableHeadTitles={tableHeadTitles}
            hasTableTitle={true}
            investments={
              ROWS_TYPES.REALIZED === rowType ? section?.realizedInvestments : section?.unrealizedInvestments
            }
            title={`${title}-${index}`}
            subTitle={section.name === 'Ingenuity Fund Investor II' ? 'Ventures II' : 'Ventures'}
            icon={section.name === 'Ingenuity Fund Investor II' ? IangelsVentures2Icon : IangelsVenturesIcon}
            tableSummary={investments.getLpsDataTotalInvested?.[index]}
            headerColor={mobile ? mobileHeaderBgColor : 'rgba(80,110,180,0.2)'}
            rowType={rowType}
            totalTitle={totalTitle}
            changeTitle={changeTitle}
            valueTitle={valueTitle}
            balance={ROWS_TYPES.ACTIVE === rowType ? section?.balance : null}
            fees={ROWS_TYPES.ACTIVE === rowType ? section?.fees : null}
            accordionExpanded={accordionExpanded}
            borderTop="border-top-lps"
          />
        )
      );
      mobileHeaderBgColor = null;
    }
    if (investments?.getPublicHoldings?.length > 0) {
      accordionDetails.push(
        <InvestmentTable
          tableHeadTitles={tableHeadTitles}
          hasTableTitle={true}
          investments={investments.getPublicHoldings}
          title={title}
          subTitle="Public Holdings"
          tableSummary={investments?.getInvestmentSummary}
          headerColor={mobile ? mobileHeaderBgColor : 'rgba(76,185,186,0.2)'}
          rowType={rowType}
          stockMarketsLastPulls={stockMarketsLastPulls}
          totalTitle={totalTitle}
          changeTitle={changeTitle}
          valueTitle={valueTitle}
          accordionExpanded={accordionExpanded}
          borderTop="border-top-public-holdings"
        />
      );
      mobileHeaderBgColor = null;
    }
    return accordionDetails;
  };

  return (
    <>
      {mobile ? (
        <MobileAccordion
          title={title}
          changeTitle={changeTitle}
          valueTitle={valueTitle}
          investmentSummary={investments.getInvestmentSummary}
          accordionDetails={createInvestments()}
          accordionClass={accordionClass ? accordionClass : 'accordion g1-bg g4-c'}
          textClass={textClass}
        />
      ) : (
        <DesktopAccordion
          icon={icon}
          title={title}
          totalTitle={totalTitle}
          changeTitle={changeTitle}
          valueTitle={valueTitle}
          investmentSummary={investments.getInvestmentSummary}
          accordionDetails={createInvestments()}
          setAccordionExpanded={setAccordionExpanded}
          accordionExpanded={accordionExpanded}
          accordionClass={accordionClass ? accordionClass : 'accordion w-c g3-bg'}
        />
      )}
    </>
  );
};

export default DashboardAccordion;
