import React from 'react';

import Stack from '@mui/material/Stack';
import Field from './Field';

const TwoFieldStack = ({ isMobile, fieldAObject, fieldBObject }) => {
  return (
    <Stack direction={isMobile ? 'column' : 'row'} style={{ width: '100%' }}>
      {fieldAObject ? (
        <Field
          obj={fieldAObject}
          isMobile={isMobile}
          style={{ width: 'inherit', maxWidth: isMobile ? '100%' : '50%', marginRight: isMobile ? 0 : 20, marginTop: 10 }}
        />
      ) : null}

      {fieldBObject ? (
        <Field
          obj={fieldBObject}
          isMobile={isMobile}
          style={{ width: 'inherit', maxWidth: isMobile ? '100%' : '50%', marginTop: 10 }}
        />
      ) : null}
    </Stack>
  );
};

export default TwoFieldStack;
