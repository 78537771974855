import {
  GET_STARTUP_PAGE_ATTEMPT,
  GET_STARTUP_PAGE_FAILURE,
  GET_STARTUP_PAGE_SUCCESS,
  CLEAR_STARTUP_PAGE_ERROR,
  CLEAR_STATE,
} from './actionTypes';

export const getStartupPageAttempt = (slug, isChild = false) => ({
  type: GET_STARTUP_PAGE_ATTEMPT,
  slug,
  isChild,
});

export const getStartupPageSuccess = (startupPage) => ({
  type: GET_STARTUP_PAGE_SUCCESS,
  startupPage,
});

export const getStartupPageFailure = (errorCode) => ({
  type: GET_STARTUP_PAGE_FAILURE,
  errorCode,
});

export const clearStartupPageError = () => ({
  type: CLEAR_STARTUP_PAGE_ERROR,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
