import axios from 'axios';

import { loadConfig } from '../env/config_util';
const config = loadConfig();
axios.defaults.withCredentials = true;
axios.defaults.useXDomain = true;
delete axios.defaults.headers.common['X-Requested-With'];
export function createHttpClient() {
  const baseUrl = config.config_module.base_url + '/';
  return axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    useXDomain: true,
  });
}
