import React, { useCallback, useState } from 'react';

// mui utils
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

// iangels utils
import PricingTerms from '../../components/PricingTerms';
import Text from '../../components/text';
import MemoTitle from '../../components/Memo/MemoTitle';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

import {
  convertToInternationalCurrencySystem,
  getCommaSeparatedString,
  dateToShortFormat,
  humanRoundType,
} from '../../helpers/iangels';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "'Titling Gothic Normal', sans-serif !important",
    fontWeight: '500 !important',
    fontSize: '12px !important',
    color: '#000000 !important',
    lineHeight: 1.5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "'Graphik Light', sans-serif !important",
    fontWeight: '600 ',
    fontSize: '12px !important',
  },
  border: '1px solid #FFFFFF',
  padding: '6px',
}));

const MyInvestments = ({ investments }) => {
  const isMobile = IsMobileDevice();
  const [close, setClose] = useState(false);
  const isAccelerator = investments.find((o) => o.groupingType === 'accelerator') != null;
  const closeTable = useCallback(() => {
    setClose(!close);
  }, [close]);

  const getRowEntity = (row) => {
    return !isAccelerator ? (
      row.entity
    ) : (
      <>
        {row.startupName}
        {(row.startupAcquired || row.startupClosed) && (
          <div>
            <Chip
              label={row.startupAcquired ? 'Acquired' : 'Closed'}
              size="small"
              sx={{
                fontSize: '10px',
                minWidth: '41px',
                border: 'none',
                letterSpacing: '1px',
                borderRadius: '5%',
                backgroundColor: row.startupAcquired ? '#63ba4c' : '#80808080',
              }}
              variant={isMobile ? 'outlined' : 'filled'}
              className="w-c"
            />
          </div>
        )}
      </>
    );
  };
  const getMobileHeaders = () => {
    return (
      <TableRow className="g1-bg" style={{ paddingLeft: 0, paddingRight: 0 }} sx={{ px: 0 }}>
        <StyledTableCell>Pricing Terms</StyledTableCell>
        <StyledTableCell>Amount Invested</StyledTableCell>
        <StyledTableCell>Last value</StyledTableCell>
        <StyledTableCell>Change</StyledTableCell>
      </TableRow>
    );
  };

  const getDesktopHeaders = () => {
    return (
      <TableRow className="gray1-bg f-f-tgf">
        <StyledTableCell style={{ borderRight: '1px solid #E1E1E1' }}>
          {isAccelerator ? 'Company' : 'Investing Entity'}
        </StyledTableCell>
        <StyledTableCell>Security type</StyledTableCell>
        <StyledTableCell>Round Size</StyledTableCell>
        <StyledTableCell>Pricing Terms</StyledTableCell>
        <StyledTableCell>Amount Invested</StyledTableCell>
        <StyledTableCell>Date Invested</StyledTableCell>
      </TableRow>
    );
  };

  const getDesktopRow = (row, index) => {
    return (
      <TableRow key={index}>
        <StyledTableCell component="th" scope="row" style={{ borderRight: '1px solid #E1E1E1' }}>
          {getRowEntity(row)}
        </StyledTableCell>
        <StyledTableCell>{humanRoundType(row.securityType)}</StyledTableCell>
        <StyledTableCell>{`US$ ${convertToInternationalCurrencySystem(row.roundSize, 2)}`}</StyledTableCell>
        <StyledTableCell>
          <PricingTerms investment={row} isMobile={isMobile} />
        </StyledTableCell>
        <StyledTableCell className="gray2-bg">{`US$ ${getCommaSeparatedString(
          Math.round(row.amount)
        )}`}
         {row.sidePocket  ? 
         <div>
         <Chip
         label="Side Pocket" size="small" sx={{ fontSize: '10px', minWidth: '41px', border: 'none' }}
         variant='filled'
       /> </div>: null}
        </StyledTableCell>
        <StyledTableCell>{dateToShortFormat(row.date)}</StyledTableCell>
      </TableRow>
    );
  };

  const getMobileRow = (row, index) => {
    return (
      <TableRow key={index}>
        <StyledTableCell>
          <PricingTerms investment={row} isMobile={isMobile} />
        </StyledTableCell>
        <StyledTableCell>{`US$ ${getCommaSeparatedString(Math.round(row.amount))}`}  
        {row.sidePocket  ? 
         <div><Chip
         label="Side Pocket" size="small" sx={{ fontSize: '10px', minWidth: '41px', border: 'none' }}
         variant={'outlined'}
         className={ 'gray-700' }
       /></div> : null}
       </StyledTableCell>
        <StyledTableCell>
          {row.position ? `US$ ${getCommaSeparatedString(Math.round(row.position))}` : 'N/A'}
        </StyledTableCell>
        <StyledTableCell>{row.appreciation != null ? `${Math.round(row.appreciation)}%` : 'N/A'}</StyledTableCell>
      </TableRow>
    );
  };

  const getTotalAmount = () => {
    return investments?.reduce((prev, curr) => prev + curr.totalAmount, 0);
  };

  return (
    <>
      <Stack
        className={isMobile ? 'mt-20' : 'mb-20'}
        display="flex"
        justifyContent="space-between"
        alignItems={isMobile ? 'flex-start' : 'center'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {isMobile ? null : (
          <Stack>
            <MemoTitle
              title={`My Investments in ${
                isAccelerator ? investments[0].groupingName : investments[0].investments?.[0]?.startupName
              }`}
            />
          </Stack>
        )}
        <Stack>
          <Text
            text={`Total Invested: US$ ${getCommaSeparatedString(Math.round(getTotalAmount()))}`}
            classes={isMobile ? 'f-f-tgf f-w-500 f-size-12 mb-10' : 'f-f-gl f-w-700 f-size-16 nowrap ml-80'}
          />
        </Stack>
        <Stack>
          {isMobile ? null : (
            <Button variant="text" onClick={closeTable} className="g3-c italic mr-15 bold">
              {!close ? 'Close' : 'Details'}
            </Button>
          )}
        </Stack>
      </Stack>
      <Collapse in={!close}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{ wordWrap: 'break-word' }}>
              {isMobile ? getMobileHeaders() : getDesktopHeaders()}
            </TableHead>
            <TableBody>
              {investments?.map((investment) =>
                investment?.investments?.map((row, index) =>
                  isMobile ? getMobileRow(row, index) : getDesktopRow(row, index)
                )
              )}

              {!isMobile && (
                <TableRow className="gray-bg">
                  <TableCell colSpan={4} className="f-size-12 f-f-tgf f-w-500">
                    Summary
                  </TableCell>
                  <StyledTableCell>{`US$ ${getCommaSeparatedString(Math.round(getTotalAmount()))}`}</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
};

export default MyInvestments;
