// useCheckAuthentication.js

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuthResultSelector, getExpiresInSelector } from '../containers/App/selectors';
import { clearState } from '../containers/App/actions';
import useIsSessionExpired from './useIsSessionExpired';
import { getIsImpersonatedSelector } from '../containers/Impersonate/selectors';

const useCheckAuthentication = (setIsRunning) => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const authResult = useSelector(getAuthResultSelector);
  const expiresIn = useSelector(getExpiresInSelector);
  const isImpersonated = useSelector(getIsImpersonatedSelector);

  const isSessionExpired = useIsSessionExpired(expiresIn);

  const checkAuthentication = useCallback(() => {
    if (authResult && isSessionExpired()) {
      setIsRunning(false);
      if (isImpersonated) {
        return logOut();
      }

      function logOut() {
        logout({ logoutParams: { returnTo: window.location.origin } });
        dispatch(clearState());
        localStorage?.clear();
        return;
      }
    }
  }, [authResult, isSessionExpired, dispatch, setIsRunning]);

  return checkAuthentication;
};

export default useCheckAuthentication;
