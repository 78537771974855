import React, { useEffect, useState } from 'react';

// mui utils
import Tooltip from '@mui/material/Tooltip';

// iAngels utils
import RequestToSellDialog from './RequestToSellDialog';
import { secondarySaleRequestedAttempt } from '../../containers/Dashboard/actions';
import { getSecondarySaleRequestedSelector } from '../../containers/Dashboard/selectors';

// third party utils
import { useSelector, useDispatch } from 'react-redux';

const RequestToSell = ({ investment }) => {
  // states
  const [secondarySaleRequested, setSecondarySaleRequested] = useState(investment.secondarySaleRequested);
  const [secondarySaleAttempting, setSecondarySaleAttempting] = useState(false);
  const [open, setOpen] = useState(false);

  // hooks
  const dispatch = useDispatch();

  // selectors
  const secondarySaleRequestedList = useSelector(getSecondarySaleRequestedSelector);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    dispatch(secondarySaleRequestedAttempt(investment.id));
  };

  useEffect(() => {
    if (secondarySaleRequestedList[investment.id]) {
      if (secondarySaleRequestedList[investment.id].Success) {
        setSecondarySaleAttempting(false);
        setSecondarySaleRequested(true);
        setOpen(false);
        window.analytics.track('Secondary Sell Request', { startup: investment.company, investment_id: investment.id });
      } else if (secondarySaleRequestedList[investment.id].Attempting) {
        setSecondarySaleAttempting(true);
      } else if (secondarySaleRequestedList[investment.id].Failure) {
        setOpen(false);
        setSecondarySaleAttempting(false);
      }
    }
  }, [secondarySaleRequestedList, investment]);

  return (
    <>
      {investment.enableSale && !(investment.subRows?.length > 0 || secondarySaleRequested) ? (
        <Tooltip
          title={
            <span className="f-gl-400 f-size-12">
              Clicking here tells us of your interest to sell your <br /> position in this company as a secondary
              transaction.
              <br /> Once actioned, we will try to find a suitable buyer. <br /> Please note that this is not a
              guarantee of sale.
            </span>
          }
          arrow
        >
          <span className="f-gl-400 f-size-12 pointer underline link-color" onClick={handleClickOpen}>
            <br />
            Request to Sell
          </span>
        </Tooltip>
      ) : secondarySaleRequested && !(investment.subRows?.length > 0) ? (
        <span className="f-size-12">
          <br />
          Sale Requested
        </span>
      ) : null}
      <RequestToSellDialog
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        open={open}
        name={investment.company}
        logo={investment.logo}
        attempting={secondarySaleAttempting}
      />
    </>
  );
};

export default RequestToSell;
