import React from 'react';
import { useSelector } from 'react-redux';

// mui utils

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { getExportToPdfSelector } from '../../containers/Dashboard/selectors';

// iangels utils
import SummaryRow from '../Dashboard/SummaryRow';

const DesktopAccordion = (props) => {
  const {
    icon,
    title,
    investmentSummary,
    accordionDetails,
    totalTitle,
    valueTitle,
    changeTitle,
    iconDisabled,
    setAccordionExpanded,
    accordionExpanded,
    accordionClass,
  } = props;

  const handleChange = () => {
    return setAccordionExpanded(!accordionExpanded);
  };
  const exportToPdf = useSelector(getExportToPdfSelector);
  return (
    <Accordion
      className={accordionClass}
      style={{ boxShadow: 'none' }}
      expanded={accordionExpanded}
      onChange={setAccordionExpanded ? handleChange : null}
      TransitionProps={{
        timeout: 0,
      }}
    >
      <AccordionSummary
        expanded={accordionExpanded ? 'true' : 'false'}
        aria-controls="accordion-summary-content"
        id="accordion-summary-header"
        justifycontent="right"
        className="accordionSummary"
      >
        <Table id={`MyInvestmentsToPDFHeader-${title}`.replace(/ /g, '')} className="toPdf">
          <TableHead>
            <SummaryRow
              total={investmentSummary?.totalInvested}
              totalCommitted={investmentSummary?.totalCommitted}
              lastValue={investmentSummary?.lastValue}
              change={investmentSummary?.change}
              cashBalance={investmentSummary?.cashBalance}
              totalTitle={totalTitle}
              changeTitle={changeTitle}
              valueTitle={valueTitle}
              icon={icon}
              title={title}
              accordionExpanded={accordionExpanded}
              iconDisabled={iconDisabled}
              classes="w-c"
            />
          </TableHead>
        </Table>
      </AccordionSummary>
      {accordionExpanded || exportToPdf
        ? accordionDetails?.map((investment, index) => <div key={index}>{investment}</div>)
        : null}
    </Accordion>
  );
};

export default DesktopAccordion;
