import React, { useState, useEffect } from 'react';

// mui utils
import AccordionDetails from '@mui/material/AccordionDetails';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

// iangels utils
import { MOBILE_HEAD_TITLES, ROWS_TYPES } from '../../helpers/constants';
import Row from './Row';
import SummaryRow from '../Dashboard/SummaryRow';
import IsMobileDevice from '../Generic/IsMobileDevice';

const InvestmentTable = (props) => {
  const {
    tableHeadTitles,
    investments,
    hasTableTitle,
    icon,
    tableSummary,
    headerColor,
    stockMarketsLastPulls,
    entityName,
    rowType,
    totalTitle,
    changeTitle,
    valueTitle,
    balance,
    fees,
    accordionExpanded,
    isIcapital,
    title,
    subTitle,
    borderTop,
  } = props;

  const isMobile = IsMobileDevice();
  const [open, setOpen] = useState(true);
  const [openNote, setOpenNote] = useState(!isMobile);

  useEffect(() => {
    if (accordionExpanded) setOpen(accordionExpanded);
  }, [accordionExpanded]);

  const getRowType = (index, investment, balancOrFeesRecord, isIcapital) => {
    switch (rowType) {
      case ROWS_TYPES.ACTIVE:
        return <Row index={index} investment={investment} balancOrFeesRecord={balancOrFeesRecord} rowType={rowType} />;
      case ROWS_TYPES.REALIZED:
        return <Row index={index} investment={investment} isIcapital={isIcapital} rowType={rowType} />;
      case ROWS_TYPES.PUBLIC_HOLDINGS:
        return <Row index={index} investment={investment} rowType={rowType} />;
      default:
        return null;
    }
  };

  const getDetailsType = () => {
    switch (rowType) {
      case ROWS_TYPES.ACTIVE:
        return (
          <div className="lh-16 ">
            <label className="f-details-rows-d">
              * Based on the valuation set forth in subsequent financing rounds raised by the company (if any), without
              taking into account liquidation and distribution preferences which may affect the final valuation. <br />
            </label>
            <label className="f-details-rows-d">
              ** Last value reflects estimated appreciation net of carried interest.
              <br />
              In cases where there was an advanced equity round (e.g. SAFE) or convertible note subsequent to your
              initial investment, the valuation of the company reflected in the table will be the product of : <br />{' '}
              (A) the cap valuation multiplied by (B) the discount rate of such convertible financing.{' '}
            </label>
          </div>
        );
      case ROWS_TYPES.REALIZED:
        return (
          <div className="lh-16 m-10">
            <label className="f-details-rows-d">
              {isIcapital ? (
                <>
                  * The original cost of the portion redeemed
                  <br />
                  ** The return on the portion redeemed
                </>
              ) : (
                <>
                  * Net of carry and expenses. This value takes into account the total return that has been realized.
                  There may be additional return which will be wired in future installments.
                  <br />
                  ** Represents the return on investment to date. Future payments are not taking into account in this
                  calculation.
                  <br />
                  *** Total Invested is the sum of all realized investments, not including dividend or interest
                  payments.
                </>
              )}
            </label>
          </div>
        );
      case ROWS_TYPES.PUBLIC_HOLDINGS:
        return (
          <div className="lh-16 m-10">
            <label className="f-details-rows-d">
              * LSEG Delayed Price - at close: {stockMarketsLastPulls?.gB} ,16:35 (EST - Eastern Standard Time Zone).
              <br />
            </label>
            <label className="f-details-rows-d">
              * NasdaqCM Delayed Price - at close: {stockMarketsLastPulls?.uS} ,16:05 (EST - Eastern Standard Time
              Zone).
              <br />
            </label>
            <label className="f-details-rows-d">
              * TSX Delayed Price - at close: {stockMarketsLastPulls?.cA} ,16:05 (EST - Eastern Standard Time Zone), CAD
              converted to USD.
              <br />
            </label>
            <label className="f-details-rows-d">
              * ASX Delayed Price - at close: {stockMarketsLastPulls?.aU}, 00:05 (EST - Eastern Standard Time Zone).
              <br />
              ** Last value reflects estimated appreciation net of carried interest.
            </label>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    investments.length > 0 && (
      <>
        <AccordionDetails style={{ padding: 0 }}>
          <TableContainer
            component={Paper}
            style={{ borderRadius: 0 }}
            className={isMobile ? '' : 'mt-10 ' + borderTop}
          >
            <div className={isMobile ? '' : 'ml-16 mr-16'}>
              <Table
                id={`MyInvestmentsToPDF${open ? '' : '-Header'}-${title}-${subTitle}`.replace(/ /g, '')}
                className="toPdf p-10"
              >
                <TableHead>
                  {open ? (
                    <>
                      {isMobile ? null : (
                        <SummaryRow
                          hasTableTitle={hasTableTitle}
                          icon={icon}
                          setOpen={setOpen}
                          open={open}
                          subTitle={subTitle}
                          entityName={entityName}
                          borderTop={borderTop}
                        />
                      )}

                      {headerColor ? (
                        <TableRow style={{ backgroundColor: `${headerColor}` }}>
                          {(isMobile ? MOBILE_HEAD_TITLES : tableHeadTitles)?.map((name, index) => {
                            return (
                              <TableCell
                                key={index}
                                className={`f-f-g f-w-600 f-size-14 ${isMobile ? 'break-space' : 'nowrap '} p-10 ${
                                  isMobile ? 'w-c' : 'g4-c'
                                }`}
                              >
                                {isMobile ? name.replace(/ /g, '\n') : name}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ) : null}
                    </>
                  ) : (
                    <SummaryRow
                      hasTableTitle={hasTableTitle}
                      total={tableSummary?.totalInvested}
                      totalCommitted={tableSummary?.totalCommitted}
                      lastValue={tableSummary?.lastValue}
                      change={tableSummary?.change}
                      cashBalance={tableSummary?.cashBalance}
                      totalTitle={totalTitle}
                      changeTitle={changeTitle}
                      valueTitle={valueTitle}
                      entityName={entityName}
                      subTitle={subTitle}
                      icon={icon}
                      setOpen={setOpen}
                      open={open}
                      borderTop={borderTop}
                      classes="g4-c"
                    />
                  )}
                </TableHead>
                {open ? (
                  <TableBody>
                    {investments?.map((investment, index) => (
                      <React.Fragment key={index}>{getRowType(index, investment, null, isIcapital)}</React.Fragment>
                    ))}
                    {balance?.amount ? <React.Fragment>{getRowType(0, balance, '(Cash)')}</React.Fragment> : null}
                    {fees?.amount ? <React.Fragment>{getRowType(0, fees, '(Fees)')}</React.Fragment> : null}

                    <SummaryRow
                      hasTableTitle={hasTableTitle}
                      total={tableSummary?.totalInvested}
                      totalCommitted={tableSummary?.totalCommitted}
                      lastValue={tableSummary?.lastValue}
                      change={tableSummary?.change}
                      cashBalance={tableSummary?.cashBalance}
                      totalTitle={totalTitle}
                      changeTitle={changeTitle}
                      valueTitle={valueTitle}
                      showTotalText={true}
                      classes={isMobile ? 'g3-c' : 'g4-c'}
                      title={title}
                      subTitle={subTitle}
                      borderTop="border-total"
                    />
                  </TableBody>
                ) : null}
              </Table>
            </div>
          </TableContainer>
        </AccordionDetails>
        {isMobile ? (
          <div onClick={() => setOpenNote(!openNote)} className="notes">
            Notes
          </div>
        ) : null}
        {open && openNote ? (
          <AccordionDetails className="accordion-details m-10">{getDetailsType()}</AccordionDetails>
        ) : null}
      </>
    )
  );
};

export default InvestmentTable;
