import {
  GET_ROUNDS_ATTEMPT,
  GET_ROUNDS_SUCCESS,
  GET_ROUNDS_FAILURE,
  SUBMIT_ATTEMPT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,
  CLEAR_UPLOADER_STATE,
  CLEAR_STATE,
} from './actionTypes';
export const getRoundsAttempt = (data) => ({
  type: GET_ROUNDS_ATTEMPT,
  data,
});

export const getRoundsSuccess = (rounds) => ({
  type: GET_ROUNDS_SUCCESS,
  rounds,
});

export const getRoundsFailure = (errorCode) => ({
  type: GET_ROUNDS_FAILURE,
  errorCode,
});

export const submitAttempt = (data) => ({
  type: SUBMIT_ATTEMPT,
  data,
});

export const submitSuccess = (submitRes) => ({
  type: SUBMIT_SUCCESS,
  submitRes,
});

export const submitFailure = (errorCode) => ({
  type: SUBMIT_FAILURE,
  errorCode,
});

export const clearUploaderState = () => ({
  type: CLEAR_UPLOADER_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
