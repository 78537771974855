import React, { useEffect } from 'react';

// mui utils
import Container from '@mui/material/Container';

// iangels utils
import { getKycStatusAttemptingSelector, getKycStatusSelector, getKycStatusErrorSelector } from './selectors';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import { clearKycState, kycStatusAttempt } from './actions';
import {} from '../../helpers/constants';
import KycStatusMobileTable from './KycStatusMobileTable';
import KycStatusTable from './KycStatusTable';
import Text from '../../components/text';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../App/selectors';

const KycStatus = (props) => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selectors
  const loading = useSelector(getKycStatusAttemptingSelector);
  const error = useSelector(getKycStatusErrorSelector);
  const kycStatus = useSelector(getKycStatusSelector);
  const isMobile = IsMobileDevice();
  const user = useSelector(getUserSelector);

  useEffect(() => {
    if (error) {
      dispatch(clearKycState());
      navigate('error');
      return;
    }
    if (user) {
      dispatch(kycStatusAttempt());
    }
  }, [dispatch, error, navigate, user]);

  const handleBackOfficeClick = () => {
    window.location.href = 'mailto:backoffice@iangels.com';
  };

  return (
    <Container
      style={{
        marginTop: 40,
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Text classes="g-c f-tgf-400" text="KYC Status" />
          <Text
            classes="f-gl-400"
            style={{ marginTop: 10 }}
            variant="body2"
            text="You have created the following entities:"
          />
          {isMobile ? (
            <KycStatusMobileTable kycStatus={kycStatus} />
          ) : (
            <KycStatusTable kycStatus={kycStatus}></KycStatusTable>
          )}
          <Text
            classes="f-gl-400"
            style={{ marginTop: 10 }}
            variant="body2"
            text="If you have any questions, please contact our compliance officer at "
            extraText="backoffice@iangels.com"
            handleClick={handleBackOfficeClick}
            extraClasses="g-c bold pointer"
          />
        </>
      )}
    </Container>
  );
};

export default KycStatus;
