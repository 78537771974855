import React, { useEffect, useLayoutEffect } from 'react';

// iAngels utils
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import { getInvestmentDataAttempt } from './actions';
import KycForm from '../../components/KYC/KycForm';
import Text from '../../components/text';
import {
  getInvestmentDataAttemptingSelector,
  getInvestmentDataErrorSelector,
  getInvestmentDataSuccessSelector,
} from './selectors';
import {
  getKycStatusAttemptingSelector,
  getKycStatusSelector,
  getKycStatusErrorSelector,
} from '../KycStatus/selectors';
import { track } from '../../services/analytics';

// third party utils
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// mui utils
import Stack from '@mui/material/Stack';

const Kyc = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const data = useSelector(params?.id ? getKycStatusSelector : getInvestmentDataSuccessSelector);
  const kyc = params?.id ? data.find((x) => x.id === params?.id) : data;
  const isInvestmentFlow = params?.investmentId !== undefined;
  const error = useSelector(params?.id ? getKycStatusErrorSelector : getInvestmentDataErrorSelector);
  const loading = useSelector(params?.id ? getKycStatusAttemptingSelector : getInvestmentDataAttemptingSelector);

  useEffect(() => {
    if (isInvestmentFlow)
      dispatch(
        getInvestmentDataAttempt({ investmentType: params?.investmentType, investmentId: params?.investmentId })
      );
  }, [dispatch, params, isInvestmentFlow]);

  useEffect(() => {
    if (error) {
      navigate('/error');
    } else if (!loading && data != null && isInvestmentFlow) {
      track('User Completed Investment', {
        category: 'Investment',
        startup: data.startupName,
        amount: data.amount,
        entity: data.name,
        investment_id: params.investmentId,
        investment_type: params.investmentType,
      });
    }
  }, [navigate, error, data, loading, isInvestmentFlow, params]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Stack style={{ margin: 25, minHeight: 700 }} spacing={2} direction="column" alignItems="start">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Text
            classes="g3-c f-f-tgf f-w-500 f-size-30"
            text={(params?.id ? 'Submit required documents' : 'KYC Form') + ': ' + kyc?.name}
          />
          <Text
            classes="f-f-pmp f-w-400 f-size-16 g4-c italic"
            style={{ marginTop: 10 }}
            variant="body2"
            text={
              params?.id
                ? 'Please upload the required documents below.'
                : 'Please fill out our KYC (Know Your Customer) form, which is standard for a business to verify the identity of its clients. Be assured, that all of the information provided in the KYC will remain strictly confidential.'
            }
          />
          <KycForm kyc={kyc} isInvestmentFlow={isInvestmentFlow}></KycForm>
        </>
      )}
    </Stack>
  );
};

export default Kyc;
