import { createSelector } from 'reselect';

const getAccreditationAttempting = (state) => state.accreditation.attempting;
const getAccreditationSuccess = (state) => state.accreditation.success;
const getAccreditationError = (state) => state.accreditation.errorCode;

export const getAccreditationAttemptingSelector = createSelector(
  getAccreditationAttempting,
  (attempting) => attempting
);

export const getAccreditationSuccessSelector = createSelector(getAccreditationSuccess, (success) => success);
export const getAccreditationErrorSelector = createSelector(getAccreditationError, (errorCode) => errorCode);
