import React, { useEffect, useState, useCallback } from 'react';

// mui utils
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// iangels utils
import { getUserSelector, getAuthResultSelector, getTraitsSelector } from '../../containers/App/selectors';
import { COMMON } from '../../helpers/constants';
import { track } from '../../services/analytics';
import WingsLogo from '../../assets/iAngels_black_wings_logo.png';
import Logo from '../../assets/iAngels-black-logo-small.png';
import { clearState } from '../../containers/App/actions';
import { getSquareFace } from '../../helpers/cloudinary';
import { openInNewTab } from '../../helpers/iangels';
import MobileListMenu from './MobileListMenu';
import useIsMounted from '../IsMounted';
import DesktopMenuItem from '../Menu/DesktopMenuItem';
import ModeBanner from '../Generic/ModeBanner';
import IsMobileDevice from '../Generic/IsMobileDevice';

import { loadConfig } from '../../env/config_util';

// third party lib
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// icons
import {
  DomainVerificationIcon,
  BusinessCenterIcon,
  ManageAccountsIcon,
  PsychologyIcon,
  ThumbUpAltIcon,
  BusinessIcon,
  PasswordIcon,
  LogoutIcon,
  GroupsIcon,
  PersonIcon,
  TopicIcon,
  MenuIcon,
  ArticleIcon,
} from './icons';
import { getAvatarSelector } from '../../containers/MyProfile/selectors';
import { useAuth0 } from '@auth0/auth0-react';

const Header = ({ showModeBanner, setShowModeBanner, isAdmin, isPreviewer, isImpersonated, isGuest }) => {
  const isMobile = IsMobileDevice();
  const config = loadConfig();
  const marketing_website_base_url = config.config_module.marketing_website_base_url;

  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authResult = useSelector(getAuthResultSelector);
  const avatar = useSelector(getAvatarSelector);
  const { logout, loginWithRedirect } = useAuth0();
  const traits = useSelector(getTraitsSelector);
  const user = useSelector(getUserSelector);

  const [anchorElUser, setAnchorElUser] = useState(false);
  const [logo, setLogo] = useState(Logo);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (isMounted() && window?.scrollY > 1000 && logo !== WingsLogo) {
        setLogo(WingsLogo);
      }

      if (isMounted() && window?.scrollY < 1000 && logo !== Logo) {
        setLogo(Logo);
      }
    },
    [logo, isMounted]
  );

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleNavigation(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener('scroll', (e) => handleNavigation(e));
    };
  }, [handleNavigation]);

  const handleMenu = () => {
    setAnchorElUser(!anchorElUser);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(false);
  };

  const handleLogOut = () => {
    try {
      logout({ logoutParams: { returnTo: '/' } });
    } catch (e) {
      console.log('logout error: ', e);
    }
    dispatch(clearState());
    localStorage?.clear();
  };

  const handleSign = (title, event) => {
    logout({ logoutParams: { returnTo: '/' } });
    dispatch(clearState());
    loginWithRedirect({ isSignUp: false, appState: { returnTo: window.location.href },databaseConnection: config.config_module.auth0_connection });
    track('User Nav', { category: 'Navigation', item: event });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (isMounted()) {
      setState({ ...state, [anchor]: open });
    }
  };

  const investmentItems = [
    {
      title: 'Investment Opportunities',
      func: () => navigate('/investment-opportunities'),
      icon: <BusinessCenterIcon />,
      shown: true,
      event: 'Top Nav',
    },
    {
      title: 'Managed Account',
      func: () => navigate('/startup/iangels-managed-account'),
      icon: <ManageAccountsIcon />,
      shown: true,
      event: 'Top Nav',
    },
  ];

  const aboutUsItems = [
    {
      title: 'Team',
      func: () => openInNewTab(marketing_website_base_url + 'about-us/'),
      icon: <GroupsIcon />,
      shown: true,
      event: 'Top Nav',
    },
    {
      title: 'Portfolio Companies',
      func: () => navigate('/startups'),
      icon: <BusinessIcon />,
      shown: true,
      event: 'Top Nav',
    },
  ];

  if (user) {
    aboutUsItems.push({
      title: 'Knowledge Center',
      func: () => openInNewTab(marketing_website_base_url + 'knowledge-center/'),
      icon: <PsychologyIcon />,
      shown: true,
      event: 'Top Nav',
    });
    aboutUsItems.push({
      title: 'Blog',
      func: () => openInNewTab(marketing_website_base_url + 'blog/'),
      icon: <ArticleIcon />,
      shown: true,
      event: 'Top Nav',
    });
  }

  const userSettingsItems = [
    {
      title: 'Portfolio',
      func: () => navigate('/dashboard'),
      icon: <BusinessIcon />,
      shown: true,
      event: 'User Nav',
    },
    {
      title: 'Documents',
      func: () => navigate('/documents'),
      icon: <TopicIcon />,
      shown: traits?.hasDocuments,
      event: 'User Nav',
    },
    {
      title: 'My KYC',
      func: () => navigate('/entity/verification'),
      icon: <DomainVerificationIcon />,
      shown: traits?.hasInvestments,
      event: 'User Nav',
    },
    {
      title: 'My Profile',
      func: () => navigate('/profile'),
      icon: <PersonIcon />,
      shown: true,
      event: 'User Nav',
    },
    {
      title: 'Accredit Yourself',
      func: () => navigate('/accreditation'),
      icon: <ThumbUpAltIcon />,
      shown: user?.level !== COMMON.ACCREDITED,
      event: 'User Nav',
    },
    {
      title: 'Change Password',
      func: () => navigate('/change-password'),
      icon: <PasswordIcon />,
      shown: true,
      event: 'User Nav',
    },
    {
      title: 'Sign Out',
      func: handleLogOut,
      icon: <LogoutIcon />,
      shown: !isMobile,
      event: 'User Nav',
    },
  ];

  return (
    <Box sx={{ flexGrow: 0 }}>
      <AppBar className="app-bar">
        <ModeBanner
          showModeBanner={showModeBanner}
          isPreviewer={isPreviewer}
          isImpersonated={isImpersonated}
          isAdmin={isAdmin}
          isGuest={isGuest}
          impersonatorEmail={user?.impersonator?.email}
          setShowModeBanner={setShowModeBanner}
        />
        <Container style={{ padding: 0, maxWidth: 1200 }}>
          <Toolbar>
            {/* logo image */}
            <Link href={'/dashboard'}>
              <img src={logo} className="pointer" alt="logo" style={{ marginBottom: 10 }} />
            </Link>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}></Box>
            <Box
              style={
                isMobile ? { display: 'none' } : { display: 'flex', justifyContent: 'space-between', width: '100%' }
              }
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {/* desktop drop down btn */}
                <div style={{ marginLeft: 50, display: 'flex' }}>
                  <DesktopMenuItem title="Invest" items={investmentItems} />
                  {user ? null : (
                    <>
                      <DesktopMenuItem
                        title="Investors"
                        onClick={() => openInNewTab(marketing_website_base_url + 'investors')}
                      />
                      <DesktopMenuItem
                        title="Entrepreneurs"
                        onClick={() => openInNewTab(marketing_website_base_url + 'entrepreneurs')}
                      />
                    </>
                  )}
                  <DesktopMenuItem title="About Us" items={aboutUsItems} />
                  {user ? null : (
                    <>
                      <DesktopMenuItem
                        title="Knowledge Center"
                        onClick={() => openInNewTab(marketing_website_base_url + 'knowledge-center/')}
                      />
                      <DesktopMenuItem
                        title="Blog"
                        onClick={() => openInNewTab(marketing_website_base_url + 'blog/')}
                      />
                    </>
                  )}
                </div>

                {/* desktop user avatar */}
                <div style={{ marginLeft: 50, display: 'flex' }}>
                  {authResult && user ? (
                    <DesktopMenuItem
                      title={user?.user_metadata?.full_name || user?.name || authResult?.name}
                      avatar={{
                        name: user?.name,
                        src: avatar
                          ? getSquareFace(avatar, 32)
                          : getSquareFace(user?.user_metadata?.avatar, 32) || user?.picture,
                      }}
                      items={userSettingsItems}
                    />
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        className="btn-outlined"
                        style={{
                          minHeight: 40,
                          borderRadius: 0,
                        }}
                        onClick={() => handleSign('login', 'Sign In')}
                      >
                        <span className="bold" style={{ letterSpacing: 1 }}>
                          Login
                        </span>
                      </Button>
                      <Button
                        variant="outlined"
                        className="btn nowrap"
                        style={{
                          minHeight: 40,
                          borderRadius: 0,
                        }}
                        onClick={() => handleSign('signUp', 'Sign Up')}
                      >
                        <span className="card-btn" style={{ letterSpacing: 1 }}>
                          Sign Up
                        </span>
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Box>
            {isMobile ? (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MenuIcon className="g4-c" />
              </IconButton>
            ) : null}

            {/* mobile menu */}
            <Box sx={{ flexGrow: 0 }}>
              <MobileListMenu
                anchor="right"
                user={user}
                investmentItems={investmentItems}
                aboutUsItems={aboutUsItems}
                userSettingsItems={userSettingsItems}
                handleLogOut={handleLogOut}
                toggleDrawer={toggleDrawer}
                setAnchorElUser={setAnchorElUser}
                anchorElUser={anchorElUser}
                handleCloseUserMenu={handleCloseUserMenu}
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
export default Header;
