import {
  GET_DOCUMENTS_ATTEMPT,
  GET_DOCUMENTS_FAILURE,
  GET_DOCUMENTS_SUCCESS,
  CLEAR_STATE,
  CLEAR_DOCUMENTS_STATE,
} from './actionTypes';

export const initialState = {
  documents: null,
  attempting: false,
  errorCode: null,
};

export const DocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_ATTEMPT:
      return {
        ...state,
        documents: null,
        attempting: true,
        errorCode: null,
      };
    case GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        documents: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.documents,
        errorCode: null,
        attempting: false,
      };
    case CLEAR_DOCUMENTS_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
