import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// mui utils
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';

// iAngels utils
import { getCommaSeparatedString, dateToShortFormat, dateToTextFormat, getDateText } from '../../helpers/iangels';
import { ROWS_TYPES } from '../../helpers/constants';
import RequestToSell from './RequestToSell';
import EntityInfo from './EntityInfo';
import SharedInfo from './SharedInfo';
import RolloverInfo from './RolloverInfo'
import PricingTerms from '../PricingTerms';
import Cell from './Cell';
import Text from '../text';
import IsMobileDevice from '../Generic/IsMobileDevice';
import { getExportToPdfSelector } from '../../containers/Dashboard/selectors';

const Row = (props) => {
  const { index, investment, balancOrFeesRecord, isIcapital, rowType } = props;
  const isExportToPdf = useSelector(getExportToPdfSelector);
  const [open, setOpen] = useState(false);
  const isMobile = IsMobileDevice();
  const classCellSmall = isMobile ? 'w-20' : 'w-10';
  const classCell = isMobile ? 'w-25' : 'w-10';
  //const classCellLarge = 'w-20';
  const classCellMiddle = 'w-15';
  const companyCellWidth = isMobile ? 'w-30' : 'w-20';
  const isSubrowsAvailable =
    isMobile && (investment.subRows?.length > 0 || investment.hasEarnout || investment.extraInfo);

  const getCell = (active, realized, publicHoldings, all, classes) => {
    let data = null;
    switch (rowType) {
      case ROWS_TYPES.ACTIVE:
        data = active;
        break;
      case ROWS_TYPES.REALIZED:
        data = realized;
        break;
      case ROWS_TYPES.PUBLIC_HOLDINGS:
        data = publicHoldings;
        break;
      default:
        return null;
    }
    return (
      <TableCell className={`f-rows-d ${classes}`}>
        {data}
        {all}
      </TableCell>
    );
  };
  const getKeyboardArrow = () => {
    return isSubrowsAvailable ? (
      <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="flex-start">
        <IconButton aria-label="expand row" size="small" className="pb-0 pt-0" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
    ) : null;
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        style={investment.notApproved ? { background: '#EBEBEB' } : {}}
        width={'100%'}
      >
        {
          // Company
        }
        <Cell
          index={index}
          name={investment.company}
          sector={investment.sector}
          funding={investment.funding}
          url={investment.logo}
          startupSlug={investment.startupSlug}
          widthClass={companyCellWidth}
        />

        {
          //description for active, eventType for realized, Previous Close* for  public holdings
        }
        {isMobile
          ? null
          : getCell(
              investment.description,
              investment.eventType?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) ,
              'US$ ' + investment.previousClose,
              null,
              classCellMiddle
            )}

        {
          //pricing terms for active, eventDescription for realized, Shares holdings for public holdings
        }
        {isMobile
          ? null
          : getCell(
              balancOrFeesRecord ? 'N/A' : <PricingTerms investment={investment} isMobile={isMobile} />,
              investment.eventDescription,
              getCommaSeparatedString(investment.publicHoldings),
              null,
              classCellMiddle
            )}

        {
          //Amount Invested , rollover on active and public holdings
        }
        {getCell(
          <>
            {'US$ ' + getCommaSeparatedString(Math.round(investment.amount))}
            {investment.notApproved && !isMobile ? (
              <span>
                <br />
                <Text variant="body" tx="portfolio.activeRows.fundsNotYetReceived" classes="f-size-12 bold" />
              </span>
            ) : null}
            {balancOrFeesRecord ? (
              <>
                <br /> <Text variant="body" text={balancOrFeesRecord} classes="f-rows-medium" />
              </>
            ) : null}
            {investment.sidePocket && investment.subRows?.length === 0 ? ( // Todo: Design here
              <>
                <br />
                <Text variant="body" text="( Side Pocket )" classes={isMobile ? 'f-rows-small' : 'f-rows-medium'} />
              </>
            ) : null}
            <RequestToSell investment={investment} />
             {investment.isRollover ? <RolloverInfo rollovers={investment.rollovers}/> :null}
          </>,
          <>
            {investment.eventType === 'tax_expense'? 'N/A':'US$ ' + getCommaSeparatedString(Math.round(investment.totalInvested))}
            {investment.eventType === 'Secondary' || investment.eventType === 'distributions' ? (
              <>
                <br />
                <Text variant="body" text="(Amount Sold)" classes={isMobile ? 'f-rows-small' : 'f-rows-medium'} />
              </>
            ) : null}
          </>,
           <>
             {'US$ ' + getCommaSeparatedString(Math.round(investment.amount))}
             {investment.isRollover ? <RolloverInfo rollovers={investment.rollovers}/> :null}
            </>,
          null,
          classCell
        )}

        {
          //Last value for active and public holdings, Amount Realized* for realized , rollover on realized
        }
        {getCell(
          investment.position ? (
            <>
              {investment.notApproved ? 'US$0' : 'US$ ' + getCommaSeparatedString(Math.round(investment.position))}
              {investment.junoEstimatedValueLastUpdated ? ( // Todo: Design here
                <div className="lh-5">
                  <Text variant="body" text="Updated:" classes="f-rows-small" />
                  <br />
                  <Text
                    variant="body"
                    text={dateToTextFormat(investment.junoEstimatedValueLastUpdated)}
                    classes="f-rows-small"
                  />
                </div>
              ) : null}
            </>
          ) : investment.junoEstimatedValueLastUpdated ? (
            // Todo: Design here
            <div class="lh-5">
              <Text variant="body" text="Updated:" classes="f-rows-small" />
              <br />
              <Text
                variant="body"
                text={dateToTextFormat(investment.junoEstimatedValueLastUpdated)}
                classes="f-rows-small"
              />
            </div>
          ) : balancOrFeesRecord ? (
            'N/A'
          ) : (
            'US$ 0'
          ),
          <>
          {investment.totalInvested && investment.totalReceived
            ? 'US$ ' + getCommaSeparatedString(Math.round(investment.totalReceived))
            : 'US$ 0'}
            {investment.isRollover ? <RolloverInfo rollovers={investment.rollovers}/> :null}
            </>,
          'US$ ' + getCommaSeparatedString(Math.round(investment.position)) ,
          investment.hasEarnout ? (
            <>
              <br />
              <Text variant="body" text="Potential Earn Out" classes="f-rows-medium" />
              <InfoIcon className="f-size-09" />
            </>
          ) : investment.eventType === 'Redemption' && investment.junoRedemptionType ? (
            <>
              <br />
              <Text
                variant="body"
                text={`( ${investment.junoRedemptionType} )`}
                classes={isMobile ? 'f-rows-small' : 'f-rows-medium'}
              />
            </>
          ) : null,
          classCell
        )}

        {
          //Change for Active and PublicHoldings , Return/Loss for Realized
        }
        {getCell(
          <>
            {getKeyboardArrow()}
            <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center">
              {investment.notApproved
                ? '0%'
                : investment.appreciation
                ? Math.round(investment.appreciation) + '%'
                : investment.appreciation === 0
                ? '0%'
                : 'N/A'}
            </Box>
          </>,
          <>
            {getKeyboardArrow()}
            <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center">
              {(investment.eventType === 'dividend' || investment.eventType === 'interest' ? 'Yield: ' : investment.eventType === 'tax_expense'?'Tax: ' : '') +
                Math.round(investment.returnLoss * 100) +
                '%'}
            </Box>
          </>,
          <>
            {getKeyboardArrow()}
            {investment.appreciation ? Math.round(investment.appreciation) + '%' : '0%'}
          </>,
          null,
          classCellSmall + (isSubrowsAvailable ? ' pt-0' : '')
        )}
        {
          //Date of investment
        }
        {isMobile
          ? null
          : getCell(
              investment.date ? (
                ''
              ) : investment.yearUpdatedBalance || investment.quarterUpdatedBalance ? (
                <>
                  <div>Last Updated:</div>
                  {investment.quarterUpdatedBalance?.toUpperCase()} {investment.yearUpdatedBalance}
                </>
              ) : (
                'N/A'
              ),
              null,
              null,
              investment.date ? (
                <>
                  {investment.subRows?.length > 0 || investment.hasEarnout || investment.extraInfo ? (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="f-rows-d-several-dates c-border-bottom">
                            {getDateText(investment)}
                          </TableCell>
                          <TableCell className="f-rows-d-several-dates c-border-bottom">
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    getDateText(investment)
                  )}
                  {investment.sharedInfo ? <SharedInfo sharedInfo={investment.sharedInfo} /> : null}
                </>
              ) : (
                ''
              ),
              classCellSmall
            )}
      </TableRow>

      {open || isExportToPdf ? (
        <>
          {investment.hasEarnout ? (
            <TableRow className="extra-rows">
              <TableCell colSpan={7} className={`f-rows-d`}>
                This exit has earnout potential which may result in additional distributions for you in the future. To
                get more information regarding the potential earnout and your potential share please contact the person
                handling your account.
              </TableCell>
            </TableRow>
          ) : null}
          {investment.extraInfo ? (
            <TableRow className="extra-rows">
              <TableCell colSpan={7} className={`f-rows-d`}>
                {investment.extraInfo}
              </TableCell>
            </TableRow>
          ) : null}
          {investment.subRows.map((extraRow, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <TableRow className="extra-rows" style={extraRow.notApproved ? { background: '#EBEBEB' } : {}}>
                <TableCell className={`${companyCellWidth}`}></TableCell>
                {isMobile ? null : <TableCell></TableCell>}
                {isMobile
                  ? null
                  : getCell(
                      <PricingTerms investment={extraRow} isSubRow={true} isMobile={isMobile} />,
                      isIcapital ? (
                        ''
                      ) : extraRow.status === 'secondary' ? (
                        extraRow.description || 'N/A'
                      ) : (
                        <>
                          {extraRow.name || 'N/A'}
                          {extraRow.status === 'future' ? <div class="badge">Future</div> : null}
                        </>
                      ),
                      getCommaSeparatedString(extraRow.publicHoldings)
                    )}

                {getCell(
                  <>
                    {extraRow.isHideInvestedAndChange
                      ? 'N/A'
                      : 'US$ ' + getCommaSeparatedString(Math.round(extraRow.amount))}
                    {extraRow.notApproved && !isMobile ? (
                      <span>
                        <br />
                        <Text variant="body" tx="portfolio.activeRows.fundsNotYetReceived" classes="f-size-12 bold" />
                      </span>
                    ) : null}
                    {extraRow.sidePocket ? ( // Todo: Design here
                      <>
                        <br />
                        <Text
                          variant="body"
                          text="( Side Pocket )"
                          classes={isMobile ? 'f-rows-small' : 'f-rows-medium'}
                        />
                      </>
                    ) : null}
                    <RequestToSell investment={extraRow} />
                  </>,
                  '',
                  'US$ ' + getCommaSeparatedString(Math.round(extraRow.amount)),
                  extraRow.isRollover ? <RolloverInfo rollovers={extraRow.rollovers}/> : null,
                  classCell
                )}

                {getCell(
                  <>
                    {extraRow.notApproved ? 'US$0' : 'US$ ' + getCommaSeparatedString(Math.round(extraRow.position))}
                    {extraRow.sidePocket && extraRow.junoEstimatedValueLastUpdated ? ( // Todo: Design here
                      <>
                        <div class="lh-5">
                          <Text variant="body" text="Updated:" classes="f-rows-small" />
                          <br />
                          <Text
                            variant="body"
                            text={dateToTextFormat(extraRow.junoEstimatedValueLastUpdated)}
                            classes="f-rows-small"
                          />
                        </div>
                      </>
                    ) : null}
                  </>,
                  'US$ ' + getCommaSeparatedString(Math.round(extraRow.amountDistribution)),
                  'US$ ' + getCommaSeparatedString(Math.round(extraRow.position)),
                  extraRow.junoRedemptionType ? (
                    <>
                      <br />
                      <Text
                        variant="body"
                        text={`( ${extraRow.junoRedemptionType} )`}
                        classes={isMobile ? 'f-rows-small' : 'f-rows-medium'}
                      />
                    </>
                  ) : null,
                  classCell
                )}

                {getCell(
                  extraRow.isHideInvestedAndChange
                    ? 'N/A'
                    : extraRow.notApproved
                    ? '0%'
                    : extraRow.appreciation
                    ? Math.round(extraRow.appreciation) + '%'
                    : '0%',
                  extraRow.junoRedemptionType
                    ? ''
                    : extraRow.returnLoss !== undefined
                    ? Math.round((extraRow.returnLoss + Number.EPSILON) * 100) / 100 + '%'
                    : 'N/A',
                  extraRow.appreciation ? getCommaSeparatedString(Math.round(extraRow.appreciation) + '%') : '0%',
                  null,
                  classCell
                )}

                {isMobile
                  ? null
                  : getCell(
                      '',
                      '',
                      '',
                      <>
                        {dateToShortFormat(extraRow.date)}
                        {extraRow.sharedInfo ? (
                          <SharedInfo sharedInfo={extraRow.sharedInfo} />
                        ) : !extraRow.is_Lp ? (
                          <EntityInfo entity={extraRow.entity} />
                        ) : null}
                      </>
                    )}
              </TableRow>

              {extraRow.isHideInvestedAndChange ? (
                <TableRow className="extra-rows">
                  <TableCell colSpan={7} className={`f-details-rows-d`}>
                    * Please note this round reflects additional shares that were provided to iAngels and its respective
                    investors upon the company's IPO and is present to represent value only. As no money was invested
                    here, there are no legal structures or tax documents that will be issued with respect to this round.
                  </TableCell>
                </TableRow>
              ) : null}
            </React.Fragment>
          ))}
        </>
      ) : null}
    </React.Fragment>
  );
};

export default Row;
