import {
  UPDATE_EMAIL_VERIFICATION_ATTEMPT,
  UPDATE_EMAIL_VERIFICATION_FAILURE,
  UPDATE_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_EMAIL_VERIFICATION_CLEAR,
} from './actionTypes';

export const updateEmailVerificationAttempt = (email, message, update_auth0) => ({
  type: UPDATE_EMAIL_VERIFICATION_ATTEMPT,
  email,
  message,
  update_auth0,
});

export const updateEmailVerificationSuccess = () => ({
  type: UPDATE_EMAIL_VERIFICATION_SUCCESS,
});

export const updateEmailVerificationFailure = (errorCode) => ({
  type: UPDATE_EMAIL_VERIFICATION_FAILURE,
  errorCode,
});

export const updateEmailVerificationClear = () => ({
  type: UPDATE_EMAIL_VERIFICATION_CLEAR,
});
