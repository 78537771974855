import React, { useState } from 'react';

// mui utils
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// third party utils
import { useTranslation } from 'react-i18next';

// iangels utils
import TotalAccordion from './DashboardAccordions/TotalAccordion';
import DashboardAccordion from './DashboardAccordions/DashboardAccordion';
import ActiveIcon from '../../assets/accordion/top_unrealized.png';
import RealizedIcon from '../../assets/accordion/top_realized.png';
import PublicHoldingsIcon from '../../assets/accordion/top_public_holding.svg';
import MultiSelect from '../../components/Dashboard/MultiSelect';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

import { HEAD_TITLES, ROWS_TYPES } from '../../helpers/constants';

// accordion classes
import PublicHoldings from '../../lib/public-holdings';
import Realized from '../../lib/realized';
import Text from '../../components/text';
import Active from '../../lib/active';

import {
  stockMarketsLastPullsSelector,
  getPublicHoldingsSelector,
  getEntitiesSelector,
  getPortfoliosSelector,
  getICapitalSelector,
  getIAngelsSelector,
  getLpsDataSelector,
} from './selectors';
import { getUserSelector } from '../App/selectors';

import { setExportToPdf } from './actions';

// third party
import { useSelector, useDispatch } from 'react-redux';
import { exportPdf } from './exportPdf';
import { track } from '../../services/analytics';

const Portfolio = () => {
  // selectors
  const stockMarketsLastPulls = useSelector(stockMarketsLastPullsSelector);
  const holdings = useSelector(getPublicHoldingsSelector);
  const entities = useSelector(getEntitiesSelector);
  const portfolios = useSelector(getPortfoliosSelector);
  const icapital = useSelector(getICapitalSelector);
  const iangels = useSelector(getIAngelsSelector);
  const lpsData = useSelector(getLpsDataSelector);
  const user = useSelector(getUserSelector);

  // hooks
  const isMobile = IsMobileDevice();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // state
  const [filters, setFilters] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [expandAllAccordions, setExpandAllAccordions] = useState(false);

  const active = new Active(
    iangels?.unrealizedInvestments,
    icapital?.unrealizedInvestments,
    lpsData,
    filters,
    entities,
    portfolios?.filter((p) => !p.deployed)
  );
  const realized = new Realized(
    iangels?.realizedInvestments,
    icapital?.realizedInvestments,
    lpsData,
    filters,
    entities
  );
  const publicHoldings = new PublicHoldings(holdings?.unrealizedInvestments, filters, entities);
  let sumTotalInvestments = 0;
  sumTotalInvestments += active?.getTotalInvestments ? active?.getTotalInvestments : 0;
  sumTotalInvestments += realized?.getTotalInvestments ? realized?.getTotalInvestments : 0;
  sumTotalInvestments += publicHoldings?.getTotalInvestments ? publicHoldings?.getTotalInvestments : 0;

  const minimumRowsForCollapsedView = 8;
  const exceedMinimumRows = sumTotalInvestments >= minimumRowsForCollapsedView;

  const hasActive = active?.iangels?.length > 0 || active?.icapital?.length > 0 || active?.lpsData?.length > 0;
  const hasRealized = realized?.totalInvestments > 0;
  const hasPublicHoldings = publicHoldings?.unrealizedInvestments?.length > 0;

  const totalInvestments = () => {
    let totalInvested =
      active?.getInvestmentSummary?.totalInvested + publicHoldings.getInvestmentSummary?.totalInvested || 0;

    let lastValue = active?.getInvestmentSummary?.lastValue + publicHoldings.getInvestmentSummary?.lastValue || 0;

    let change = 0;

    if (totalInvested) {
      change = Math.round((lastValue / totalInvested - 1) * 100);
    }
    let totalCashBalance = 0;
    let entitiesForCashBalance = filters.length > 0 ? filters : entities;
    if (entitiesForCashBalance) {
      entitiesForCashBalance.forEach(function (entity) {
        if (entity.cashBalance) {
          totalCashBalance += entity.cashBalance;
        }
      });
    }

    return {
      totalInvested: totalInvested,
      lastValue: lastValue,
      change: change,
      cashBalance: totalCashBalance ? Math.round(totalCashBalance) : null,
    };
  };

  const hasPortfolio = () => {
    return (
      active?.iangels?.length > 0 ||
      active?.icapital?.length > 0 ||
      active?.lpsData?.length > 0 ||
      realized?.iangels?.length > 0 ||
      realized?.icapital?.length > 0 ||
      realized?.lpsData?.length > 0 ||
      publicHoldings?.unrealizedInvestments?.length > 0
    );
  };
  const totals = totalInvestments();
  const hasTotal = (totals.cashBalance && totals.cashBalance > 0) || (totals.totalInvested !== 0 && hasPublicHoldings);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportToPdf = (all) => {
    handleClose();
    if (all) {
      // Expand all accordions before exporting
      setExpandAllAccordions(true);
      dispatch(setExportToPdf(true));
      // Wait for state update, then export PDF
      setTimeout(() => {
        try {
          exportPdf(user, true);
          track('Successfully export to pdf');
        } catch (e) {
          console.error('Error exporting PDF:', e);
        }

        setExpandAllAccordions(false);
        dispatch(setExportToPdf(false));
      }, 1500); // Adjust delay as needed
    } else {
      try {
        exportPdf(user, false);
        track('Successfully export to pdf');
      } catch (e) {
        console.error('Error exporting PDF:', e);
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {hasPortfolio() ? (
        <Stack
          spacing={2}
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems={isMobile ? 'start' : 'center'}
          style={{ paddingTop: 15 }}
        >
          <Text
            variant="h5"
            classes="f-f-pmp f-w-400 italic f-size-30 lh-36 g4-c"
            text="Your Portfolio"
            style={isMobile ? { marginTop: 20 } : null}
          />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ minWidth: isMobile ? '100%' : 250 }}
          >
            <div
              style={{
                marginRight: 25,
                paddingBottom: 15,
                minWidth: isMobile ? '100%' : 250,
                maxWidth: isMobile ? '100%' : 400,
              }}
            >
              {entities?.length > 1 ? (
                <MultiSelect
                  items={entities}
                  label="Investment Entity"
                  placeholder="Select Entity"
                  selectAllLabel="All"
                  onChange={setFilters}
                  t={t}
                />
              ) : null}
            </div>
            {isMobile ? null : (
              <div
                style={{
                  paddingBottom: 10,
                }}
              >
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="outlined"
                  className="btn-outlined-g4-c"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Export to PDF
                </Button>
                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleExportToPdf(false)} disableRipple>
                    Export Current
                  </MenuItem>
                  <MenuItem onClick={() => handleExportToPdf(true)} disableRipple>
                    Export All
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Stack>
        </Stack>
      ) : null}

      <div id="Portfolio">
        {hasActive || hasRealized || hasPublicHoldings ? (
          <DashboardAccordion
            investments={active}
            mobile={isMobile}
            isExpanded={(!hasRealized && !hasPublicHoldings) || !exceedMinimumRows || expandAllAccordions}
            rowType={ROWS_TYPES.ACTIVE}
            tableHeadTitles={HEAD_TITLES.Active}
            icon={ActiveIcon}
            title={'Active Portfolio'}
            totalTitle={'Total Invested'}
            changeTitle={'Change'}
            valueTitle={'Last Value'}
          />
        ) : null}
        {hasPublicHoldings ? (
          <DashboardAccordion
            investments={publicHoldings}
            mobile={isMobile}
            isExpanded={(!hasActive && !hasRealized) || !exceedMinimumRows || expandAllAccordions}
            rowType={ROWS_TYPES.PUBLIC_HOLDINGS}
            tableHeadTitles={HEAD_TITLES.PublicHoldings}
            icon={PublicHoldingsIcon}
            title={'Public Holdings'}
            totalTitle={'Total Invested'}
            changeTitle={'Change'}
            valueTitle={'Last Value'}
            stockMarketsLastPulls={stockMarketsLastPulls}
          />
        ) : null}
        {hasTotal ? <TotalAccordion totalInvestments={totalInvestments()} mobile={isMobile} /> : null}
        {hasRealized ? (
          <DashboardAccordion
            investments={realized}
            mobile={isMobile}
            isExpanded={(!hasActive && !hasPublicHoldings) || !exceedMinimumRows || expandAllAccordions}
            rowType={ROWS_TYPES.REALIZED}
            tableHeadTitles={HEAD_TITLES.Realized}
            icon={RealizedIcon}
            title={'Total Realized Returns'}
            totalTitle={'Total Invested'}
            changeTitle={'Return'}
            valueTitle={'Total Realized'}
            accordionClass={isMobile ? 'accordion g4-bg g4-c' : 'accordion w-c g4-bg'}
            textClass="w-c"
          />
        ) : null}
      </div>
    </div>
  );
};
export default Portfolio;
