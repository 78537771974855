import React from 'react';

import Text from '../text';

const SharedInfo = ({ sharedInfo }) => {
  return (
    <div>
      {sharedInfo.isSharedByMe ? (
        <>
          <br className="toPdf hidden" />
          <Text tx="portfolio.shared.sharedWith" classes="g-c f-size-12" />
          <br className="toPdf hidden" />
          <span className="f-size-12 underline">{sharedInfo.viewerUser}</span>
        </>
      ) : (
        <>
          <br className="toPdf hidden" />
          <Text tx="portfolio.shared.sharedBy" classes="g-c f-size-12" />
          <br className="toPdf hidden" />
          <span className="f-size-12 underline">{sharedInfo.ownerUser}</span>
        </>
      )}
    </div>
  );
};

export default SharedInfo;
