import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { getImpersonatedUserSelector } from '../containers/Impersonate/selectors';
const useGetUser = () => {
  const { user } = useAuth0();
  const impersonatedUser = useSelector(getImpersonatedUserSelector);
  // First, check if the Auth0 user is available
  if (user) {
    return user;
  }
  // Then, check if there's an impersonated user
  if (impersonatedUser) {
    return impersonatedUser;
  }
  // If no user is found, return null
  return null;
};
export default useGetUser;
