import {
  ACCREDITATION_AFFIRMATION_ATTEMPT,
  ACCREDITATION_AFFIRMATION_FAILURE,
  ACCREDITATION_AFFIRMATION_SUCCESS,
  CLEAR_ACCREDITATION_AFFIRMATION_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const accreditationAffirmationAttempt = (data) => ({
  type: ACCREDITATION_AFFIRMATION_ATTEMPT,
  data,
});

export const accreditationAffirmationSuccess = (success) => ({
  type: ACCREDITATION_AFFIRMATION_SUCCESS,
  success,
});

export const accreditationAffirmationFailure = (errorCode) => ({
  type: ACCREDITATION_AFFIRMATION_FAILURE,
  errorCode,
});

export const clearAccreditationAffirmationState = () => ({
  type: CLEAR_ACCREDITATION_AFFIRMATION_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
