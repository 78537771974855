import {
    SYNC_ATTEMPT,
    SYNC_SUCCESS,
    SYNC_FAILURE,
    CLEAR_STATE
} from './actionTypes';

export const syncAttempt = () => ({
    type: SYNC_ATTEMPT
});

export const syncSuccess = () => ({
    type: SYNC_SUCCESS
});

export const syncFailure = (errorCode) => ({
    type: SYNC_FAILURE,
    errorCode
});

export const clearState = () => ({
    type: CLEAR_STATE,
});