import {
    SYNC_ATTEMPT,
    SYNC_SUCCESS,
    SYNC_FAILURE,
    CLEAR_STATE
} from './actionTypes';

export const initialState =
{
    attempting: false,
    errorCode: null,
    success: null
};

export const SyncReducer = (state = initialState, action) => {
    switch (action.type) {
        case SYNC_ATTEMPT:
            return {
                ...state,
                attempting: true,
                errorCode: null,
                success: null
            };
        case SYNC_SUCCESS:
            return {
                ...state,
                attempting: false,
                errorCode: null,
                success: true
            };
        case SYNC_FAILURE:
            return {
                ...state,
                attempting: false,
                errorCode: action.errorCode,
                success: false

            };
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    };
};
