import {
  GET_STARTUPS_ATTEMPT,
  GET_STARTUPS_SUCCESS,
  GET_STARTUPS_FAILURE,
  GET_SECTORS_STARTUPS_ATTEMPT,
  GET_SECTORS_STARTUPS_FAILURE,
  GET_SECTORS_STARTUPS_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  startups: null,
  errorCode: null,
  startupsAttempting: false,
  sectorsAttempting: false,
  sectors: [],
};

export const StartupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STARTUPS_ATTEMPT:
      return {
        ...state,
        startupsAttempting: true,
        errorCode: null,
        startups: null,
      };
    case GET_STARTUPS_SUCCESS:
      return {
        ...state,
        startupsAttempting: false,
        errorCode: null,
        startups: action.startups,
      };
    case GET_STARTUPS_FAILURE:
      return {
        ...state,
        startupsAttempting: false,
        startups: null,
        errorCode: action.errorCode,
      };
    case GET_SECTORS_STARTUPS_ATTEMPT:
      return {
        ...state,
        sectorsAttempting: true,
        errorCode: null,
        sectors: [],
      };
    case GET_SECTORS_STARTUPS_SUCCESS:
      return {
        ...state,
        sectorsAttempting: false,
        errorCode: null,
        sectors: action.sectors,
      };
    case GET_SECTORS_STARTUPS_FAILURE:
      return {
        ...state,
        sectorsAttempting: false,
        errorCode: action.errorCode,
        sectors: null,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
