import React from 'react';
import { convertToInternationalCurrencySystem, dateToTextFormat, humanize } from '../helpers/iangels';
import Text from './text';

const CapitalPositionCard = ({ name, date, value }) => {
  return (
    <div className="d-f a-i-c j-c-sb capital-position-card mt-10">
      <div>
        <Text classes="f-f-tgf f-w-500 f-size-12 capitalize" text={humanize(name)} />
        <Text classes="f-f-g f-w-300 f-size-12 mt-5 " text={dateToTextFormat(date)} />
      </div>
      <Text classes="f-f-tgf f-w-500 f-size-16 g7-c ml-30" text={value} />
    </div>
  );
};

const CapitalPositionTitle = ({ title, isMobile }) => {
  return <Text classes={`f-f-tgf f-w-500 f-size-14 g4-c ${isMobile ? 'mt-10' : 'mt-20'}`} text={title} />;
};

const CapitalPosition = ({
  cashOnHand,
  monthlyNetBurnRate,
  operatingCashFlow,
  plansForFutureFunding,
  totalCapitalRaisedToDate,
  isMobile,
}) => {
  return (
    <>
      <CapitalPositionTitle title="Capital Position" isMobile={isMobile} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }} className="fdr-c">
        {cashOnHand && (
          <CapitalPositionCard
            name={humanize(cashOnHand.type)}
            date={cashOnHand.date}
            value={`$${convertToInternationalCurrencySystem(cashOnHand.value, 1)}`}
          />
        )}
        {monthlyNetBurnRate && (
          <CapitalPositionCard
            name={monthlyNetBurnRate.type}
            date={monthlyNetBurnRate.date}
            value={`($${convertToInternationalCurrencySystem(monthlyNetBurnRate.value, 1)})`}
          />
        )}
        {operatingCashFlow && (
          <CapitalPositionCard
            name={operatingCashFlow.type}
            date={operatingCashFlow.date}
            value={`($${convertToInternationalCurrencySystem(operatingCashFlow.value, 1)})`}
          />
        )}
      </div>
      {plansForFutureFunding?.text ? (
        <>
          <CapitalPositionTitle title="Plans for Future Funding" isMobile={isMobile} />
          <Text classes="f-gl-400 f-size-16 justify capital-position-card" text={plansForFutureFunding.text} />
        </>
      ) : null}

      {totalCapitalRaisedToDate && (
        <>
          <CapitalPositionTitle title="Funding Status" isMobile={isMobile} />
          <CapitalPositionCard
            name={totalCapitalRaisedToDate.type}
            date={totalCapitalRaisedToDate.date}
            value={`$${convertToInternationalCurrencySystem(totalCapitalRaisedToDate.value, 1)}`}
          />
        </>
      )}
    </>
  );
};

export default CapitalPosition;
