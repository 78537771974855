import {
  RESET_PASSWORD_CLEAR_STATE,
  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CLEAR_STATE,
} from './actionTypes';

export const getResetPasswordAttempt = (email) => ({
  type: RESET_PASSWORD_ATTEMPT,
  email,
});

export const getResetPasswordSuccess = (status) => ({
  type: RESET_PASSWORD_SUCCESS,
  status,
});

export const getResetPasswordFailure = (errorCode) => ({
  type: RESET_PASSWORD_FAILURE,
  errorCode,
});

export const resetPasswordClearState = () => ({
  type: RESET_PASSWORD_CLEAR_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
