import {
  ACCREDITATION_ATTEMPT,
  ACCREDITATION_FAILURE,
  ACCREDITATION_SUCCESS,
  CLEAR_ACCREDITATION_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const accreditationAttempt = (data) => ({
  type: ACCREDITATION_ATTEMPT,
  data,
});

export const accreditationSuccess = (success) => ({
  type: ACCREDITATION_SUCCESS,
  success,
});

export const accreditationFailure = (errorCode) => ({
  type: ACCREDITATION_FAILURE,
  errorCode,
});

export const clearAccreditationState = () => ({
  type: CLEAR_ACCREDITATION_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
