import React from 'react';

// third party lib
import TagManager from 'react-gtm-module';
import { createRoot } from 'react-dom/client';

import App from './containers/App';
import { loadConfig } from './env/config_util';
import { track } from './services/analytics';
// auth0
// import { Auth0Provider } from '@auth0/auth0-react';

// styles
import './index.scss';
import './themes/fonts/fonts.css';
import './themes/main.scss';
import './themes/mui_override.scss';
import './themes/memo-formatting.scss';

// router
import { BrowserRouter as Router } from 'react-router-dom';
import history from './history';

// material ui
import { createTheme, ThemeProvider } from '@mui/material/styles';

// redux
import { Provider } from 'react-redux';
import store from './store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

// react Helmet
import { HelmetProvider } from 'react-helmet-async';
import './translations';
import AuthProvider from './auth/AuthProvider';

const persistor = persistStore(store);
const config = loadConfig();

const THEME = createTheme({
  typography: {
    fontFamily: `'Graphik Light',"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  pale: {
    main: '#FCF4D9',
  },
});

// google tag manager
const tagManagerArgs = {
  gtmId: config.config_module.gtmId,
};

TagManager.initialize(tagManagerArgs);

// const onRedirectCallback = (appState) => {
//   history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
// };

const root = createRoot(document.getElementById('root'));

track('App Loaded'); // Note: might need to move this to a different location to decide when the app has actually loaded

// <Auth0Provider
//   domain={config.config_module.domain}
//   clientId={config.config_module.client_id}
//   redirectUri="http://127.0.0.1:9000/dashboard"
//   onRedirectCallback={onRedirectCallback}
//   // advancedOptions={{
//   //   defaultScope: null,
//   // }}
//   scope="openid user_id email level read:roles avatar created_at user_metadata app_metadata"

//   // seCookiesForTransactions={true}
// >
root.render(
  <AuthProvider>
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </AuthProvider>
);
