import React, { useCallback, useState } from 'react';
import { getCircleFace } from '../helpers/cloudinary';
import Text from './text';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

const MemoTeamMember = ({ image, name, info, role, tags, isMobile }) => {
  const [readMore, setReadMore] = useState(false);

  const readMoreClick = useCallback(() => {
    setReadMore(!readMore);
  }, [readMore]);

  const infoText = () => {
    return info ? (
      <div>
        <Collapse
          in={readMore}
          collapsedSize={115}
          className="ml-10 mr-10  f-f-g f-w-400 f-size-16 lh-20 "
          style={{ paddingRight: isMobile ? null : '50px' }}
        >
          <div dangerouslySetInnerHTML={{ __html: info }} className="memo-formatting justify team-member-info  mt-10" />
        </Collapse>

        <Link onClick={readMoreClick} className="btn-team ml-10">
          {!readMore ? 'Read more' : 'Read less'}
        </Link>
        <Stack direction="row" spacing={0} mt={1}>
          {tags.map((tag) => (
            <Chip label={tag} key={tag} variant="outlined" className="chip-outlined" />
          ))}
        </Stack>
      </div>
    ) : null;
  };
  return (
    <Box className="m-20 mt-30 fullwidth f-size-14" sx={{ flexDirection: 'column', display: 'flex' }}>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: isMobile ? 'center' : 'flex-start' }}>
        <img src={getCircleFace(image, 100)} alt={name} height={isMobile ? 84 : 130} width={isMobile ? 84 : 130} />
        <div>
          <Text text={name} classes={`f-f-tgf f-w-500 ml-10 ${isMobile ? 'g4-c f-size-12' : 'g7-c f-size-16 '}`} />
          <Text text={role} classes={` ml-10 italic ${isMobile ? 'g4-c f-f-g f-size-14' : 'g4-c f-f-pmp'}`} />
          {isMobile ? null : infoText()}
        </div>
      </Box>
      {isMobile ? infoText() : null}
    </Box>
  );
};

export default MemoTeamMember;
