import React, { useState } from 'react';

// mui utils
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';

// iangels utils
import Text from '../text';

const MultiSelect = ({ items, label, placeholder, selectAllLabel, onChange, t }) => {
  const [selectedOptions, setSelectedOptions] = useState(items);
  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptions.find((option) => option.value === 'select-all')) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter((el) => el.value !== 'select-all');
        return onChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);
      }
    } else if (reason === 'clear') {
      handleClearOptions && handleClearOptions();
    }
  };
  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.value === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <li
        {...props}
        className="f-gl-400"
        style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
      >
        <span>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            name={option.name}
            {...selectAllProps}
          />
        </span>
        {/* 1 means it shared */}
        <Text text={option.isShared === 1 ? t('portfolio.shared.sharedBy') + ': ' + option.name : option.name} />
      </li>
    );
  };
  const inputRenderer = (params) => (
    <TextField
      {...params}
      label={label}
      placeholder={!allSelected ? placeholder : null}
      variant="standard"
      className="text-field-g4-c"
    />
  );
  const renderTags = (tagValue, getTagProps) => {
    return (
      <>
        {allSelected ? (
          <Text text={selectAllLabel} />
        ) : (
          tagValue.map((option, index) => <Chip label={option.name} {...getTagProps({ index })} />)
        )}
      </>
    );
  };
  const filter = createFilterOptions();
  return (
    <Autocomplete
      style={{ width: '100%' }}
      multiple
      id="checkboxes-entities"
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{ name: selectAllLabel, value: 'select-all' }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      renderTags={renderTags}
    />
  );
};

MultiSelect.defaultProps = {
  items: [],
  selectedValues: [],
};

export default MultiSelect;
