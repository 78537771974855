import { createSelector } from 'reselect';

const getResetPasswordAttempting = (state) => state.resetPassword.attempting;
const getResetPasswordStatus = (state) => state.resetPassword.status;
const getResetPasswordError = (state) => state.resetPassword.errorCode;

export const getResetPasswordAttemptingSelector = createSelector(
  getResetPasswordAttempting,
  (attempting) => attempting
);

export const getResetPasswordStatusSelector = createSelector(getResetPasswordStatus, (status) => status);
export const getResetPasswordErrorSelector = createSelector(getResetPasswordError, (errorCode) => errorCode);
