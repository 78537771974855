import {
  RESEND_EMAIL_ATTEMPT,
  RESEND_EMAIL_FAILURE,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_CLEAR,
  CLEAR_STATE,
} from './actionTypes';

export const resendEmailAttempt = () => ({
  type: RESEND_EMAIL_ATTEMPT,
});

export const resendEmailSuccess = (resendEmail) => ({
  type: RESEND_EMAIL_SUCCESS,
  resendEmail,
});

export const resendEmailFailure = (errorCode) => ({
  type: RESEND_EMAIL_FAILURE,
  errorCode,
});

export const resendEmailClear = () => ({
  type: RESEND_EMAIL_CLEAR,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
