import {
  getIdentityDetailsSuccess,
  getIdentityDetailsFailure,
  identityUpdateFailure,
  identityUpdateSuccess,
} from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService, postData } from '../../services/apiGateway';
import { GET_IDENTITY_DETAILS_ATTEMPT, IDENTITY_UPDATE_ATTEMPT } from './actionTypes';

function* getIdentityDetails(action) {
  try {
    const identityDetails = yield call(getDataFromService, '/iangels/users/identity/details');
    yield put(getIdentityDetailsSuccess(identityDetails?.data));
  } catch (errorCode) {
    yield put(getIdentityDetailsFailure(errorCode.response || errorCode));
  }
}

function* updateAttempt(action) {
  try {
    const update = yield call(postData, '/iangels/users/identity/update', action.data);
    yield put(identityUpdateSuccess(update?.data));
  } catch (errorCode) {
    yield put(identityUpdateFailure(errorCode.response || errorCode));
  }
}

function* identityDetailsSaga() {
  yield all([takeLatest(GET_IDENTITY_DETAILS_ATTEMPT, getIdentityDetails)]);
  yield all([takeLatest(IDENTITY_UPDATE_ATTEMPT, updateAttempt)]);
}

export default identityDetailsSaga;
