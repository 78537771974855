import {
  CLEAN_IDENTITY_ERROR_AND_SUCCESS,
  GET_IDENTITY_DETAILS_ATTEMPT,
  GET_IDENTITY_DETAILS_SUCCESS,
  GET_IDENTITY_DETAILS_FAILURE,
  IDENTITY_UPDATE_ATTEMPT,
  IDENTITY_UPDATE_FAILURE,
  IDENTITY_UPDATE_SUCCESS,
  CLEAR_STATE,
  SET_AVATAR,
} from './actionTypes';

export const getIdentityDetailsAttempt = () => ({
  type: GET_IDENTITY_DETAILS_ATTEMPT,
});

export const setAvatar = (avatar) => ({
  type: SET_AVATAR,
  avatar,
});

export const getIdentityDetailsSuccess = (identityDetails) => ({
  type: GET_IDENTITY_DETAILS_SUCCESS,
  identityDetails,
});

export const getIdentityDetailsFailure = (errorCode) => ({
  type: GET_IDENTITY_DETAILS_FAILURE,
  errorCode,
});

export const identityUpdateAttempt = (data) => ({
  type: IDENTITY_UPDATE_ATTEMPT,
  data,
});

export const identityUpdateSuccess = (updateSuccess) => ({
  type: IDENTITY_UPDATE_SUCCESS,
  updateSuccess,
});

export const identityUpdateFailure = (errorCode) => ({
  type: IDENTITY_UPDATE_FAILURE,
  errorCode,
});

export const clearErrorAndSuccessState = () => ({
  type: CLEAN_IDENTITY_ERROR_AND_SUCCESS,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
