import React from 'react';
// third party utils
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
//
import { updateEmailVerificationAttempt, updateEmailVerificationClear } from './actions';
import { getUpdateVerificationSuccessSelector } from './selectors';
import { refreshTraitsAttempt } from '../App/actions';
import { track } from '../../services/analytics';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';

const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const updateVerificationSuccess = useSelector(getUpdateVerificationSuccessSelector);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    const message = params.get('message');
    track('Email Verification', {
      message: message,
      category: 'Verification',
    });
    track('Verified User Registered', { category: 'Verification' });
    TagManager.dataLayer({ dataLayer: { event: 'user_registered' } });
    const update_auth0 = params.get('update_auth0');
    dispatch(updateEmailVerificationAttempt(email, message, update_auth0));
  }, [dispatch, location]);

  useEffect(() => {
    if (updateVerificationSuccess) {
      dispatch(updateEmailVerificationClear());
      dispatch(refreshTraitsAttempt({}));
      navigate('/dashboard');
    }
  }, [updateVerificationSuccess, navigate, dispatch]);

  return <LoadingIndicator />;
};

export default EmailVerification;
