import { createFilteredList, getUnrealizedTotalInvested } from './investments';

export default class PublicHoldings {
  constructor(unrealizedInvestments, filters, entities) {
    this.totalInvestments = 0;
    if (filters.length > 0 && filters.length !== entities.length) {
      this.unrealizedInvestments = [];
      createFilteredList(unrealizedInvestments, filters, this.unrealizedInvestments, false, true, this.totalInvestments);
    } else {
      this.unrealizedInvestments = unrealizedInvestments;
    }
    this.totalInvestments += this.unrealizedInvestments?.length;
    this.investmentSummary = getUnrealizedTotalInvested(this.unrealizedInvestments);
  }
  get getTotalInvestments() {
    return this.totalInvestments;
  }
  get getInvestmentSummary() {
    return this.investmentSummary;
  }

  get getPublicHoldings() {
    return this.unrealizedInvestments;
  }
}
