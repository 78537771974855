import React, { useState, useEffect } from 'react';

// mui utils
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

// iAngels utils
import {
  clearTemplate,
  clearFile,
  uploadFileSuccess,
  downloadKycTemplateAttempt,
} from '../../containers/KycSubmitDocuments/actions';
import {
  downloadKycTemplateSelector,
  downloadKycTemplateErrorSelector,
} from '../../containers/KycSubmitDocuments/selectors';
import { getAuthResultSelector } from '../../containers/App/selectors';

import KycVerified from '../../assets/kyc-verified.svg';
import { FileUploader } from 'react-drag-drop-files';
import useIsMounted from '../IsMounted';
import Text from '../text';
import IsMobileDevice from '../Generic/IsMobileDevice';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import { uploadFile } from '../../helpers/iangels';

const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'PDF'];

const KycDocumentsItem = ({ approved, mainTitle, secondaryTitle, downloadKey, apiKey, options }) => {
  const isMobile = IsMobileDevice();

  // hooks
  const authResult = useSelector(getAuthResultSelector);
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const downloadKycTemplate = useSelector(downloadKycTemplateSelector);
  const downloadKycError = useSelector(downloadKycTemplateErrorSelector);
  // state
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [error, setError] = useState(null);
  const [errorDownload, setErrorDownload] = useState(null);
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setLoading(true);
    uploadFile(
      file,
      authResult.__raw,
      (response) => {
        if (isMounted()) {
          setFile(file);
          dispatch(uploadFileSuccess(response.data.imageId, apiKey));
          setError(false);
        }
      },
      (err) => {
        if (isMounted()) {
          setError(err);
        }
      },
      options
    );
  };

  const download = () => {
    setLoadingDownload(true);
    dispatch(downloadKycTemplateAttempt(downloadKey));
  };

  const deleteFile = () => {
    setFile(null);
    dispatch(clearFile(apiKey));
    setLoading(false);
  };

  useEffect(() => {
    if (downloadKycTemplate) {
      let alink = document.createElement('a');
      alink.href = downloadKycTemplate;
      alink.download = 'SamplePDF.pdf';
      alink.click();
    }
  }, [downloadKycTemplate]);

  useEffect(() => {
    if (downloadKycError) {
      setErrorDownload(downloadKycError);
    }
    if (downloadKycTemplate) {
      dispatch(clearTemplate());
      setLoadingDownload(false);
    }
  }, [dispatch, downloadKycTemplate, downloadKycError]);
  return (
    <Grid
      container
      style={{
        borderBottom: '1px solid #E1E1E1',
        width: '100%',
        paddingBottom: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
      className={`mt-30 ${approved || file ? 'g3-c' : 'g4-c'}`}
    >
      <Grid item xs={7} className="pr-5">
        <div className="d-f fdr-r">
          <Text text={mainTitle} classes="f-f-gl bold f-size-16" />
          {!approved && downloadKey ? (
            errorDownload ? (
              errorDownload
            ) : loadingDownload ? (
              <CircularProgress size={20} thickness={4} className="g-c" />
            ) : (
              <DownloadIcon className="g4-c pointer" onClick={download} />
            )
          ) : null}
        </div>
        {secondaryTitle ? (
          <div className="d-f">
            <Text text={secondaryTitle} classes="f-f-pm f-w-400 italic f-size-14" style={{ maxWidth: 250 }} />
          </div>
        ) : null}
      </Grid>

      <Grid item xs={5} className="f-f-gl f-size-16">
        {approved || file ? (
          <div className="d-f df-r">
            <img src={KycVerified} alt="approved icon" />
            <Text text={approved ? approved : file.name} classes="ml-10  file-uploaded g3-c" />
            {file ? <DeleteForeverIcon className="r-c pointer" onClick={deleteFile} /> : null}
          </div>
        ) : (
          <FileUploader
            classes="drop_zone"
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            options={options}
            onTypeError={() => setError(true)}
          >
            <div className="upload-file-container" style={{ width: isMobile ? 100 : 250 }}>
              {loading ? (
                <LinearProgress color="inherit" style={{ height: 25 }} className="primary-c" sx={{ width: '100%' }} />
              ) : (
                <>
                  <FileUploadIcon className="g-c mr-10" />
                  <Text classes="f-gl-400 f-size-16 g4-c " text={`Upload ${isMobile ? '' : '/Drag and drop here'}`} />
                </>
              )}
            </div>
          </FileUploader>
        )}
        {error ? <Text classes="mt-5 f-gl-400 f-size-12 r-c" text="This file type is not supported" /> : null}
      </Grid>
    </Grid>
  );
};

export default KycDocumentsItem;
