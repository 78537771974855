import { accreditationFailure, accreditationSuccess } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { postData } from '../../services/apiGateway';
import { ACCREDITATION_ATTEMPT } from './actionTypes';

function* accreditationAttempt(action) {
  try {
    const accreditation = yield call(postData, '/iangels/users/accreditation', action.data);
    yield put(accreditationSuccess(accreditation?.data));
  } catch (errorCode) {
    yield put(accreditationFailure(errorCode.response || errorCode));
  }
}

function* accreditationSaga() {
  yield all([takeLatest(ACCREDITATION_ATTEMPT, accreditationAttempt)]);
}

export default accreditationSaga;
