import {useEffect, useState } from 'react';

// third party utils
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// iangels utils
import { MINUTE_IN_MILLISECOND } from '../helpers/constants';
import { track, identify } from '../services/analytics';
import { loadConfig } from '../env/config_util';

import useInterval from '../components/useInterval';
import { useAuth0 } from '@auth0/auth0-react';

import {
  getIdentifyUserFailureSelector,
  getAuthAttemptingSelector,
  getUserAttemptingSelector,
  getAuthResultSelector,
  getTraitsSelector,
  getUserSelector,
  getForceUserInfoSelector,
} from '../containers/App/selectors';
import {
  clearIdentifyFailureState,
  identifyUserAttempt,
  setUserSuccess,
  setAuthResult,
  clearState,
  setForceUserInfo,
} from '../containers/App/actions';
import useCheckAuthentication from '../hooks/useCheckAuthentication';
import useGetUser from '../hooks/useGetUser';

import {getIsImpersonatedSelector } from '../containers/Impersonate/selectors';
import {setImpersonated } from '../containers/Impersonate/actions';

const RoutGuard = ({ hasNext, navigateTo, lockGuard ,isSignup}) => {
  const { loginWithRedirect, isAuthenticated, isLoading, error, getIdTokenClaims, logout } = useAuth0();
  const user = useGetUser();
  const config = loadConfig();
  // state
  const [isRunning, setIsRunning] = useState(true);
  
  // selectors
  const identifyUserFailure = useSelector(getIdentifyUserFailureSelector);
  const forceUserInfo = useSelector(getForceUserInfoSelector);
  const authLoading = useSelector(getAuthAttemptingSelector);
  const userLoading = useSelector(getUserAttemptingSelector);
  const authResult = useSelector(getAuthResultSelector);
  const traits = useSelector(getTraitsSelector);
  const userState = useSelector(getUserSelector);
  const isImpersonated = useSelector(getIsImpersonatedSelector);

  // hooks
  const checkAuthentication = useCheckAuthentication(setIsRunning);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useInterval(checkAuthentication, isRunning ? MINUTE_IN_MILLISECOND : null);

  useEffect(() => {
    if (identifyUserFailure || error) {
      dispatch(clearIdentifyFailureState());
      if (error && error.message === 'Email is required to facebook'){ 
       logout({ logoutParams: { returnTo: '/' } });
       loginWithRedirect({ isSignUp: true, appState: { returnTo: '/' } , databaseConnection: config.config_module.auth0_connection , errorDescription: 'Something went wrong and we couldn\'t complete your Facebook registration, please try different registration options or contact backoffice@iangels.com' });
      } else navigate('/error');
    }
  }, [identifyUserFailure, navigate, dispatch, error,loginWithRedirect,logout,config.config_module.auth0_connection]);

  useEffect(() => {
    const getIdToken = async () => {
      try {
        const idTokenClaims = await getIdTokenClaims();
        if(JSON.stringify(idTokenClaims) !== JSON.stringify(authResult))
        {
          dispatch(setAuthResult(idTokenClaims));
          if (!traits || JSON.stringify(traits)==='{}' || isImpersonated ) dispatch(identifyUserAttempt());
        }
      } catch (error) {
        console.error('Error while getting token:', error);
      }
    };
    //add checking to not impersonate state , clear it on logout
    if (!isLoading && isAuthenticated ) {
      getIdToken();
    }
  }, [isAuthenticated,isImpersonated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error && lockGuard && !isImpersonated) {
      dispatch(clearState());
      logout({ logoutParams: { returnTo: '/' } });
      //const isSignup = document.location.pathname.indexOf('signup') !== -1;
      loginWithRedirect({ isSignUp: isSignup, appState: { returnTo: '/' } , databaseConnection: config.config_module.auth0_connection });
    }
  }, [isLoading, isAuthenticated, error, lockGuard, loginWithRedirect,config.config_module.auth0_connection]);

  useEffect(() => {
    if (
      (traits &&
        (!userState || (user && JSON.stringify(userState) !== JSON.stringify(user))) &&
        !authLoading &&
        !userLoading) ||
      forceUserInfo
    ) {
      if (forceUserInfo) {
        dispatch(setForceUserInfo(false));
      }
      if (user) {
        //set the userState with the user hooks data
        dispatch(setUserSuccess(user));
        identify(false);
        track(user.visits > 1 ? 'Sign In' : 'Sign Up', {});
       }
      }
    else if (!isLoading && !user && userState) {
      dispatch(clearState());
      logout({ logoutParams: { returnTo: '/' } });
    }
  }, [traits, user, userState, userLoading, authLoading, dispatch, authResult, forceUserInfo]);

  useEffect(() => {
    const location = window.location;
    const hash = location.hash;

    if (hash?.indexOf('#access_token=') === 0) {
      navigate('/imper?' + hash.substr(1));
      dispatch(setImpersonated(true));
      return;
    }
    if (authResult) {
      if (hasNext) {
        navigate(`/${navigateTo}`);
      }
      return;
    }
    //!authResult - at start
    if(location.pathname==="/imper")
    {
        dispatch(setImpersonated(true));
        return;
    }
  }, [authResult, navigate, dispatch, navigateTo, hasNext]);

  return null;
};

export default RoutGuard;
