import {
  UPDATE_EMAIL_VERIFICATION_ATTEMPT,
  UPDATE_EMAIL_VERIFICATION_FAILURE,
  UPDATE_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_EMAIL_VERIFICATION_CLEAR,
} from './actionTypes';

export const initialState = {
  errorCode: null,
  attempting: false,
  updateEmailVerification: null,
};

export const emailVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_VERIFICATION_ATTEMPT:
      return { ...state, attempting: true, errorCode: null, updateEmailVerification: null };
    case UPDATE_EMAIL_VERIFICATION_FAILURE:
      return { ...state, attempting: false, errorCode: action.errorCode, updateEmailVerification: null };
    case UPDATE_EMAIL_VERIFICATION_SUCCESS:
      return { ...state, attempting: false, errorCode: null, updateEmailVerification: true };
    case UPDATE_EMAIL_VERIFICATION_CLEAR:
      return { ...state, attempting: false, errorCode: null, updateEmailVerification: null };
    default:
      return state;
  }
};
