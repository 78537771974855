import React from 'react';
import { FileUploader } from 'react-drag-drop-files';

// mui utils
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

// iangels utils
import FounderWrapper from '../../components/FounderPage/FounderWrapper';
import Text from '../../components/text';
import FileUploadedIcon from '../../assets/kyc-verified.svg';

const KeyDocuments = ({ fileLoading, keyDocuments, keyDocumentsError, handleKeyDocumentsChange, matches, title }) => {
  return (
    <FounderWrapper matches={matches}>
      <Text variant="h6" classes="f-tgf-400 g4-c" text={title} />
      <Grid container>
        <Grid item xs={5}>
          <Text classes="founder-upload-section-label" text="Company Presentation/One Pager (PDF only):" />
        </Grid>
        <Grid item xs={7}>
          {keyDocuments.companyPresentation ? (
            <div className="d-f m-10">
              <img src={FileUploadedIcon} alt="approved icon" />
              <Text text={keyDocuments.companyPresentation.file.name} classes="ml-10" />
            </div>
          ) : (
            <FileUploader
              classes="drop_zone"
              handleChange={(file) => handleKeyDocumentsChange(file, 'companyPresentation')}
              name="file"
              types={['PDF']}
              hoverTitle=" "
              onTypeError={() => keyDocumentsError.companyPresentation(true)} //
            >
              {matches ? (
                <div className="upload-file-container-mobile">
                  <>
                    <FileUploadIcon className="g-c mr-10" />
                    <Text classes="f-gl-400" text="Upload" />
                  </>
                </div>
              ) : (
                <div className="upload-file-container" style={{ maxWidth: 350 }}>
                  {fileLoading.companyPresentation ? (
                    <Stack className="primary-c" sx={{ width: '100%' }} spacing={2}>
                      <LinearProgress color="inherit" style={{ height: 25 }} />
                    </Stack>
                  ) : (
                    <>
                      <FileUploadIcon className="g-c mr-10" />
                      <Text //
                        classes="f-gl-400"
                        text="Drag and drop here, or "
                        extraText="Upload file"
                        extraClasses="g-c bold pointer underline"
                      />
                    </>
                  )}
                </div>
              )}
            </FileUploader>
          )}

          {keyDocumentsError.companyPresentation ? (
            <Text
              classes="f-gl-400"
              text="This file type is not supported."
              style={{ color: '#d32f2f', fontSize: 12, marginTop: 5, marginBottom: 5 }}
            />
          ) : null}
        </Grid>
        <Grid item xs={5}>
          <Text classes="founder-upload-section-label" text="Projections (PDF only):" />
        </Grid>

        <Grid item xs={7}>
          {keyDocuments.projections ? (
            <div className="d-f m-10">
              <img src={FileUploadedIcon} alt="approved icon" />
              <Text text={keyDocuments.projections.file.name} classes="ml-10" />
            </div>
          ) : (
            <FileUploader
              classes="drop_zone"
              handleChange={(file) => handleKeyDocumentsChange(file, 'projections')}
              name="file"
              types={['PDF']}
              hoverTitle=" "
              onTypeError={() => keyDocumentsError.projections(true)}
            >
              {matches ? (
                <div className="upload-file-container-mobile">
                  <>
                    <FileUploadIcon className="g-c mr-10" />
                    <Text classes="f-gl-400" text="Upload" />
                  </>
                </div>
              ) : (
                <div className="upload-file-container mt-5" style={{ maxWidth: 350 }}>
                  {fileLoading.projections ? (
                    <Stack className="primary-c" sx={{ width: '100%' }} spacing={2}>
                      <LinearProgress color="inherit" style={{ height: 25 }} />
                    </Stack>
                  ) : (
                    <>
                      <FileUploadIcon className="g-c" />
                      <Text
                        classes="f-gl-400"
                        text="Drag and drop here, or "
                        extraText="Upload file"
                        extraClasses="g-c bold pointer underline"
                      />
                    </>
                  )}
                </div>
              )}
            </FileUploader>
          )}
          {keyDocumentsError.projections ? (
            <Text
              classes="f-gl-400"
              text="This file type is not supported."
              style={{ color: '#d32f2f', fontSize: 12, marginTop: 5, marginBottom: 5 }}
            />
          ) : null}
        </Grid>
      </Grid>
    </FounderWrapper>
  );
};

export default KeyDocuments;
