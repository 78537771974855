import {
  KYC_STATUS_ATTEMPT,
  KYC_STATUS_FAILURE,
  KYC_STATUS_SUCCESS,
  CLEAR_KYC_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const kycStatusAttempt = () => ({
  type: KYC_STATUS_ATTEMPT,
});

export const kycStatusSuccess = (kycStatus) => ({
  type: KYC_STATUS_SUCCESS,
  kycStatus,
});

export const kycStatusFailure = (errorCode) => ({
  type: KYC_STATUS_FAILURE,
  errorCode,
});

export const clearKycState = () => ({
  type: CLEAR_KYC_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
