import {
  KYC_STATUS_ATTEMPT,
  KYC_STATUS_FAILURE,
  KYC_STATUS_SUCCESS,
  CLEAR_STATE,
  CLEAR_KYC_STATE,
} from './actionTypes';

export const initialState = {
  kycStatus: null,
  attempting: false,
  errorCode: null,
};

export const KycStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case KYC_STATUS_ATTEMPT:
      return {
        ...state,
        kycStatus: null,
        attempting: true,
        errorCode: null,
      };
    case KYC_STATUS_FAILURE:
      return {
        ...state,
        kycStatus: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case KYC_STATUS_SUCCESS:
      return {
        ...state,
        kycStatus: action.kycStatus,
        errorCode: null,
        attempting: false,
      };
    case CLEAR_KYC_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
