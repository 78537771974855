import { createSelector } from 'reselect';

const getStartupsAttempting = (state) => state.startups.startupsAttempting;
const getSectorsAttempting = (state) => state.startups.sectorsAttempting;

const getStartups = (state) => state.startups.startups;
const getSectors = (state) => state.startups.sectors;

export const getStartupsAttemptingSelector = createSelector(
  getStartupsAttempting,
  (startupsAttempting) => startupsAttempting
);
export const getSectorsAttemptingSelector = createSelector(
  getSectorsAttempting,
  (sectorsAttempting) => sectorsAttempting
);

export const getStartupsSelector = createSelector(getStartups, (startups) => startups);
export const getSectorsSelector = createSelector(getSectors, (sectors) => sectors);
