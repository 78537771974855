import { realizedTypes } from '../helpers/constants';
import _ from 'lodash';

export const getUnrealizedTotalInvested = (investments, isSubRows, totalCommitted, fees = null) => {
  let totalInvested = 0;
  let lastValue = 0;
  let publicHoldings = 0;
  let allSubRowsNotApproved = false;
  let isContainApproved = false;

  investments?.forEach?.((investment) => {
    if (investment.notApproved && isSubRows && !isContainApproved) {
      allSubRowsNotApproved = true;
    } else if (!investment.notApproved) {
      isContainApproved = true;
      if (allSubRowsNotApproved) {
        totalInvested = 0;
        lastValue = 0;
        publicHoldings = 0;
        allSubRowsNotApproved = false;
      }
    }

    if (!investment.notApproved || (isSubRows && allSubRowsNotApproved)) {
      totalInvested += investment.amount || 0;
      lastValue += investment.position || 0;
      publicHoldings += investment.publicHoldings || 0;
    }
  });

  if (fees) totalInvested += fees;
  return {
    totalInvested: Math.round(totalInvested),
    lastValue: Math.round(lastValue),
    change: totalInvested !== 0 ? Math.round((lastValue / totalInvested - 1) * 100) : 0,
    publicHoldings: Math.round(publicHoldings),
    isAllSubRowsNotApproved: allSubRowsNotApproved,
    totalCommitted: totalCommitted,
  };
};

export const getRealizedTotalInvested = (investments) => {
  let totalInvested = 0;
  let lastValue = 0;

  investments?.forEach?.((investment) => {
    if (!_.includes(['dividend', 'interest', 'tax_expense'], investment.eventType)) {
      totalInvested += investment.totalInvested || 0;
    }
    lastValue += investment.totalReceived || 0;
  });

  return {
    totalInvested: Math.round(totalInvested),
    lastValue: Math.round(lastValue),
    change: totalInvested !== 0 ? Math.round((totalInvested === 0 ? 0 : lastValue / totalInvested - 1) * 100) : 0,
  };
};

export const getRealizedSubRowsTotals = (investments, eventType) => {
  let totalInvested = 0;
  let lastValue = 0;
  let totalReceivedRealized = 0;
  let investmentIds = [];

  investments?.forEach?.((investment) => {
    if (!_.includes(['dividend', 'interest'], investment.eventType)) {
      if (investment.amount && !investment.notApproved) {
        totalInvested += investment.amount;
      } else if (investment.amountInvested) {
        //in case of Acquisition with some Distributions amountInvested will be the same as both rows but each time the amountDistribution will be diffrenet so no needed to sum amountInvested again
        if (investment.investmentId && !investmentIds.includes(investment.investmentId)) {
          investmentIds.push(investment.investmentId);
          totalInvested += investment.amountInvested;
        }
      }
      // in case of secondary
      else if (investment.amountSold) {
        totalInvested += investment.amountSold;
      }
      if (investment.amountDistribution) {
        lastValue += investment.amountDistribution;
      }
      if (investment.status && investment.status === realizedTypes.realized) {
        totalReceivedRealized += investment.amountDistribution;
      }
    }
  });

  if (eventType === realizedTypes.secondary) {
    totalReceivedRealized = lastValue;
  }

  return {
    totalInvested: Math.round(totalInvested),
    lastValue: Math.round(lastValue),
    change:
      totalInvested !== 0
        ? eventType === realizedTypes.dividend || eventType === realizedTypes.interest
          ? lastValue / totalInvested
          : (totalReceivedRealized - totalInvested) / totalInvested
        : 0,
  };
};

export const createFilteredList = (listToFilter, filters, destinationList, isRealized, hasPublicHoldings) => {
  listToFilter?.forEach((investment) => {
    let subRows = [];

    filters.forEach((element) => {
      if (investment.subRows?.length > 0) {
        subRows = subRows.concat(_.filter(investment.subRows, { entity: element.name }));
      } else if (investment.entity === element.name) {
        destinationList.push(investment);
      }
    });

    if (subRows.length > 0) {
      let currInvestment = Object.assign({}, investment);
      let subRowsTotalInvested = null;

      if (isRealized) {
        assignRealizedFields(subRowsTotalInvested, subRows, currInvestment);
      } else {
        assignUnrealizedFields(subRowsTotalInvested, subRows, currInvestment, hasPublicHoldings);
      }
      if (subRows.length > 1) {
        currInvestment.subRows = subRows;
      } else if (subRows.length === 1) {
        if (!isRealized) {
          currInvestment = subRows[0];
        }
        currInvestment.subRows = [];
        if (investment.junoEstimatedValueLastUpdated) {
          currInvestment.junoEstimatedValueLastUpdated = investment.junoEstimatedValueLastUpdated;
        }
      }
      destinationList.push(currInvestment);
    }
  });
};

const assignRealizedFields = (subRowsTotalInvested, subRows, currInvestment) => {
  subRowsTotalInvested = getRealizedSubRowsTotals(subRows, currInvestment.eventType);
  currInvestment.totalInvested = subRowsTotalInvested.totalInvested;
  currInvestment.totalReceived = subRowsTotalInvested.lastValue;
  currInvestment.returnLoss = subRowsTotalInvested.change;
};

const assignUnrealizedFields = (subRowsTotalInvested, subRows, currInvestment, hasPublicHoldings) => {
  subRowsTotalInvested = getUnrealizedTotalInvested(subRows, true);
  currInvestment.amount = subRowsTotalInvested.totalInvested;
  currInvestment.position = subRowsTotalInvested.lastValue;
  currInvestment.appreciation = subRowsTotalInvested.change;
  if (hasPublicHoldings) {
    currInvestment.publicHoldings = subRowsTotalInvested.publicHoldings;
  }
  if (subRowsTotalInvested.isAllSubRowsNotApproved) currInvestment.notApproved = true;
};
