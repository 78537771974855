import React from 'react';

// mui components
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

//logo svg
import Logo from '../../assets/white-logo.svg';
import Text from '../text';
import IsMobileDevice from '../Generic/IsMobileDevice';

// iangels utils
import { loadConfig } from '../../env/config_util';

const Item = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        fontSize: '0.875rem',
        fontWeight: '700',
        display: 'flex',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    />
  );
};

const ItemContent = (props) => {
  const { title, items } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: 120 }}>
      <Text variant="body2" text={title} classes="f-tgf-400  f-size-12" />
      <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
        {items.map((item, index) => {
          return (
            <Link
              href={item.href ? item.href : null}
              variant="body2"
              key={index}
              style={{ marginTop: 10, textDecoration: 'none', color: '#fff', letterSpacing: 0.8 }}
              className="link f-gl-400"
              target={item.target ? '_blank' : ''}
              rel={item.target ? 'noreferrer' : ''}
              onClick={item.on_click ? item.on_click : null}
            >
              {item.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const Footer = () => {
  const isMobile = IsMobileDevice();
  const config = loadConfig();
  const marketing_website_base_url = config.config_module.marketing_website_base_url;

  const howItWorks = [
    { name: 'Investors', href: marketing_website_base_url + 'investors/', target: '_blank' },
    { name: 'Entrepreneurs', href: marketing_website_base_url + 'entrepreneurs/', target: '_blank' },
    {
      name: 'Privacy Policy',
      href:
        marketing_website_base_url +
        'privacy-policy/?_gl=1*tw71nj*_ga*NTc1NTY2NjUzLjE2ODYwNTQ3NTU.*_ga_BP06PL7MEG*MTY4NjA1NjU4NC4xLjEuMTY4NjA1ODQ5MS4wLjAuMA..',
      target: '_blank',
    },
    {
      name: 'Terms of Service',
      href:
        marketing_website_base_url +
        'terms-of-service/?_gl=1*rh1xj6*_ga*NTc1NTY2NjUzLjE2ODYwNTQ3NTU.*_ga_BP06PL7MEG*MTY4NjA1NjU4NC4xLjEuMTY4NjA1ODU5Ni4wLjAuMA..',
      target: '_blank',
    },
  ];

  const aboutUs = [
    { name: 'Team', href: marketing_website_base_url + 'about-us/', target: '_blank' },
    { name: 'Portfolio', href: '/startups' },
    { name: 'Blog', href: marketing_website_base_url + 'blog/', target: '_blank' },
    { name: 'Contact Us', on_click: () => window.Intercom('show') },
  ];

  const getHowItWorks = () => {
    return (
      <Grid item xs>
        <Item>
          <ItemContent title="HOW IT WORKS" items={howItWorks}></ItemContent>
        </Item>
      </Grid>
    );
  };

  const getAboutUs = () => {
    return (
      <Grid item xs>
        <Item>
          <ItemContent title="ABOUT US" items={aboutUs}></ItemContent>
        </Item>
      </Grid>
    );
  };

  const getContactUs = () => {
    return (
      <Grid item xs>
        <Item>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="body2" text="CONTACT US" classes="f-tgf-400 f-size-12" />

            <div
              style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Tooltip
                title={
                  <Text
                    variant="subtitle2"
                    text="Check the chat on the bottom right corner of your screen"
                    classes="f-gl-400"
                  />
                }
                arrow
              >
                <Typography variant="subtitle2" className="f-gl-400" style={{ letterSpacing: 0.8 }}>
                  {'+972-3-901-3760'}
                </Typography>
              </Tooltip>
              <Text
                variant="subtitle2"
                text="iAngels Crowd Ltd. Rothschild Blvd 18 Tel Aviv, 6688121"
                classes="f-gl-400"
                style={{ marginTop: 10, lineHeight: 1.65, maxWidth: 120, letterSpacing: 0.8 }}
              />
            </div>
          </div>
        </Item>
      </Grid>
    );
  };

  const getSocialNetwork = () => {
    return (
      <Grid item xs>
        <Item>
          <Stack justifyContent="center" direction="row" style={{ minWidth: 120 }}>
            <a
              href="https://twitter.com/iangelscapital?s=11&t=pFTvJwDTo-pJ2FKGvs-A-w"
              style={{ color: 'white' }}
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon style={{ marginRight: 10 }} />
            </a>
            <a href="https://www.facebook.com/iangels.co" style={{ color: 'white' }} target="_blank" rel="noreferrer">
              <FacebookIcon style={{ marginRight: 10 }} />
            </a>
            <a
              href="http://www.linkedin.com/company/3054142?trk=prof-exp-company-name"
              style={{ color: 'white' }}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon style={{ marginRight: 10 }} />
            </a>
            <a
              href="https://www.instagram.com/iangelscapital"
              style={{ color: 'white' }}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon style={{ marginRight: 10 }} />
            </a>
          </Stack>
        </Item>
      </Grid>
    );
  };

  return (
    <>
      <Stack justifyContent="center" alignItems="center" style={{ marginTop: 65, paddingTop: 30 }}>
        {!isMobile ? (
          <Grid container spacing={0} direction="row" style={{ maxWidth: 1200 }}>
            <Grid item xs>
              <Item>
                <img src={Logo} alt="logo" />
              </Item>
              {getSocialNetwork()}
            </Grid>
            {getHowItWorks()}
            {getAboutUs()}
            {getContactUs()}
          </Grid>
        ) : (
          <Grid container spacing={3} direction="column" style={{ textAlign: 'center' }}>
            <Grid item xs>
              <Item>
                <img src={Logo} alt="logo" />
              </Item>
            </Grid>
            {getContactUs()}
            {getSocialNetwork()}
            {getHowItWorks()}
            {getAboutUs()}
          </Grid>
        )}
      </Stack>
    </>
  );
};

export default Footer;
