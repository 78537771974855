import {
  INVESTMENT_ATTEMPT,
  INVESTMENT_FAILURE,
  INVESTMENT_SUCCESS,
  CLEAR_INVESTMENT_STATE,
  GET_INVESTMENT_ENTITIES_ATTEMPT,
  GET_INVESTMENT_ENTITIES_FAILURE,
  GET_INVESTMENT_ENTITIES_SUCCESS,
  GET_SIGNING_EMBEDDED_URL_ATTEMPT,
  GET_SIGNING_EMBEDDED_URL_FAILURE,
  GET_SIGNING_EMBEDDED_URL_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const getSigningEmbeddedUrlAttempt = (data) => ({
  type: GET_SIGNING_EMBEDDED_URL_ATTEMPT,
  data,
});

export const getSigningEmbeddedUrlSuccess = (urlSuccess) => ({
  type: GET_SIGNING_EMBEDDED_URL_SUCCESS,
  urlSuccess,
});

export const getSigningEmbeddedUrlFailure = (errorCode) => ({
  type: GET_SIGNING_EMBEDDED_URL_FAILURE,
  errorCode,
});

export const getInvestmentEntitiesAttempt = () => ({
  type: GET_INVESTMENT_ENTITIES_ATTEMPT,
});

export const getInvestmentEntitiesSuccess = (entities) => ({
  type: GET_INVESTMENT_ENTITIES_SUCCESS,
  entities,
});

export const getInvestmentEntitiesFailure = (errorCode) => ({
  type: GET_INVESTMENT_ENTITIES_FAILURE,
  errorCode,
});

export const investmentAttempt = (data) => ({
  type: INVESTMENT_ATTEMPT,
  data,
});

export const investmentSuccess = (investmentSuccess) => ({
  type: INVESTMENT_SUCCESS,
  investmentSuccess,
});

export const investmentFailure = (errorCode) => ({
  type: INVESTMENT_FAILURE,
  errorCode,
});

export const clearInvestmentState = () => ({
  type: CLEAR_INVESTMENT_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
