import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { loadConfig } from '../env/config_util';

const AuthProvider = (props) => {
  const config = loadConfig();

  return (
    <Auth0Provider
      domain={config.config_module.domain}
      clientId={config.config_module.client_id}
      redirectUri= {config.config_module.redirectUrl}
      scope="openid user_id email level read:roles avatar created_at user_metadata app_metadata"
    >
      {props.children}
    </Auth0Provider>
  );
};

export default AuthProvider;
