import React from 'react';

// iAngels utils
import InvestmentOpportunitiesPage from './InvestmentOpportunitiesPage';
import { getDashboardSelector } from '../Dashboard/selectors';

// third party utils
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const InvestmentOpportunities = () => {
  const dashboard = useSelector(getDashboardSelector);
  const navigate = useNavigate();

  if (!dashboard) {
    navigate('/dashboard');
    return;
  }

  return (
    <>
      <InvestmentOpportunitiesPage />
    </>
  );
};

export default InvestmentOpportunities;
