import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import history from '../history';
import storage from 'redux-persist/lib/storage';

import { createStore, applyMiddleware } from 'redux';
import { rootSaga } from './rootSaga';
import { routerMiddleware } from 'react-router-redux';
import { persistReducer } from 'redux-persist';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export const config = {
  key: 'root',
  storage: storage,
  blacklist: ['resetPassword', 'verifyEmail'],
};

const persisted = persistReducer(config, rootReducer);

const store =
  process.env.REACT_APP_BUILD_ENV === 'dev' || process.env.REACT_APP_BUILD_ENV === 'local'
    ? createStore(persisted, applyMiddleware(sagaMiddleware, routerMiddleware(history), logger))
    : createStore(persisted, applyMiddleware(sagaMiddleware, routerMiddleware(history)));
sagaMiddleware.run(rootSaga);

export default store;
