import React from 'react';

import MemoTeamMember from './MemoTeamMember';
import MemoWrapper from './Memo/MemoWrapper';

const members = (investors, isMobile) => {
  return (
    <div className="d-f fdr-c">
      {investors.map((member) => (
        <MemoTeamMember
          key={member.fullName}
          image={member.avatar}
          name={member.fullName}
          info={member.bio}
          role={member.role}
          tags={member.tags}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
};

const MemoTeam = ({ id, title, isMobile, investors }) => {
  return isMobile ? (
    members(investors, isMobile)
  ) : (
    <MemoWrapper id={id} title={title}>
      {members(investors, isMobile)}
    </MemoWrapper>
  );
};

export default MemoTeam;
