import { call, all, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService } from '../../services/apiGateway';
import { kycStatusFailure, kycStatusSuccess } from './actions';

import { KYC_STATUS_ATTEMPT } from './actionTypes';

function* getKycStatus() {
  try {
    const kycStatus = yield call(getDataFromService, '/iangels/investments/user-invested-entities');
    yield put(kycStatusSuccess(kycStatus?.data));
  } catch (errorCode) {
    yield put(kycStatusFailure(errorCode.response || errorCode));
  }
}

function* kycStatusSaga() {
  yield all([takeLatest(KYC_STATUS_ATTEMPT, getKycStatus)]);
}

export default kycStatusSaga;
