import React from 'react';

// mui utils
import Chip from '@mui/material/Chip';
// third party utils
import { useTranslation } from 'react-i18next';
import { getPricingTerms } from '../lib/pricingTerms';

const PricingTerms = ({ investment, isSubRow, isMobile }) => {
  const { t } = useTranslation();
  const pricingTerms = getPricingTerms(investment, t, isSubRow);
  return pricingTerms.pricingTermsEmptyOrSeveral ? (
    pricingTerms.pricingTermsEmptyOrSeveral
  ) : (
    <div className="nowrap">
      {pricingTerms.amount ? (
        <div>
          {pricingTerms.amount}
          {pricingTerms.tokenTerms ? <div>{pricingTerms.tokenTerms}</div> : null}
        </div>
      ) : (
        <div>
          {pricingTerms.amountCap ? pricingTerms.amountCap : null}
          {pricingTerms.amountDiscount ? <div>{pricingTerms.amountDiscount}</div> : null}
        </div>
      )}

      {pricingTerms.roundName ? (
        <div className="toPdf">
          <br className="hidden" />
          <Chip
            label={pricingTerms.roundName}
            size="small"
            sx={{ fontSize: '10px', minWidth: '41px', border: 'none' }}
            variant={isMobile ? 'outlined' : 'filled'}
            className={isMobile ? 'gray-700' : ''}
          />
          <br className="hidden" />
        </div>
      ) : null}
      <div className={`f-smaller ${isMobile ? 'gray-600' : ''}`}>{pricingTerms.roundType}</div>
    </div>
  );
};

export default PricingTerms;
