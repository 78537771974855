import {
  GET_INVESTMENT_INVESTMENT_DATA_ATTEMPT,
  GET_INVESTMENT_INVESTMENT_DATA_FAILURE,
  GET_INVESTMENT_INVESTMENT_DATA_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  investmentData: null,
  attempting: false,
  errorCode: null,
};

export const InvestmentDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVESTMENT_INVESTMENT_DATA_ATTEMPT:
      return {
        ...state,
        investmentData: null,
        attempting: true,
        errorCode: null,
      };
    case GET_INVESTMENT_INVESTMENT_DATA_FAILURE:
      return {
        ...state,
        investmentData: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_INVESTMENT_INVESTMENT_DATA_SUCCESS:
      return {
        ...state,
        investmentData: action.investmentData,
        errorCode: null,
        attempting: false,
      };

    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
