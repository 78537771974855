import {
  GET_INVESTMENTS_ATTEMPT,
  GET_INVESTMENTS_SUCCESS,
  GET_INVESTMENTS_FAILURE,
  GET_DASHBOARD_ATTEMPT,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_SUCCESS,
  CLEAR_STATE,
  SECONDARY_SALE_ATTEMPT,
  SECONDARY_SALE_SUCCESS,
  SECONDARY_SALE_FAILURE,
  EXPORT_PDF,
} from './actionTypes';

export const getInvestmentsAttempt = (token) => ({
  type: GET_INVESTMENTS_ATTEMPT,
  token,
});

export const getInvestmentsSuccess = (investments) => ({
  type: GET_INVESTMENTS_SUCCESS,
  investments,
});

export const getInvestmentsFailure = (errorCode) => ({
  type: GET_INVESTMENTS_FAILURE,
  errorCode,
});

export const getDashboardAttempt = () => ({
  type: GET_DASHBOARD_ATTEMPT,
});

export const getDashboardSuccess = (dashboard) => ({
  type: GET_DASHBOARD_SUCCESS,
  dashboard,
});

export const getDashboardFailure = (errorCode) => ({
  type: GET_DASHBOARD_FAILURE,
  errorCode,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});

export const secondarySaleRequestedAttempt = (investmentId) => ({
  type: SECONDARY_SALE_ATTEMPT,
  investmentId,
});
export const secondarySaleRequestedSuccess = (investmentId, result) => ({
  type: SECONDARY_SALE_SUCCESS,
  investmentId,
  result,
});

export const secondarySaleRequestedFailure = (investmentId, errorCode) => ({
  type: SECONDARY_SALE_FAILURE,
  investmentId,
  errorCode,
});

export const setExportToPdf = (exportPdf) => ({
  type: EXPORT_PDF,
  exportPdf,
});
