import React, { useState, useCallback, useEffect } from 'react';

// mui utils
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';
import Text from '../../components/text';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// iangels utils
import { clearAccreditationState, accreditationAttempt } from './actions';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import { identifyUserAttempt, clearUserState } from '../App/actions';
import { ACCREDITATION_PAGES } from '../../helpers/constants';
import { track, identify } from '../../services/analytics';
import { getUserSelector } from '../App/selectors';

import {
  getAccreditationAttemptingSelector,
  getAccreditationErrorSelector,
  getAccreditationSuccessSelector,
} from './selectors';

const AccreditationForm = () => {
  const [accredited, setAccredited] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector(getUserSelector);

  const loading = useSelector(getAccreditationAttemptingSelector);
  const error = useSelector(getAccreditationErrorSelector);
  const success = useSelector(getAccreditationSuccessSelector);

  const countryCode = user?.user_metadata?.country_code;
  const countryName = user?.user_metadata?.country;

  useEffect(() => {
    if (error) {
      dispatch(clearAccreditationState());
      navigate('/error');
    }

    if (success) {
      track('Update User Accreditation', { is_accredited: true });
      identify(true);
      dispatch(clearAccreditationState());
      dispatch(clearUserState());
      dispatch(identifyUserAttempt());
      navigate('/dashboard');
    }
  }, [error, success, navigate, dispatch]);

  const handleRadioChange = (event) => {
    setAccredited(event.target.value === 'true');
  };

  const handleSubmit = () => {
    if (!accredited) {
      return;
    }
    dispatch(accreditationAttempt({ accredited: accredited }));
  };

  const getAccreditationText = useCallback(() => {
    if (!countryCode) {
      return <span>I am an accredited Investor under the securities laws and regulations of my country</span>;
    }
    if (ACCREDITATION_PAGES.indexOf(countryCode.toUpperCase()) !== -1) {
      return (
        <span className="justify">
          I meet the
          <span
            className="g-c pointer"
            onClick={() => window.open(`/accreditation/${countryCode.toUpperCase()}`, '_blank')}
          >
            {' '}
            accreditation requirements{' '}
          </span>
          in {countryName}
        </span>
      );
    }
    return (
      <span className="justify">
        I am an accredited Investor under the securities laws and regulations of {countryName}
      </span>
    );
  }, [countryName, countryCode]);

  return (
    <Stack style={{ marginTop: 25, minHeight: 700, maxWidth: 950 }} spacing={2} direction="column" alignItems="start">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Text variant="h5" classes="f-tgf-400 g3-c" text="Investors Accreditation" />
          <Text
            classes="f-f-gl f-w-400 f-size-16 justify g4-c"
            text="In order to invest with iAngels, it's required that all investors are accredited according to the rules and regulations of the country in which they reside. The information provided will remain private."
          />
          <Text
            classes="f-f-gl f-w-400 f-size-16 justify g4-c"
            text="For more information on these requirements please contact"
            extraText="  our team."
            handleClick={() => (window.location = 'mailto:myteam@iangels.com')}
            extraClasses="g-c bold pointer"
          />
          <div>
            <Text
              classes="f-f-tgf f-w-500 f-size-16 g4-c"
              text="You will be asked to share accreditation credentials."
            />
            <Text classes="f-f-tgf f-w-500 f-size-16 g4-c" text="Please accredit yourself" />
          </div>
          <RadioGroup
            aria-labelledby="investing-radio-buttons-group-label"
            defaultValue={false}
            name="radio-buttons-group"
            onChange={handleRadioChange}
          >
            <FormControlLabel
              style={{ marginTop: 10 }}
              value={true}
              control={<Radio />}
              label={getAccreditationText()}
              className="f-f-gl f-w-400 f-size-16 justify g4-c"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="I am not accredited investor"
              className="f-f-gl f-w-400 f-size-16 justify g4-c"
            />
          </RadioGroup>
          <Button
            variant="contained"
            className={!accredited ? 'btn-disabled' : 'btn'}
            style={{ minWidth: 150, minHeight: 60, borderRadius: 0, marginTop: 40, marginBottom: 40 }}
            onClick={handleSubmit}
            disabled={!accredited}
          >
            <span className="f-size-16">Submit</span>
          </Button>
        </>
      )}
    </Stack>
  );
};

export default AccreditationForm;
