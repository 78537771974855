export const INVESTMENT_ATTEMPT = 'INVESTMENT_ATTEMPT';
export const INVESTMENT_FAILURE = 'INVESTMENT_FAILURE';
export const INVESTMENT_SUCCESS = 'INVESTMENT_SUCCESS';

export const GET_INVESTMENT_ENTITIES_ATTEMPT = 'GET_INVESTMENT_ENTITIES_ATTEMPT';
export const GET_INVESTMENT_ENTITIES_FAILURE = 'GET_INVESTMENT_ENTITIES_FAILURE';
export const GET_INVESTMENT_ENTITIES_SUCCESS = 'GET_INVESTMENT_ENTITIES_SUCCESS';

export const GET_SIGNING_EMBEDDED_URL_ATTEMPT = 'GET_SIGNING_EMBEDDED_URL_ATTEMPT';
export const GET_SIGNING_EMBEDDED_URL_FAILURE = 'GET_SIGNING_EMBEDDED_URL_FAILURE';
export const GET_SIGNING_EMBEDDED_URL_SUCCESS = 'GET_SIGNING_EMBEDDED_URL_SUCCESS';

export const CLEAR_INVESTMENT_STATE = 'CLEAR_INVESTMENT_STATE';
export const CLEAR_STATE = 'CLEAR_STATE';
