import React from 'react';

// mui utils
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';

// iAngels utils
import Text from '../text';

const ErrorAlert = ({ hasError, setHasError, text, title, style, extraText, handleClick, extraClasses }) => {
  return (
    <Collapse in={hasError} style={style}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setHasError(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        variant="filled"
        severity="error"
        className="alert"
      >
        <AlertTitle>{title}</AlertTitle>
        <Text text={text} classes="w-c" extraText={extraText} handleClick={handleClick} extraClasses={extraClasses} />
      </Alert>
    </Collapse>
  );
};

export default ErrorAlert;
