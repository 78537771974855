import React from 'react';

import DownIcon from '../../assets/down-arrow.svg';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';

import { styled, alpha } from '@mui/material/styles';

import { track } from '../../services/analytics';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const ToolbarItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!props.items) track('Top Nav', { category: 'Navigation', item: props.title });
    props.onClick ? props.onClick() : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuItems = () => {
    return props.items.map((item, index, row) => {
      return item.shown ? (
        <div key={index}>
          <MenuItem
            onClick={() => {
              item.func();
              setAnchorEl(null);
              track(item.event, { category: 'Navigation', item: item.title });
            }}
            disableRipple
          >
            {item.icon}
            {item.title}
          </MenuItem>
          {row?.length === index + 1 ? null : <Divider />}
        </div>
      ) : null;
    });
  };

  return (
    <div style={{ marginLeft: 15, display: 'flex' }}>
      {props.avatar ? <Avatar alt={props.avatar.name} src={props.avatar.src} /> : null}
      <Button
        className="drop-down-btn f-f-gl f-w-700 f-size-18 g4-c nowrap"
        id="customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={props.items ? <img src={DownIcon} alt="down-arrow" /> : null}
      >
        <span>{props.title}</span>
      </Button>
      {props.items ? (
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {getMenuItems()}
        </StyledMenu>
      ) : null}
    </div>
  );
};

export default ToolbarItem;
