import React from 'react';

import RoutGuard from '../../../auth/RouteGuardUniversal';
import { pageView } from '../../../services/analytics';
import { Helmet } from 'react-helmet';

const EmptyLayout = (props) => {
  pageView(props.track, window.location.href, { routerPath: props.navigateTo });
  return (
    <div>
      <div className={props.whiteBg ? 'empty-layout-white' : 'empty-layout'}>
        <Helmet>{props.title ? <title>{`${props.title} - iAngels`}</title> : <title>{'iAngels'}</title>}</Helmet>
        <RoutGuard lockGuard={props.lockGuard} navigateTo={props.navigateTo} hasNext={props.hasNext} />
        <main role="main">{React.Children.toArray(props.children)}</main>
      </div>
    </div>
  );
};
export default EmptyLayout;
