import React from 'react';

// iangels utils
import DesktopAccordion from '../../../components/Accordion/DesktopAccordion';
import MobileAccordion from '../../../components/Accordion/MobileAccordion';
import ActiveIcon from '../../../assets/accordion/top_total.png';

const TotalAccordion = ({ totalInvestments, mobile }) => {
  return (
    <>
      {mobile ? (
        <MobileAccordion
          title="Total Unrealized"
          changeTitle="Change"
          valueTitle="Total Value"
          investmentSummary={totalInvestments}
          iconDisabled={true}
          accordionClass="accordion mb-bg g4-c"
          textClass="w-c"
        />
      ) : (
        <DesktopAccordion
          icon={ActiveIcon}
          title="Total Unrealized"
          totalTitle={'Total Invested'}
          changeTitle="Change"
          valueTitle="Total Value"
          investmentSummary={totalInvestments}
          iconDisabled={true}
          accordionExpanded={false}
          accordionClass="accordion w-c mb-bg"
        />
      )}
    </>
  );
};

export default TotalAccordion;
