import React from 'react';

// iangels utils
import TwoFieldStack from '../../components/FounderPage/TwoFieldStack';
import Field from '../../components/FounderPage/Field';
import FounderWrapper from '../../components/FounderPage/FounderWrapper';
import Text from '../../components/text';

import { fundingStages, securityTypes } from '../../helpers/constants';

const Round = ({ round, roundError, handleRoundChange, matches, title }) => {
  const fundingStageField = {
    type: 'select',
    required: true,
    error: roundError.fundingStage,
    id: 'fundingStage',
    label: 'Funding stage *',
    labelId: 'fundingStageLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    style: { maxWidth: '100%', marginRight: matches ? 0 : 20, marginTop: 10 },
    value: round.fundingStage,
    itemsList: fundingStages,
    name: 'fundingStage',
    onChange: handleRoundChange,
    helperText: roundError.fundingStage,
  };

  const amountRaisingField = {
    type: 'text-number',
    required: true,
    error: roundError.amountRaising,
    id: 'amountRaising',
    label: 'Amount raising (US$)',
    style: { maxWidth: '100%', marginRight: matches ? 0 : 20, marginTop: 10 },
    value: round.amountRaising,
    name: 'amountRaising',
    onChange: handleRoundChange,
    helperText: roundError.amountRaising,
  };

  const securityTypeField = {
    type: 'select',
    required: false,
    id: 'dealType',
    label: 'Security type',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    labelId: 'dealTypeLabel',
    style: { width: 'inherit', maxWidth: '100%', marginRight: matches ? 0 : 20, marginTop: 10 },
    value: round.dealType,
    itemsList: securityTypes,
    name: 'dealType',
    onChange: handleRoundChange,
  };

  const valuationField = {
    type: 'text-number',
    required: false,
    id: 'valuation',
    label: 'Valuation (pre) US$',
    style: { maxWidth: '100%', marginRight: matches ? 0 : 20, marginTop: 10 },
    value: round.valuation,
    name: 'valuation',
    onChange: handleRoundChange,
  };

  const estimatedClosingDateField = {
    type: 'date',
    required: false,
    error: roundError.estimatedClosingDate,
    id: 'estimatedClosingDate',
    label: 'Est. Closing date',
    value: round.estimatedClosingDate,
    name: 'estimatedClosingDate',
    onChange: handleRoundChange,
    helperText: roundError.estimatedClosingDate,
  };

  const leaderField = {
    type: 'text',
    required: false,
    id: 'leader',
    label: 'Who is leading the round?',
    style: { maxWidth: '100%', marginRight: matches ? 0 : 20, marginTop: 10 },
    value: round.leader,
    name: 'leader',
    onChange: handleRoundChange,
  };

  const founderOwnershipField = {
    type: 'text-number',
    required: false,
    id: 'founderOwnership',
    label: 'Current percent ownership by founders',
    style: { maxWidth: '100%', marginRight: matches ? 0 : 20, marginTop: 10 },
    value: round.founderOwnership,
    name: 'founderOwnership',
    onChange: handleRoundChange,
  };

  return (
    <FounderWrapper matches={matches}>
      <Text variant="h6" classes="f-tgf-400 g4-c" text={title} />
      <div className={`nowrap d-f j-c-sb ${matches ? 'fdr-c' : 'fdr-r'}`}>
        <Field obj={fundingStageField} matches={matches} style={{ marginRight: matches ? 0 : 20, marginTop: 10 }} />

        <Field
          obj={amountRaisingField}
          matches={matches}
          style={{ width: '100%', marginTop: 10, marginRight: matches ? 0 : 20 }}
        />

        <Field obj={securityTypeField} matches={matches} style={{ marginTop: 10 }} />
      </div>
      <TwoFieldStack matches={matches} fieldAObject={valuationField} fieldBObject={estimatedClosingDateField} />
      <TwoFieldStack matches={matches} fieldAObject={leaderField} fieldBObject={founderOwnershipField} />
    </FounderWrapper>
  );
};

export default Round;
