import React, { useState, useEffect } from 'react';

import TagManager from 'react-gtm-module';
import { getAudio } from '../../helpers/cloudinary';
import ToggleButton from '@mui/material/ToggleButton';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import { track, isValideReport } from '../../services/analytics.js';

const MemoAudio = ({ startupPage, limitedView }) => {
  const hasAudio =
    !limitedView && startupPage.isUserAccredited && startupPage.openRound && startupPage.openRound.memoRecord
      ? true
      : false;
  const [audio] = useState(hasAudio ? new Audio(getAudio(startupPage.openRound.memoRecord)) : null);
  const [play, setPlay] = useState();
  const [pressCounter, setPressCounter] = useState(-1);
  const [startAudioTimer, setStartAudioTimer] = useState();
  const [lastDuration, setLastDuration] = useState();

  useEffect(() => {
    if (audio) {
      try {
        if (play) setStartAudioTimer(new Date());
        play ? audio.play() : audio.pause();
      } catch (e) { }
    }
  }, [play, audio]);

  useEffect(() => {
    return () => {
      if (audio) audio.pause();
    };
  }, [audio]);

  const handleAudio = () => {
    if (isValideReport) {
      setPressCounter(pressCounter + 1);
      let data = {
        startup: startupPage.name,
        press_button_counter: pressCounter,
      };
      if (play) {
        data = { ...data, duration: Math.floor((new Date() - startAudioTimer + lastDuration) / 1000) };
        setLastDuration(lastDuration + new Date() - startAudioTimer);
      }
      track(play ? 'Pause Memo Audio' : 'Pause Memo Audio', data);
      TagManager.dataLayer({ dataLayer: { event: play ? 'pause_memo_audio' : 'play_memo_audio', data: data } });
    }
    setPlay(!play);
  };

  return hasAudio ? (
    <ToggleButton
      onClick={() => handleAudio()}
      className="g4-bg fullwidth mb-10 w-c f-w-700 f-size-16 f-f-g capitalize pointer"
    >
      {play ? (
        <>
          <VolumeOffIcon /> Pause
        </>
      ) : (
        <>
          <VolumeUpIcon /> Play
        </>
      )}
      {'  '}
      Audio
    </ToggleButton>
  ) : null;
};

export default MemoAudio;
