import {
  SUBMIT_FORM_ATTEMPT,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_FAILURE,
  GET_SECTORS_ATTEMPT,
  GET_SECTORS_SUCCESS,
  GET_SECTORS_FAILURE,
  GET_PRIMARY_SECTORS_ATTEMPT,
  GET_PRIMARY_SECTORS_SUCCESS,
  GET_PRIMARY_SECTORS_FAILURE,
  CLEAR_STATE,
} from './actionTypes';

export const submitFormAttempt = (data) => ({
  type: SUBMIT_FORM_ATTEMPT,
  data,
});

export const submitFormSuccess = (submitFormRes) => ({
  type: SUBMIT_FORM_SUCCESS,
  submitFormRes,
});

export const submitFormFailure = (errorCode) => ({
  type: SUBMIT_FORM_FAILURE,
  errorCode,
});

export const getSectorsAttempt = (data) => ({
  type: GET_SECTORS_ATTEMPT,
  data,
});

export const getSectorsSuccess = (sectors) => ({
  type: GET_SECTORS_SUCCESS,
  sectors,
});

export const getSectorsFailure = (errorCode) => ({
  type: GET_SECTORS_FAILURE,
  errorCode,
});

export const getPrimarySectorsAttempt = (data) => ({
  type: GET_PRIMARY_SECTORS_ATTEMPT,
  data,
});

export const getPrimarySectorsSuccess = (primarySectors) => ({
  type: GET_PRIMARY_SECTORS_SUCCESS,
  primarySectors,
});

export const getPrimarySectorsFailure = (errorCode) => ({
  type: GET_PRIMARY_SECTORS_FAILURE,
  errorCode,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
