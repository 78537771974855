import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Text from '../../components/text';
import { humanize } from '../../helpers/iangels';
import { getStartupPageSelector, getStartupPageAttemptingSelector } from '../StartupPage/selectors';
import { getStartupPageAttempt } from '../StartupPage/actions';

const Thank = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const loading = useSelector(getStartupPageAttemptingSelector);
  const startupPage = useSelector(getStartupPageSelector);

  useEffect(() => {
    dispatch(getStartupPageAttempt(params?.slug, false));
  }, [params?.slug, dispatch]);

  return (
    <Container
      style={{
        padding: 0,
        marginTop: 45,
        height: '100%',
      }}
      maxWidth="sm"
    >
      <Stack direction="column" spacing={2} justifyContent="space-evenly" alignItems="center" style={{ margin: 5 }}>
        <Text
          text="Thank you for signing the confidentiality agreement"
          classes="f-tgf-400 mb-20"
          style={{ fontSize: 24, textAlign: 'center' }}
        />
        <Text
          text="An email notification will be sent once the supplemental materials become available."
          classes="f-size-16 mb-20"
          style={{ textAlign: 'center' }}
        />

        <div style={{ marginBottom: 40 }}>
          <Button
            variant="contained"
            className="btn"
            style={{
              minWidth: 220,
              minHeight: 40,
              borderRadius: 0,
              margin: 25,
            }}
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate('/startup/' + params?.slug)}
          >
            <span className="form-btn">Back to {loading ? humanize(params?.slug) : startupPage?.name} </span>
          </Button>
        </div>
      </Stack>
    </Container>
  );
};
export default Thank;
