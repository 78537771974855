import React, { useEffect, useState } from 'react';

// mui utils
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// iAngels utils
import { resendEmailAttempt, resendEmailClear } from './actions';
import { track } from '../../../services/analytics';
import useIsMounted from '../../../components/IsMounted';
import Text from '../../../components/text';
import IsMobileDevice from '../../../components/Generic/IsMobileDevice';

import {
  getResendEmailAttemptingSelector,
  getResendEmailErrorSelector,
  getResendEmailSuccessSelector,
} from './selectors';

// third party utils
import { useDispatch, useSelector } from 'react-redux';

const LayoutBanner = ({ email }) => {
  // hooks
  const isMobile = IsMobileDevice();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  // selectors
  const loading = useSelector(getResendEmailAttemptingSelector);
  const success = useSelector(getResendEmailSuccessSelector);
  const error = useSelector(getResendEmailErrorSelector);

  // state
  const [btnText, setBtnText] = useState('Resend Email');

  useEffect(() => {
    if (success) {
      setBtnText('Email Sent');
      track('Verification Email Sent');
    }

    if (error) {
      setBtnText('An error has occurred');
      track('Verification Email Error', { error: error });
    }

    const timer = setTimeout(() => {
      isMounted() && setBtnText('Resend Email');
      dispatch(resendEmailClear());
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [error, isMounted, success, dispatch]);

  const handleResendEmail = () => {
    track('Flash Message Click', { action: 'resend Email' });
    dispatch(resendEmailAttempt());
  };

  return (
    <div className="verify-banner">
      <Container
        style={{
          maxWidth: 1200,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack display="flex" flexDirection="row" alignItems="center">
          <ReportProblemIcon style={{ color: '#fff', marginRight: 10 }} />
          <Text
            classes="w-c"
            text={`To get full access to iAngels, please click the link in the verification email which was sent to ${email}`}
          />
        </Stack>
        {loading ? (
          <Stack
            className="primary-c"
            sx={{ height: '100%' }}
            style={{ width: 160, padding: 8, background: 'transparent' }}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress className="g-c" />
          </Stack>
        ) : (
          <Button
            className="btn"
            style={{
              marginLeft: 10,
              maxWidth: 160,
              minHeight: 40,
              marginRight: 10,
              borderRadius: 2,
              marginTop: isMobile ? 15 : 0,
              userSelect: 'none',
            }}
            onClick={handleResendEmail}
          >
            <span className="form-btn" style={{ letterSpacing: 1 }}>
              {btnText}
            </span>
          </Button>
        )}
      </Container>
    </div>
  );
};

export default LayoutBanner;
