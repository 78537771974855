import React from 'react';

// mui utils
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';

const Field = ({ obj, isMobile, style }) => {
  switch (obj.type) {
    case 'loading': {
      return <CircularProgress size={20} thickness={4} className="mt-10" />;
    }
    case 'text':
      return (
        <TextField
          required={obj.required}
          error={obj.error ? true : false}
          id={obj.id}
          label={obj.label}
          variant="standard"
          style={style}
          className="text-field"
          value={obj.value}
          name={obj.name}
          onChange={obj.onChange}
          helperText={obj.helperText}
        />
      );
    case 'text-number':
      return (
        <TextField
          required={obj.required}
          error={obj.error ? true : false}
          id={obj.id}
          label={obj.label}
          variant="standard"
          type="number"
          style={style}
          className="text-field"
          value={obj.value}
          name={obj.name}
          onChange={obj.onChange}
          helperText={obj.helperText}
        />
      );
    case 'text-multiline':
      return (
        <TextField
          required={obj.required}
          error={obj.error !== ''}
          id={obj.id}
          label={obj.label}
          variant="standard"
          style={style}
          value={obj.value}
          name={obj.name}
          onChange={obj.onChange}
          helperText={obj.helperText}
          multiline
          rows={5}
        />
      );
    case 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            disableCloseOnSelect={false}
            disableFuture
            id={obj.id}
            error={obj.error ? true : false}
            label={obj.label}
            value={obj.value}
            variant="standard"
            required={obj.required}
            onChange={(event) => obj.onChange(event, obj?.name)}
            name={obj.name}
            renderInput={(params) => (
              <TextField {...params} error={obj.error ? true : false} style={style} variant="standard" />
            )}
            //helperText={obj.helperText}
          />
        </LocalizationProvider>
      );
    case 'select':
      return (
        <FormControl variant="outlined" style={style} className="min-w-300">
          <InputLabel id={obj.labelId} style={obj.labelStyle} error={obj.error ? true : false}>
            {obj.label}
          </InputLabel>
          <Select
            label={obj.label}
            labelId={obj.labelId}
            id={obj.id}
            value={obj.value}
            onChange={obj.onChange}
            name={obj.name}
            variant="standard"
            required={obj.required}
            error={obj.error ? true : false}
            //helperText={obj.helperText}
          >
            {obj.itemsList.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case 'select-file-import':
      return (
        <FormControl variant="outlined" style={style} className="min-w-300">
          <InputLabel id={obj.labelId} style={obj.labelStyle} error={obj.error !== ''}>
            {obj.label}
          </InputLabel>
          <Select
            label={obj.label}
            labelId={obj.labelId}
            id={obj.id}
            value={obj.value}
            onChange={obj.onChange}
            name={obj.name}
            variant="standard"
            required={obj.required}
            error={obj.error !== ''}
            //helperText={obj.helperText}
          >
            {obj.itemsList?.map((item) => (
              <MenuItem key={item.name} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    default:
      return null;
  }
};

export default Field;
