import {
  IDENTITY_REGISTER_ATTEMPT,
  IDENTITY_REGISTER_FAILURE,
  IDENTITY_REGISTER_SUCCESS,
  CLEAR_SIGNUP_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const identityRegisterAttempt = (data) => ({
  type: IDENTITY_REGISTER_ATTEMPT,
  data,
});

export const identityRegisterSuccess = (registerSuccess) => ({
  type: IDENTITY_REGISTER_SUCCESS,
  registerSuccess,
});

export const identityRegisterFailure = (errorCode) => ({
  type: IDENTITY_REGISTER_FAILURE,
  errorCode,
});

export const clearSignup = () => ({
  type: CLEAR_SIGNUP_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
