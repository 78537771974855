import React from 'react';

const EntityName = ({ name }) => {
  return (
    <div className="table-header-entity-name-container ">
      <span className="entity-name">{name}</span>
    </div>
  );
};

export default EntityName;
