import { createFilteredList, getUnrealizedTotalInvested } from './investments';

export default class Active {
  constructor(iangels, icapital, lpsData, filters, entities, portfolios) {
    this.totalInvestments = 0;
    if (filters.length > 0 && filters.length !== entities.length) {
      this.iangels = [];
      this.icapital = [];
      this.lpsData = [];
      this.portfolios = portfolios.filter((p) => filters.filter((i) => i.name === p.name).length > 0);

      createFilteredList(iangels, filters, this.iangels, false, false);
      createFilteredList(icapital, filters, this.icapital, false, false);

      filters.forEach((element) => {
        lpsData?.forEach((data) => {
          if (data.entityName === element.name) {
            this.lpsData.push(data);
            this.totalInvestments += data?.unrealizedInvestments?.length;
          }
        });
      });
    } else {
      this.iangels = iangels;
      this.icapital = icapital;
      this.lpsData = lpsData;
      this.portfolios = portfolios;
      lpsData?.forEach((data) => {
        this.totalInvestments += data?.unrealizedInvestments?.length;
      });
    }
    this.totalInvestments += this.iangels?.length;
    this.totalInvestments += this.icapital?.length;

    this.iangelsTotalInvested = getUnrealizedTotalInvested(
      this.iangels,
      null,
      this.portfolios?.reduce((pv, cv) => pv + (cv?.totalCommitted || 0), 0)
    );
    this.icapitalTotalInvested = getUnrealizedTotalInvested(this.icapital);
    this.lpsDataTotalInvested = [];
    this.lpsData?.forEach((section) =>
      this.lpsDataTotalInvested.push(
        getUnrealizedTotalInvested(section?.unrealizedInvestments, null, section.totalCommitted, section?.fees?.amount)
      )
    );

    this.totalInvested =
      this.iangelsTotalInvested?.totalInvested +
      this.icapitalTotalInvested?.totalInvested +
      this.lpsDataTotalInvested?.reduce((pv, cv) => pv + (cv?.totalInvested || 0), 0);

    this.lastValue =
      this.iangelsTotalInvested?.lastValue +
      this.icapitalTotalInvested?.lastValue +
      this.lpsDataTotalInvested?.reduce((pv, cv) => pv + (cv?.lastValue || 0), 0);

    this.investmentSummary = {
      totalInvested: this.totalInvested,
      lastValue: this.lastValue,
      change: this.totalInvested !== 0 ? Math.round((this.lastValue / this.totalInvested - 1) * 100) : 0,
      totalCommitted:
        (this.portfolios?.reduce((pv, cv) => pv + (cv?.totalCommitted || 0), 0) || 0) +
        this.lpsDataTotalInvested?.reduce((pv, cv) => pv + (cv?.totalCommitted || 0), 0),
    };
  }
  get getTotalInvestments() {
    return this.totalInvestments;
  }

  get getIangelsTotalInvested() {
    return this.iangelsTotalInvested;
  }
  get getIcapitalTotalInvested() {
    return this.icapitalTotalInvested;
  }

  get getLpsDataTotalInvested() {
    return this.lpsDataTotalInvested;
  }

  get getInvestmentSummary() {
    return this.investmentSummary;
  }

  get getIangels() {
    return this.iangels;
  }

  get getIcapital() {
    return this.icapital;
  }

  get getLpsData() {
    return this.lpsData;
  }
}
