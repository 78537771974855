import { loadConfig } from '../env/config_util';
const config = loadConfig();

const getDPRTransform = () => {
  return window.devicePixelRatio ? 'dpr_' + window.devicePixelRatio : 'dpr_auto';
};

const buildUrl = (image_id, transforms) => {
  image_id = image_id || 'v1428312005/missing_avatar.gif';
  var _dpr = getDPRTransform();

  if (transforms) {
    transforms = transforms + ',' + _dpr;
    return '//res.cloudinary.com/' + config.config_module.org_id + '/image/upload/' + transforms + '/' + image_id;
  }
  return '//res.cloudinary.com/' + config.config_module.org_id + '/image/upload/' + _dpr + '/' + image_id;
};

export const getCloudinaryLogo = (image_id, size) => {
  return buildUrl(image_id, 'w_' + size + ',h_' + size + ',c_lpad');
};

export const getCloudinaryFace = (image_id, size) => {
  return buildUrl(image_id, 'w_' + size + ',h_' + size + ',c_fill');
};

export const getCircleFace = (image_id, size) => {
  return buildUrl(image_id, 'w_' + size + ',h_' + size + ',c_thumb,g_face,r_max');
};

export const getSquareFace = (image_id, size) => {
  return buildUrl(image_id, 'w_' + size + ',h_' + size + ',c_fill,g_face');
};

export const getAudio = (url) => {
  return '//res.cloudinary.com/' + config.config_module.org_id + '/video/upload/' + url;
};
