import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

const setWatermarkOnAllPages = (doc) => {
  for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
    doc.setPage(i);
    doc.setFontSize(52);
    doc.setTextColor(200);
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.5 }));
    doc.text(175, doc.internal.pageSize.height - 150, 'iAngels Confidential', { angle: 25 });
    doc.restoreGraphicsState();
  }
  return doc;
};

const processSection = (doc, id) => {
  const element = document.querySelector(id);
  if (!element) {
    console.error(`Element not found: ${id}`);
    return;
  }

  const isHeader = id.includes('Header');
  const isNewPage = id.includes('-NP-');
  const fillColor = isHeader ? [76, 185, 186] : [221, 241, 241];
  let isSubtotal = false;

  const columnsWidth = [300, 10, 100, 100, 100, 102, 50];

  // If it's a new section on a new page, add a new page
  if (isNewPage && !isHeader) {
    doc.addPage();
  }

  try {
    doc.autoTable({
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      html: id,
      useCss: true,
      headStyles: { fillColor: fillColor, cellPadding: { top: 5, right: 5, bottom: 5, left: 5 } },
      showHead: 'firstPage',
      didParseCell: function (data) {
        if (data.cell.text)
          data.cell.text = data.cell.text.map((o) =>
            o
              .replace(/([a-z])Invest$/, '$1')
              .replace(/Close Table/, '')
              .replace(/Open Table/, '')
              .replace(/Open all/, '')
              .replace(/Close all/, '')
          );

        if (isHeader) {
          data.cell.styles.cellWidth = columnsWidth[data.column.index];
        }

        if (data.section === 'body' && data.column?.index === 0 && data.cell.text?.length === 2)
          data.cell.text[1] = data.cell.text[1].replace(/\b\w/, (l) => l.toUpperCase());

        if (data.cell.text.filter((o) => o === 'Total').length > 0) isSubtotal = true;

        if (isSubtotal) {
          data.cell.styles.fillColor = [235, 235, 235];
        }

        data.cell.styles.cellPadding = { top: 5, right: 5, bottom: 5, left: 5 };
      },
    });
  } catch (e) {
    console.error('Error in autoTable: ' + e.message);
  }
};

export const exportPdf = (user, withSummary) => {
  const userName = (user?.user_metadata?.full_name || user?.name).replace('.', ' ');

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'A4',
  });

  doc.setFontSize(10);
  doc.text(`Account Name: ${userName}       Date: ${moment(new Date()).format('MMM, YYYY')}`, 10, 20);

  if (withSummary) {
    document
      .querySelectorAll("[id^='MyInvestmentsToPDFHeader']")
      .forEach((element) => processSection(doc, `#${element.id}`));
    doc.addPage();
  }

  // Process each main content section
  document.querySelectorAll("[id^='MyInvestmentsToPDF']").forEach((element) => processSection(doc, `#${element.id}`));

  setWatermarkOnAllPages(doc);
  doc.save(`${userName} Investments.pdf`);
};
