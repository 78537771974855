import { SET_FIRST_LOAD, SET_IMPERSONATED, SET_IMPERSONATED_USER_SUCCESS, CLEAR_STATE } from './actionTypes';

export const setFirstLoad = (isFirstLoad) => ({
  type: SET_FIRST_LOAD,
  isFirstLoad,
});
export const setImpersonated = (impersonated) => ({
  type: SET_IMPERSONATED,
  impersonated,
});
export const setImpersonatedUserSuccess = (impersonatedUser) => ({
  type: SET_IMPERSONATED_USER_SUCCESS,
  impersonatedUser,
});
export const clearState = () => ({
  type: CLEAR_STATE,
});
