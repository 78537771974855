import React, { useState } from 'react';
import MemoHtmlParser from './MemoHtmlParser';
// mui utils
import Link from '@mui/material/Link';

// iAngels utils
import Text from '../text';

const MemoCompanyUpdate = ({ title, content, isFirst }) => {
  const [open, setOpen] = useState(isFirst);
  return (
    <div>
      {isFirst ? (
        <Text text={title} classes="mb-20" />
      ) : (
        <Link
          onClick={() => {
            setOpen(!open);
          }}
          underline="hover"
          className="d-f mt-10 g-c"
        >
          {title}
        </Link>
      )}
      {open ? <MemoHtmlParser content={content} /> : null}
    </div>
  );
};
export default MemoCompanyUpdate;
