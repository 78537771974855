import {
  GET_NDA_EMBEDDED_URL_ATTEMPT,
  GET_NDA_EMBEDDED_URL_SUCCESS,
  GET_NDA_EMBEDDED_URL_FAILURE,
  GET_ENVELOP_ATTEMPT,
  GET_ENVELOP_FAILURE,
  GET_ENVELOP_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const getNdaEmbeddedUrlAttempt = (id) => ({
  type: GET_NDA_EMBEDDED_URL_ATTEMPT,
  id,
});

export const getNdaEmbeddedUrlSuccess = (embeddedUrl) => ({
  type: GET_NDA_EMBEDDED_URL_SUCCESS,
  embeddedUrl,
});

export const getNdaEmbeddedUrlFailure = (errorCode) => ({
  type: GET_NDA_EMBEDDED_URL_FAILURE,
  errorCode,
});

export const getEnvelopAttempt = (session) => ({
  type: GET_ENVELOP_ATTEMPT,
  session,
});

export const getEnvelopSuccess = (envelop) => ({
  type: GET_ENVELOP_SUCCESS,
  envelop,
});

export const getEnvelopFailure = (errorCode) => ({
  type: GET_ENVELOP_FAILURE,
  errorCode,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
