import React, { useEffect, useState } from 'react';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// mui utils
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// iAngels utils
import IsMobileDevice from '../../components/Generic/IsMobileDevice';
import { getUserSelector } from '../App/selectors';
import {
  getAccreditationAffirmationAttemptingSelector,
  getAccreditationAffirmationErrorSelector,
  getAccreditationAffirmationSuccessSelector,
} from './selectors';
import { clearAccreditationAffirmationState, accreditationAffirmationAttempt } from './actions';
import { track } from '../../services/analytics';

const Affirmation = () => {
  const isMobile = IsMobileDevice();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [affirmation, setAffirmation] = useState();
  const user = useSelector(getUserSelector);
  const loading = useSelector(getAccreditationAffirmationAttemptingSelector);
  const error = useSelector(getAccreditationAffirmationErrorSelector);
  const success = useSelector(getAccreditationAffirmationSuccessSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (affirmation != null) dispatch(accreditationAffirmationAttempt({ accredited: affirmation }));
  }, [affirmation, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearAccreditationAffirmationState());
      navigate('/error');
    }

    if (success) {
      track('Accreditation Affirmation', { is_accredited: affirmation });
      if (affirmation) {
        setOpen(false);
      } else if (affirmation === false) {
        navigate(`/user/not-accredited`);
      }
    }
  }, [error, success, navigate, dispatch, affirmation]);

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="400"
      scroll="paper"
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <div className="f-f-tgf f-w-500 f-size-18 mt-20 g3-c">{`Dear ${user?.name || user?.user_metadata?.full_name}`}</div>
      </DialogTitle>
      <DialogContent
        dividers={false}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <div className="f-f-g f-w-400 f-size-16 g4-c">
          Please confirm that there was no change in your accreditation status and you are an accredited investor under
          the applicable law
        </div>

        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} className="mt-20">
          <Button
            variant="contained"
            className={loading ? 'btn-disabled' : 'btn form-btn'}
            style={{
              minWidth: isMobile ? '100%' : 100,
              minHeight: 50,
              borderRadius: 0,
              maxWidth: 250,
              alignSelf: 'center',
            }}
            onClick={() => setAffirmation(true)}
            disabled={loading}
          >
            Confirm
          </Button>

          <span className="f-f-g f-w-400 f-size-16 gray-600" disabled={loading} onClick={() => setAffirmation(false)}>
            I'm not accredited
          </span>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Affirmation;
