import React from 'react';

// mui utils
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// iangels utils
import BrokenPageIcon from '../assets/broken_page.png';
import Text from './text';
import IsMobileDevice from './Generic/IsMobileDevice';

// third party utils
import { useNavigate } from 'react-router-dom';

const ErrorFallback = () => {
  const navigate = useNavigate();
  const isMobile = IsMobileDevice();

  const handleSupportClick = () => {
    window.location.href = 'mailto:backoffice@iangels.com';
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: isMobile ? 25 : 50,
        paddingBottom: 100,
      }}
    >
      <img src={BrokenPageIcon} alt="broken page icon" style={{ width: 145, height: 135 }} />
      <Text
        classes="f-tgf-400 mt-20"
        style={{ fontSize: 30, textAlign: 'center', margin: 25 }}
        text="Oops, something went wrong."
      />
      <Typography className="f-gl-400" style={{ fontSize: 16, textAlign: 'center', lineHeight: 1.6, maxWidth: 450 }}>
        Let's try to solve this by clicking
        <span className="g-c pointer bold" onClick={() => navigate(-1)}>
          {' '}
          here{' '}
        </span>
        and starting over. If this issue doesn't resolve itself, let us know by chat
        <span className="g-c pointer bold"> here </span>
        or send us an email to
        <span className="g-c pointer bold" onClick={handleSupportClick}>
          {' '}
          support@iangels.com
        </span>
      </Typography>
      <Button
        variant="contained"
        className="btn pointer"
        style={{ minWidth: 120, minHeight: 50, borderRadius: 0, margin: 25 }}
        onClick={() => navigate(`/dashboard`)}
      >
        Back to home page
      </Button>
    </div>
  );
};

export default ErrorFallback;
