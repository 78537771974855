import React from 'react';
import Paper from '@mui/material/Paper';

const FounderWrapper = (props) => {
  return (
    <Paper elevation={3} style={{ borderRadius: 0, padding: props.isMobile ? 15 : 25, marginTop: 25 }}>
      {props.children}
    </Paper>
  );
};

export default FounderWrapper;
