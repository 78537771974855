import React from 'react';
import Text from '../text';

const CardBadge = (props) => {
  const { badgeText, badgeClass } = props;

  if (!badgeText) {
    return null;
  }

  return (
    <div className="badge-wrapper">
      <Text text={badgeText} classes={badgeClass} />
    </div>
  );
};

export default CardBadge;
