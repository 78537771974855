export const config_module = {
  base_url: 'https://api.iangels.co',
  context: 'prod',
  domain: 'auth.iangels.com',
  client_id: '6i13sUisVOUCVzLALBbIgSkkhk4IKKsx',
  custom_domain: 'https://cdn.eu.auth0.com',
  org_id: 'iangels',
  redirectUrl: 'https://app.iangels.com',
  cloudinaryCloudName: 'iangels',
  gtmId: 'GTM-5S4WJG8',
  marketing_website_base_url: 'https://www.iangels.com/',
  auth0_connection: 'UPA-Prod',
};
