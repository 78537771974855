import { useCallback } from 'react';

const useIsSessionExpired = (expiresIn) => {
  const isSessionExpired = useCallback(() => {
    if (expiresIn === null) {
      return true;
    }
    const currentTime = new Date().getTime();
    const timeLeft = expiresIn - currentTime;

    return timeLeft < 0;
  }, [expiresIn]);

  return isSessionExpired;
};

export default useIsSessionExpired;
