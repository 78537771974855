import { call, all, put, takeLatest, select } from 'redux-saga/effects';
import { iAngelsState } from '../../helpers/iangelsState';
import { postData, getDataFromService } from '../../services/apiGateway';
import {
  identifyUserFailure,
  identifyUserSuccess,
  refreshTraitsFailure,
  refreshTraitsSuccess,
  clearIdentifyFailureState,
} from './actions';

import { IDENTIFY_USER_ATTEMPT, REFRESH_TRAITS_ATTEMPT } from './actionTypes';
import { getAuthResultSelector } from './selectors';

function* identifyUser() {
  const authResult = yield select(getAuthResultSelector);

  if (!authResult) {
    yield put(clearIdentifyFailureState());
    return;
  }

  try {
    let helper = new iAngelsState();
    let queryState = yield helper.getState(authResult);
    const traits = yield call(postData, '/iangels/users/identity/create', {
      token: authResult.__raw,
      state: queryState,
    });

    yield put(identifyUserSuccess(traits.data));
  } catch (errorCode) {
    yield put(identifyUserFailure(errorCode.response || errorCode));
  }
}

function* refreshTraits(action) {
  try {
    const traits = yield call(getDataFromService, '/iangels/users/traits');
    yield put(refreshTraitsSuccess(traits?.data));
  } catch (errorCode) {
    yield put(refreshTraitsFailure(errorCode.response || errorCode));
  }
}

function* authSaga() {
  yield all([takeLatest(IDENTIFY_USER_ATTEMPT, identifyUser)]);
  yield all([takeLatest(REFRESH_TRAITS_ATTEMPT, refreshTraits)]);
}

export default authSaga;
