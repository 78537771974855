// third party lib
import { call, all, put, takeLatest } from 'redux-saga/effects';

// iangels lib
import {
  GET_KYC_SUBMITTED_DOCUMENTS_ATTEMPT,
  DOWNLOAD_KYC_TEMPLATE_ATTEMPT,
  SAVE_DOCUMENTS_ATTEMPT,
  FINISH_INVESTMENT_ATTEMPT,
} from './actionTypes';
import { getDataFromService, postData } from '../../services/apiGateway';
import {
  getKycSubmittedDocumentsFailure,
  getKycSubmittedDocumentsSuccess,
  downloadKycTemplateFailure,
  downloadKycTemplateSuccess,
  saveDocumentsSuccess,
  saveDocumentsFailure,
  finishInvestmentSuccess,
  finishInvestmentFailure,
} from './actions';

function* getKycSubmitted(action) {
  try {
    const submittedDocuments = yield call(getDataFromService, `/iangels/investments/kyc/${action.id}/documents`);
    yield put(getKycSubmittedDocumentsSuccess(submittedDocuments?.data));
  } catch (errorCode) {
    yield put(getKycSubmittedDocumentsFailure(errorCode.response || errorCode));
  }
}

function* downloadKycTemplate(action) {
  try {
    const template = yield call(
      getDataFromService,
      `/iangels/investments/verification/download/${encodeURIComponent('account_verification/' + action.id)}`
    );
    yield put(downloadKycTemplateSuccess(template?.data));
  } catch (errorCode) {
    yield put(downloadKycTemplateFailure(errorCode.response || errorCode));
  }
}

function* saveDocuments(action) {
  try {
    const saveDocumentsRes = yield call(postData, '/iangels/investments/kyc/documents', action.data);
    yield put(saveDocumentsSuccess(saveDocumentsRes?.data));
  } catch (errorCode) {
    yield put(saveDocumentsFailure(errorCode.response || errorCode));
  }
}

function* finishInvestment(action) {
  try {
    const finishInvestmentRes = yield call(
      postData,
      `/iangels/investments/investment/${action.investmentType}/${action.investmentId}/kyc`,
      action.data
    );
    yield put(finishInvestmentSuccess(finishInvestmentRes?.data));
  } catch (errorCode) {
    yield put(finishInvestmentFailure(errorCode.response || errorCode));
  }
}

function* kycSubmitSaga() {
  yield all([takeLatest(GET_KYC_SUBMITTED_DOCUMENTS_ATTEMPT, getKycSubmitted)]);
  yield all([takeLatest(DOWNLOAD_KYC_TEMPLATE_ATTEMPT, downloadKycTemplate)]);
  yield all([takeLatest(SAVE_DOCUMENTS_ATTEMPT, saveDocuments)]);
  yield all([takeLatest(FINISH_INVESTMENT_ATTEMPT, finishInvestment)]);
}

export default kycSubmitSaga;
