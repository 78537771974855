import { createSelector } from 'reselect';

const getKycSubmittedDocuments = (state) => state.kycSubmit.submittedDocuments;
const getKycSubmittedDocumentsAttempting = (state) => state.kycSubmit.attempting;
const getKycSubmittedDocumentsError = (state) => state.kycSubmit.errorCode;

const downloadKycTemplate = (state) => state.kycSubmit.template;
const downloadKycTemplateAttempting = (state) => state.kycSubmit.attempting;
const downloadKycTemplateError = (state) => state.kycSubmit.errorCode;

const getKycFiles = (state) => state.kycSubmit.files;

const getSavedFilesSuccess = (state) => state.kycSubmit.saveDocumentsRes;
const getFinishInvestmentSuccess = (state) => state.kycSubmit.finishInvestmentRes;

export const getFinishInvestmentSuccessSelector = createSelector(
  getFinishInvestmentSuccess,
  (finishInvestmentRes) => finishInvestmentRes
);

export const getSavedFilesSuccessSelector = createSelector(
  getSavedFilesSuccess,
  (saveDocumentsRes) => saveDocumentsRes
);

export const getKycSubmittedDocumentsSelector = createSelector(
  getKycSubmittedDocuments,
  (submittedDocuments) => submittedDocuments
);
export const getKycSubmittedDocumentsAttemptingSelector = createSelector(
  getKycSubmittedDocumentsAttempting,
  (attempting) => attempting
);
export const getKycSubmittedDocumentsErrorSelector = createSelector(
  getKycSubmittedDocumentsError,
  (errorCode) => errorCode
);

export const downloadKycTemplateSelector = createSelector(downloadKycTemplate, (template) => template);
export const downloadKycTemplateAttemptingSelector = createSelector(
  downloadKycTemplateAttempting,
  (attempting) => attempting
);
export const downloadKycTemplateErrorSelector = createSelector(downloadKycTemplateError, (errorCode) => errorCode);
export const getKycFilesSelector = createSelector(getKycFiles, (files) => files);
