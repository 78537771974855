// material ui utils
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// iangels utils
import LoadingIndicator from '../Generic/LoadingIndicator';
import Text from '../text';

const InvestEntitySelection = ({
  params,
  handleEntityChange,
  handleEntityKeyDown,
  handleNext,
  isDisabled,
  loading,
  entity,
  setEntity,
  entities,
}) => {
  return (
    <Stack style={{ marginTop: 50, minHeight: 0 }} spacing={2} direction="column">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Text
            variant="h4"
            classes="f-tgf-400 kyc-title"
            style={{ marginLeft: 0, marginTop: 30 }}
            text="Investing Entity"
          />

          <Stack
            style={{ margin: 0, marginTop: 50, width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}
            spacing={2}
          >
            <Text classes={'bold g4-c f-f-tgf f-w-500 mt-19 f-size-09'} text="Select/Create investing entity name" />
            <div style={{ minWidth: '52%', marginTop: 0 }}>
              <Autocomplete
                id="autocomplete-invest"
                options={entities ? entities : []}
                getOptionLabel={(option) => option.name}
                disableClearable
                freeSolo
                className="f-tgf-400"
                onChange={(event, selectedEntity) => {
                  setEntity(selectedEntity);
                }}
                renderInput={(renderParams) => (
                  <TextField
                    {...renderParams}
                    id="entity"
                    style={{ width: '100%', margin: '10px 0' }}
                    variant="standard"
                    className="text-field"
                    placeholder="KYC Name"
                    value={entity}
                    onChange={handleEntityChange}
                    onKeyDown={handleEntityKeyDown}
                    name="entity"
                  />
                )}
              />
            </div>
            <Button
              variant="contained"
              className={isDisabled() ? '' : 'entities-next-btn'}
              style={{
                minWidth: 200,
                maxWidth: '10%',
                minHeight: 58,
                marginTop: '-10px',
                float: 'right',
                marginBottom: 40,
                borderRadius: 8,
              }}
              onClick={handleNext}
              disabled={isDisabled()}
            >
              <span className="bold f-size-16" style={{ letterSpacing: 1 }}>
                Next
              </span>
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default InvestEntitySelection;
