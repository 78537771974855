import { resendEmailSuccess, resendEmailFailure } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { postData } from '../../../services/apiGateway';
import { RESEND_EMAIL_ATTEMPT } from './actionTypes';

function* resendEmail(action) {
  try {
    const resendStatus = yield call(postData, '/iangels/users/send/email-verification', {});
    yield put(resendEmailSuccess(resendStatus?.data));
  } catch (errorCode) {
    yield put(resendEmailFailure(errorCode.response || errorCode));
  }
}

function* layoutSaga() {
  yield all([takeLatest(RESEND_EMAIL_ATTEMPT, resendEmail)]);
}

export default layoutSaga;
