// iangels utils
import { convertToInternationalCurrencySystem, truncateDecimal } from '../helpers/iangels';
import { CURRENCY_FORMAT } from '../helpers/constants';

export const getPricingTerms = (investment, t, isSubRow) => {
  let amount;
  let amountCap;
  let amountDiscount;
  let pricingTermsEmptyOrSeveral;
  let roundName;
  let hasConvertedRound = false;
  let tokenTerms;

  const roundType = investment.convertedRoundType ? investment.convertedRoundType : investment.roundType;
  const roundValuation = investment.convertedValuation ? investment.convertedValuation : investment.preMoneyValue;
  const investmentType = investment.type;

  const capitalize = (text) => {
    return text
      ? text
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : text;
  };
  const cap =
    investment.cap !== 0
      ? t('portfolio.pricingTerms.capUS') + ` ${convertToInternationalCurrencySystem(investment.cap, 1)}`
      : '';
  const roundTypeDesc = t('portfolio.pricingTerms.converted') + ' ' + investment.roundType;

  const discountDesc = t('portfolio.pricingTerms.discount') + `: ${investment.discount}%`;
  const internationalCurrencySystem =
    t('portfolio.pricingTerms.US') +
    ` ${convertToInternationalCurrencySystem(roundValuation, 1)} ` +
    t('portfolio.pricingTerms.pre');

  if (investment?.subRows?.length > 0 && investmentType !== 'fund' && investmentType !== 'token')
    pricingTermsEmptyOrSeveral = t('portfolio.pricingTerms.several');
  else if ((investmentType === 'fund' || investmentType === 'token') && isSubRow) pricingTermsEmptyOrSeveral = '';
  else {
    if ((roundType === 'common equity' || roundType === 'preferred equity') && roundValuation)
      amount = internationalCurrencySystem;
    else if (investment.cap || investment.discount) {
      if (investment.cap !== null && investment.cap !== undefined && investment.cap !== 0) amountCap = cap;
      if (investment.discount) amountDiscount = discountDesc;
    } else if (investmentType === 'token') {
      if (
        (investment.subRows.length && investment.price) ||
        (!investment.subRows.length && investment.termType === 'none') ||
        (investment.termValue && investment.termType)
      )
        amount = investment.price
          ? `${CURRENCY_FORMAT[investment.dealCurrency]} ${truncateDecimal(investment.price, 3)}`
          : 'N/A';
      else
        !investment.subRows.length && (!investment.termValue || !investment.termType)
          ? (amount = 'N/A')
          : (amount = '');
      if (
        (investment.subRows.length && investment.price && investment.termValue && investment.termType) ||
        (!investment.subRows.length && investment.termType !== 'none' && investment.termValue && investment.termType)
      )
        tokenTerms = `${investment.termValue}% ${investment.termType}`;
    } else amount = 'N/A';
    roundName = capitalize(roundType) || investment.type;
    hasConvertedRound = investment.convertedRoundType;
  }
  return {
    pricingTermsEmptyOrSeveral: pricingTermsEmptyOrSeveral,
    amount: amount,
    amountCap: amountCap,
    amountDiscount: amountDiscount,
    tokenTerms: tokenTerms,
    roundName: roundName,
    roundType: hasConvertedRound ? roundTypeDesc : '',
  };
};
