import { call, all, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService } from '../../services/apiGateway';
import { getInvestmentDataFailure, getInvestmentDataSuccess } from './actions';

import { GET_INVESTMENT_INVESTMENT_DATA_ATTEMPT } from './actionTypes';

function* signingInvestmentDataAttempt(action) {
  try {
    const investmentData = yield call(
      getDataFromService,
      `/iangels/investments/investment/${action.data.investmentType}/${action.data.investmentId}`
    );
    yield put(getInvestmentDataSuccess(investmentData?.data));
  } catch (errorCode) {
    yield put(getInvestmentDataFailure(errorCode.response || errorCode));
  }
}

function* investmentDataSaga() {
  yield all([takeLatest(GET_INVESTMENT_INVESTMENT_DATA_ATTEMPT, signingInvestmentDataAttempt)]);
}

export default investmentDataSaga;
