import React from 'react';
import Box from '@mui/material/Box';

const AccordionItem = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        fontSize: '0.875rem',
        fontWeight: '700',
        display: 'flex',
        margin: 0,
        ...sx,
      }}
      {...other}
    />
  );
};

export default AccordionItem;
