import React from 'react';

// mui utils
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

// iangels utils
import VerifiedIcon from '../../assets/kyc-verified.svg';
import WarningIcon from '../../assets/kyc-warning.svg';
import Text from '../../components/text';

// third party utils
import { useNavigate } from 'react-router-dom';

const KycStatusMobileTable = ({ kycStatus }) => {
  const navigate = useNavigate();

  const isSubmitted = (status) => {
    return status?.toLowerCase() === 'verified' || status?.toLowerCase() === 'submitted';
  };

  return (
    <Box>
      <Paper
        elevation={2}
        style={{
          height: '100%',
          borderRadius: 0,
          paddingBottom: 20,
          marginTop: 35,
          boxShadow: 'none',
        }}
      >
        {kycStatus?.map((row) => (
          <div key={row.id} style={{ borderBottom: '2px solid rgba(224, 224, 224, 1)', marginBottom: 15 }}>
            <div className="df-sb" style={{ marginBottom: 7 }}>
              <div className="df-l">
                {isSubmitted(row.status) ? (
                  <img src={VerifiedIcon} alt={row.status} loading="lazy" />
                ) : (
                  <img src={WarningIcon} alt={row.status} loading="lazy" />
                )}
                <Text classes="f-tgf-400 f-size-12" text={row.name} style={{ marginLeft: 7, maxWidth: 250 }} />
              </div>
              <div style={{ marginRight: 5 }}>
                {!isSubmitted(row.status) ? (
                  <Button
                    startIcon={<UploadIcon />}
                    className="g-c"
                    style={{ padding: 0 }}
                    onClick={() => {
                      navigate(`/entity/upload/${row.id}`);
                    }}
                  >
                    Upload
                  </Button>
                ) : (
                  <Text
                    classes="f-gl-400 f-size-12"
                    text={row.type?.replace('_', ' ')}
                    style={{ marginLeft: 7, maxWidth: 250 }}
                  />
                )}
              </div>
            </div>
            <Text
              classes={
                isSubmitted(row.status) ? 'f-tgf-400 f-size-12 uppercase g-c' : 'f-tgf-400 f-size-12 uppercase l-text'
              }
              style={{ marginLeft: 30, marginBottom: 10 }}
              text={row?.status?.replace('_', ' ')}
            />
          </div>
        ))}
      </Paper>
    </Box>
  );
};

export default KycStatusMobileTable;
