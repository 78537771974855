import {
  GET_STARTUPS_ATTEMPT,
  GET_STARTUPS_SUCCESS,
  GET_STARTUPS_FAILURE,
  GET_SECTORS_STARTUPS_ATTEMPT,
  GET_SECTORS_STARTUPS_FAILURE,
  GET_SECTORS_STARTUPS_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const getStartupsAttempt = () => ({
  type: GET_STARTUPS_ATTEMPT,
});

export const getStartupsSuccess = (startups) => ({
  type: GET_STARTUPS_SUCCESS,
  startups,
});

export const getStartupsFailure = (errorCode) => ({
  type: GET_STARTUPS_FAILURE,
  errorCode,
});

export const getSectorsAttempt = () => ({
  type: GET_SECTORS_STARTUPS_ATTEMPT,
});

export const getSectorsSuccess = (sectors) => ({
  type: GET_SECTORS_STARTUPS_SUCCESS,
  sectors,
});

export const getSectorsFailure = (errorCode) => ({
  type: GET_SECTORS_STARTUPS_FAILURE,
  errorCode,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
