import React from 'react';

// mui utils
import Stack from '@mui/material/Stack';

// third party utils
import { useSelector } from 'react-redux';

// iAngels utils
import { PROVIDER_TYPES } from '../../helpers/constants';
import { getUserSelector } from '../App/selectors';
import Congrats from '../../assets/congrats.svg';
import Text from '../../components/text';

const RegisterComplete = () => {
  const user = useSelector(getUserSelector);

  const providerType = () => {
    if (!user?.identities) return null;
    return user.identities[0].connection;
  };

  const providerTypeName = providerType();

  return (
    <Stack
      direction="column"
      spacing={4}
      justifyContent="space-evenly"
      alignItems="center"
      width="100%"
      style={{ margin: 0 }}
    >
      <Text style={{ fontSize: 20, marginTop: 10 }} classes="f-tgf-400 g-c" text="Thank You for Registering!" />
      <img src={Congrats} alt="congrats" />
      <Text
        classes="f-gl-400 f-size-16 justify"
        style={{ maxWidth: 300, marginBottom: 25 }}
        text={
          providerTypeName === PROVIDER_TYPES.UPA ||
          providerTypeName === PROVIDER_TYPES.UPA_STAGING ||
          providerTypeName === PROVIDER_TYPES.U_NAME_PASS
            ? 'We sent an activation link to your email address. Please confirm your account to enjoy full access to the platform.' +
              ' If you do not receive our emails, please check your spam/promotion folders.' +
              ' If you found our emails within those folders, please mark them as "not spam/promotion"'
            : "Welcome to the iAngels' investment platform. Feel free to explore investment opportunities by clicking explore."
        }
      />
    </Stack>
  );
};

export default RegisterComplete;
