import React from 'react';

// mui utils
import useMediaQuery from '@mui/material/useMediaQuery';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';

// iangels utils
import { getCloudinaryLogo, getCloudinaryFace } from '../../helpers/cloudinary';
import { flattenObject } from '../../helpers/iangels';
import { track } from '../../services/analytics';
import CardBadge from '../../components/Generic/CardBadge';
import Text from '../../components/text';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

const CeoItem = ({ name, avatar, role }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div>
        {avatar ? (
          <img src={getCloudinaryFace(avatar, 50)} alt="name" height={55} />
        ) : (
          <Avatar alt={name} style={{ height: 55, width: 55, margin: 2 }} />
        )}
      </div>
      <div style={{ marginLeft: 5 }}>
        <Text text={name} variant="body2" classes="f-gl-400" />
        <Text text={role} variant="subtitle2" classes="f-gl-400 muted-text" />
      </div>
    </div>
  );
};

const CompanyCard = (props) => {
  const matches_1000 = useMediaQuery('(max-width:1000px)');
  const matches_700 = useMediaQuery('(max-width:700px)');
  const isMobile = IsMobileDevice();

  const getImage = () => {
    try {
      let img = require(`../../assets/roundbox/${props.card?.sector?.toLowerCase().replace(/ /g, '-')}.jpg`);
      return img;
    } catch (ex) {
      return require('../../assets/roundbox/default.jpg');
    }
  };

  const getCompanyLogo = () => {
    return getCloudinaryLogo(props.card?.logo, 80);
  };

  const getTeamMembers = () => {
    if (!props.card?.headTeamMember) {
      return null;
    }
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text variant="body2" classes="f-gl-400" text="Founder" />
          {props.card.coInvestors?.length > 0 ? (
            <Text variant="body2" classes="f-gl-400" text="Notable Shareholder" />
          ) : null}
        </div>
        <Divider
          style={{
            marginTop: 10,
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div style={props.card.coInvestors?.length > 0 ? { maxWidth: '50%' } : { maxWidth: '100%' }}>
            <CeoItem
              name={props.card.headTeamMember.fullName}
              role={props.card?.headTeamMember?.role}
              avatar={props.card.headTeamMember.avatar}
            />
          </div>
          {props.card.coInvestors?.length > 0 ? (
            <div style={{ maxWidth: '50%' }}>
              <CeoItem name={props.card.coInvestors[0].fullName} avatar={props.card.coInvestors[0].avatar} />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const getRoundState = () => {
    if (!props.hasRoundState) {
      return null;
    }

    return (
      <div>
        <Divider
          style={{
            marginTop: 10,
          }}
        />
        <Text
          variant="body2"
          classes="f-gl-400 f-size-12 gray-800"
          text="Round Size"
          style={{
            marginTop: 10,
          }}
        />
        <Text
          variant="h6"
          classes="f-gl-400"
          text={'US$ ' + props.card?.roundStats?.roundSize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          style={{ fontSize: '1em', fontWeight: 'bolder' }}
        />
      </div>
    );
  };

  return (
    <div
      style={
        matches_1000
          ? matches_700
            ? { width: '100%', minWidth: 280 }
            : { width: '50%', minWidth: 280 }
          : { width: '33.3%', minWidth: 280 }
      }
    >
      <Card style={{ margin: 10, padding: 0 }} className="card-company card-company-height">
        <CardActionArea href={props.cardTo}
          onClick={() => {
            track('Round Box Click', { ...flattenObject({ round: props.card }), target: 'round' });

          }}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
        >
          <CardBadge
            badgeText={props.card?.closed ? 'Closed' : props.card?.acquired ? 'Acquired' : ''}
            badgeClass={props.card?.closed ? 'badge-orange' : props.card?.acquired ? 'badge-green' : ''}
          />
          <div style={{ width: '100%' }}>
            <div style={{ position: 'relative' }}>
              <CardMedia component="img" alt="green iguana" height="99" image={getImage()} />
              <div style={{ position: 'absolute' }} className="sector">
                {props?.card?.sector}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: -50,
                }}
              >
                <img src={getCompanyLogo()} alt="logo" height={80} width={80} className="company-circle-logo" />
              </div>
            </div>

            <CardContent>
              <div className="card-content">
                <Text variant="h6" classes="f-tgf-400 f-size-16 g4-c" text={props.card?.name} />
                <Text variant="body2" classes="f-gl-400 f-size-14 g4-c" text={props.card?.tagLine} />
                {props.hasShortDescription ? (
                  <Text variant="body2" classes="f-tgf-400 f-size-12 g4-c" text={props.card?.shortDescription} />
                ) : null}
              </div>
              {!isMobile && (props.card?.headTeamMember || props.hasRoundState) ? (
                <div
                  style={!props.hasRoundState ? { marginTop: 10, height: '7em' } : { marginTop: 10 }}
                  className="card-members"
                >
                  {getTeamMembers()}
                  {getRoundState()}
                </div>
              ) : null}
            </CardContent>
          </div>
          <div style={{ width: '100%' }}>
            <CardActions className="card-action">
              <div className="card-btn">Learn More</div>
            </CardActions>
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default CompanyCard;
