export const IDENTIFY_USER_ATTEMPT = 'IDENTIFY_USER_ATTEMPT';
export const IDENTIFY_USER_SUCCESS = 'IDENTIFY_USER_SUCCESS';
export const IDENTIFY_USER_FAILURE = 'IDENTIFY_USER_FAILURE';
export const IDENTIFY_USER_FAILURE_CLEAR = 'IDENTIFY_USER_FAILURE_CLEAR';

export const SET_USER_ATTEMPT = 'SET_USER_ATTEMPT';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_FAILURE = 'SET_USER_FAILURE';

export const SET_AUTH_RESULT = 'SET_AUTH_RESULT';
export const SET_FORCE_GET_USER_INFO = 'SET_FORCE_GET_USER_INFO';

export const REFRESH_TRAITS_ATTEMPT = 'REFRESH_TRAITS_ATTEMPT';
export const REFRESH_TRAITS_SUCCESS = 'REFRESH_TRAITS_SUCCESS';
export const REFRESH_TRAITS_FAILURE = 'REFRESH_TRAITS_FAILURE';

export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';

export const CLEAR_STATE = 'CLEAR_STATE';
