import {
  ACCREDITATION_ATTEMPT,
  ACCREDITATION_FAILURE,
  ACCREDITATION_SUCCESS,
  CLEAR_ACCREDITATION_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  errorCode: null,
  attempting: false,
  success: null,
};

export const AccreditationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCREDITATION_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        success: null,
      };
    case ACCREDITATION_SUCCESS:
      return {
        ...state,
        attempting: false,
        errorCode: null,
        success: action.success,
      };
    case ACCREDITATION_FAILURE:
      return {
        ...state,
        attempting: false,
        success: null,
        errorCode: action.errorCode,
      };
    case CLEAR_ACCREDITATION_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
