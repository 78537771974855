import React from 'react';
import Text from '../../components/text';

const FounderHeader = () => {
  return (
    <>
      <Text variant="h5" classes="f-tgf-400" text="Startup Application" />
      <Text classes="f-size-16 mt-10" text="Thank you for your interest in iAngels." />
      <Text
        classes="f-size-16  mt-10 justify"
        text="Please complete our startup application form below and a member of our investment team will reach out shortly to follow up."
      />
      <Text
        classes="f-size-12 r-c mt-10 mb-10"
        text="Disclaimer: We only invest in Israel and will only review applications from companies headquartered in Israel."
      />
    </>
  );
};

export default FounderHeader;
