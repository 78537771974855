import { Button, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { syncAttempt, clearState } from './actions'
import { useState, useEffect } from 'react'

const Sync = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const syncFailure = useSelector(state => state.sync.erroCode);
    const syncSuccess = useSelector(state => state.sync.success);

    const handleSync = (event) => {
        event.preventDefault();
        setIsLoading(true);
        dispatch(syncAttempt());
    }

    useEffect(() => {
        if (syncFailure) {
            setIsLoading(false);
            dispatch(clearState());
        }
        if (syncSuccess) {
            setIsLoading(false);
            dispatch(clearState());
        }
    }, [syncFailure, syncSuccess, dispatch]);

    return (<div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 50
    }}>
        <Button onClick={handleSync} variant="contained">{isLoading ? "Synchronization..." : "Synchronize now."}</Button>
        {isLoading && <CircularProgress size={24} />}
    </div>);
};

export default Sync;