import React, { useEffect, useState } from 'react';

// mui utils
import Container from '@mui/material/Container';

// iAngels utils
import OpportunitiesIcon from '../../assets/iAngels-opportunities.png';
import { getDashboardSelector } from '../Dashboard/selectors';
import Text from '../../components/text';
import CompanyCard from './CompanyCard';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

// third party utils
import { useSelector } from 'react-redux';

const InvestmentOpportunitiesPage = (props) => {
  const isMobile = IsMobileDevice();
  const dashboard = useSelector(getDashboardSelector);

  const [activelyManagedOffering, setActivelyManagedOffering] = useState(null);

  useEffect(() => {
    if (dashboard && !activelyManagedOffering) {
      let activelyManagedList = [];

      if (dashboard?.opportunities?.fundOpportunities?.length > 0) {
        dashboard.opportunities.fundOpportunities.forEach((element) => {
          activelyManagedList.push(element);
        });
      }

      if (dashboard?.opportunities?.managedOpportunities?.length > 0) {
        dashboard.opportunities.managedOpportunities.forEach((element) => {
          activelyManagedList.push(element);
        });
      }

      setActivelyManagedOffering(activelyManagedList);
    }
  }, [dashboard, activelyManagedOffering]);

  const getOpportunities = (opportunities, title, subtitle, image) => {
    if (!opportunities?.length > 0) {
      return null;
    }

    return (
      <>
        <div style={{ margin: 10, marginTop: 24, marginRight: 10, marginBottom: 24 }} className="mobileSection">
          {image ? (
            <img
              src={image}
              alt="icon"
              style={{ marginBottom: 5 }}
              width={isMobile ? 260 : 371}
              height={isMobile ? 29 : 41}
            />
          ) : (
            <Text variant="h5" tx={title} classes="f-f-pmp f-size-30 g4-c" />
          )}
          <Text variant="body1" tx={subtitle} classes="f-gl-400" style={{ marginTop: 5 }} />
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexFlow: 'row wrap',
          }}
        >
          {opportunities.map((element, index) => (
            <CompanyCard
              card={element}
              hasRoundState={element.roundStats?.roundSize > 0}
              hasShortDescription={true}
              key={index}
              cardTo={`/startup/${element.slug}`}
            ></CompanyCard>
          ))}
        </div>
      </>
    );
  };

  return (
    <Container
      style={{
        padding: 0,
        display: 'flex',
        flexFlow: 'row wrap',
        width: 'auto',
        marginLeft: -10,
        marginRight: -10,
        marginTop: 0,
        maxWidth: 1200,
      }}
    >
      {getOpportunities(
        activelyManagedOffering,
        'investmentOpportunities.activelyManagedTitle',
        'investmentOpportunities.activelyManagedSubtitle',
        null
      )}

      {/* has no title, image is set and title is null */}
      {getOpportunities(
        dashboard?.opportunities?.iangelsOpportunities,
        null,
        'investmentOpportunities.iangelsOpportunitiesSubtitle',
        OpportunitiesIcon
      )}

      {getOpportunities(
        dashboard?.opportunities?.syndicateOpportunities,
        'investmentOpportunities.syndicateOpportunitiesTitle',
        'investmentOpportunities.syndicateOpportunitiesSubtitle',
        null
      )}
    </Container>
  );
};

export default InvestmentOpportunitiesPage;
