import { getResetPasswordFailure, getResetPasswordSuccess } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CLIENTS_NAME } from '../../helpers/constants';
import { RESET_PASSWORD_ATTEMPT } from './actionTypes';
import { postData } from '../../services/apiGateway';

function* getResetPasswordStatus(action) {
  try {
    const status = yield call(
      postData,
      '/iangels/users/identity/change-password',
      {
        email: action.email,
        client: CLIENTS_NAME.iAngels,
      },
      true
    );
    yield put(getResetPasswordSuccess(status?.data));
  } catch (errorCode) {
    yield put(getResetPasswordFailure(errorCode.response || errorCode));
  }
}

function* resetPasswordSaga() {
  yield all([takeLatest(RESET_PASSWORD_ATTEMPT, getResetPasswordStatus)]);
}

export default resetPasswordSaga;
