import {
  GET_KYC_SUBMITTED_DOCUMENTS_ATTEMPT,
  GET_KYC_SUBMITTED_DOCUMENTS_SUCCESS,
  GET_KYC_SUBMITTED_DOCUMENTS_FAILURE,
  DOWNLOAD_KYC_TEMPLATE_ATTEMPT,
  DOWNLOAD_KYC_TEMPLATE_SUCCESS,
  DOWNLOAD_KYC_TEMPLATE_FAILURE,
  FINISH_INVESTMENT_ATTEMPT,
  FINISH_INVESTMENT_FAILURE,
  FINISH_INVESTMENT_SUCCESS,
  SAVE_DOCUMENTS_ATTEMPT,
  SAVE_DOCUMENTS_FAILURE,
  SAVE_DOCUMENTS_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  CLEAR_KYC_DOCUMENTS,
  CLEAR_TEMPLATE,
  CLEAR_FILES,
  CLEAR_STATE,
} from './actionTypes';

export const saveDocumentsAttempt = (data) => ({
  type: SAVE_DOCUMENTS_ATTEMPT,
  data,
});

export const saveDocumentsSuccess = (saveDocumentsRes) => ({
  type: SAVE_DOCUMENTS_SUCCESS,
  saveDocumentsRes,
});

export const saveDocumentsFailure = (errorCode) => ({
  type: SAVE_DOCUMENTS_FAILURE,
  errorCode,
});

export const finishInvestmentAttempt = (data, investmentType, investmentId) => ({
  type: FINISH_INVESTMENT_ATTEMPT,
  data,
  investmentType,
  investmentId,
});

export const finishInvestmentSuccess = (finishInvestmentRes) => ({
  type: FINISH_INVESTMENT_SUCCESS,
  finishInvestmentRes,
});

export const finishInvestmentFailure = (errorCode) => ({
  type: FINISH_INVESTMENT_FAILURE,
  errorCode,
});

export const getKycSubmittedDocumentsAttempt = (id) => ({
  type: GET_KYC_SUBMITTED_DOCUMENTS_ATTEMPT,
  id,
});

export const getKycSubmittedDocumentsSuccess = (submittedDocuments) => ({
  type: GET_KYC_SUBMITTED_DOCUMENTS_SUCCESS,
  submittedDocuments,
});

export const getKycSubmittedDocumentsFailure = (errorCode) => ({
  type: GET_KYC_SUBMITTED_DOCUMENTS_FAILURE,
  errorCode,
});

export const downloadKycTemplateAttempt = (id) => ({
  type: DOWNLOAD_KYC_TEMPLATE_ATTEMPT,
  id,
});

export const downloadKycTemplateSuccess = (template) => ({
  type: DOWNLOAD_KYC_TEMPLATE_SUCCESS,
  template,
});

export const downloadKycTemplateFailure = (errorCode) => ({
  type: DOWNLOAD_KYC_TEMPLATE_FAILURE,
  errorCode,
});

export const uploadFileSuccess = (imageId, key) => ({
  type: UPLOAD_FILE_SUCCESS,
  imageId,
  key,
});

export const clearFile = (key) => ({
  type: CLEAR_FILES,
  key,
});

export const clearTemplate = () => ({
  type: CLEAR_TEMPLATE,
});

export const clearFiles = () => ({
  type: CLEAR_FILES,
});

export const clearKycSubmittedDocuments = () => ({
  type: CLEAR_KYC_DOCUMENTS,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
