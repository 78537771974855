import { createSelector } from 'reselect';

const getInvestmentEntities = (state) => state.investment.entities;
const getInvestmentSuccess = (state) => state.investment.investmentSuccess;
const getInvestmentAttempting = (state) => state.investment.attempting;
const getInvestmentError = (state) => state.investment.errorCode;
const getSigningUrl = (state) => state.investment.urlSuccess;

export const getInvestmentEntitiesSelector = createSelector(getInvestmentEntities, (entities) => entities);
export const getInvestmentAttemptingSelector = createSelector(getInvestmentAttempting, (attempting) => attempting);
export const getInvestmentErrorSelector = createSelector(getInvestmentError, (errorCode) => errorCode);
export const getSigningUrlSelector = createSelector(getSigningUrl, (urlSuccess) => urlSuccess);
export const getInvestmentSuccessSelector = createSelector(
  getInvestmentSuccess,
  (investmentSuccess) => investmentSuccess
);
