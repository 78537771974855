import React from 'react';
const UploaderSubmitted = () => {
  return (
    <div align="center">
      <h1>Process begin!</h1>
      <a href="/uploader">Back to uploader</a>
    </div>
  );
};
export default UploaderSubmitted;
