import { SET_FIRST_LOAD, SET_IMPERSONATED, SET_IMPERSONATED_USER_SUCCESS, CLEAR_STATE } from './actionTypes';

export const initialState = {
  isFirstLoad: true,
  impersonated: false,
  impersonatedUser: null,
};

export const ImpersonateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIRST_LOAD:
      return {
        ...state,
        isFirstLoad: action.isFirstLoad,
      };
    case SET_IMPERSONATED:
      return {
        ...state,
        impersonated: action.impersonated,
      };
    case SET_IMPERSONATED_USER_SUCCESS:
      return {
        ...state,
        impersonatedUser: action.impersonatedUser,
      };

    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
