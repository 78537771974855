import React from 'react';

// styles
import Container from '@mui/material/Container';

// iAngels utils
import FounderForm from './FounderForm';
import FounderHeader from './FounderHeader';

const Founder = (props) => {
  return (
    <Container
      style={{
        padding: 0,
        marginTop: 20,
        width: '100%',
      }}
    >
      <FounderHeader />
      <FounderForm />
    </Container>
  );
};

export default Founder;
