import store from '../store/index.js';
import _ from 'lodash';

const endsWith = (str, suffix) => {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
};

const isInternalUser = (user) => {
  return (
    user?.impersonated || //impersonate
    _.includes(user?.roles, 'previewer') || //previewers
    _.includes(user?.roles, 'admin') || //admin
    (user.email &&
      _.some(['iangels.co', 'iangels.com', 'icapital.io', '21mcapital.com'], function (domain) {
        return endsWith(user.email, domain);
      }))
  );
};

const isTestUser = (user) => {
  return (
    (user.email &&
      _.some(['mailinator.com'], function (domain) {
        return endsWith(user.email, domain);
      })) ||
    (user.name && user.name.match(new RegExp('\\btest\\b')) !== null)
  ); //test users
};

const isProductionEnv = () => {
  return process.env.REACT_APP_BUILD_ENV === 'production';
};

const bypass = (user) => {
  if (!isProductionEnv()) {
    return false;
  }

  return isInternalUser(user) || isTestUser(user);
};

const getVirtualPath = () => {
  const path = window.location.hash;
  const absUrl = window.location.href;

  return absUrl.substring(absUrl.indexOf(path));
};

export const init = () => {
  if (!isProductionEnv) {
    window.analytics.debug();
  }
  if (!window.Intercom) {
    return;
  }

  window.Intercom('update');

  window.Intercom('onActivatorClick', function () {
    track('Intercom got activated');
  });

  window.Intercom('onShow', function () {
    track('Intercom on Show');
  });

  window.Intercom('onHide', function () {
    track('Intercom on Hide');
  });
};

export const identify = (isUpdate) => {
  if (bypass()) {
    return;
  }
  const state = store.getState();
  const profile = state?.auth?.user;
  const userId = profile?.user_id;

  const superProps = {
    platform: 'iAngels',
    user_id: userId,
    email: profile?.email,
    email_verified: profile?.email_verified,
    name: profile?.user_metadata?.full_name,
    country: profile?.user_metadata?.country,
    city: profile?.user_metadata?.city,
    applications: profile?.applications,
    visits: profile?.visits,
    //provider: profile?['https://example.com/provider'],
    roles: profile?.app_metadata?.roles,
    owner: profile?.app_metadata?.account_owner,
    referrer: profile?.app_metadata?.referrer,
    accreditation: profile?.app_metadata?.level,
    icapital_accreditation: profile?.app_metadata?.icapital_level,
    date_registered: profile?.app_metadata?.date_registered,
    icapital_date_registered: profile?.app_metadata?.icapital_date_registered,
    investments: profile?.app_metadata?.investments,
    icapital_investments: profile?.app_metadata?.icapital_investments,
    has_portfolio: profile?.app_metadata?.has_portfolio,
    user_status: profile?.app_metadata?.user_status,
    icapital_user_status: profile?.app_metadata?.icapital_user_status,
    access_limitations: profile?.app_metadata?.access_limitations,
  };

  if (profile) {
    if (!isUpdate && profile?.visits === 1) {
      window.analytics.alias(userId);
      window.analytics.identify(superProps);
    } else {
      window.analytics.identify(userId, superProps);
    }
  }
};

export const pageView = (name, url, traits) => {
  if (!name) return;
  if (bypass()) {
    return;
  }
  var virtualUrl = getVirtualPath();
  window.analytics.page(
    name,
    _.extend(
      {
        url: url || virtualUrl,
      },
      traits
    )
  );
};

export const track = (event, data = {}) => {
  const state = store.getState();
  const user = state?.auth?.user;

  const _data = _.extend({}, data, {
    url: window.location.hash,
    user_email: user?.email,
    authenticated: user !== null,
    registered: true,
  });
  window.analytics.track(event, _data);
};

export const time = (event) => {
  window.mixpanel && window.mixpanel.time_event(event);
};

export const isValideReport = () => {
  return isProductionEnv() && !isInternalUser() && !isTestUser();
};
