import { createSelector } from 'reselect';

const getSubmitFormSuccess = (state) => state.founderPage.submitFormRes;
const getSubmitFormAttempting = (state) => state.founderPage.attempting;
const getSubmitFormError = (state) => state.founderPage.errorCode;

export const getSubmitFormSuccessSelector = createSelector(getSubmitFormSuccess, (submitFormRes) => submitFormRes);
export const getSubmitFormAttemptingSelector = createSelector(getSubmitFormAttempting, (attempting) => attempting);
export const getSubmitFormErrorSelector = createSelector(getSubmitFormError, (errorCode) => errorCode);

const getSectorsSuccess = (state) => state.founderPage.sectors;
const getSectorsAttempting = (state) => state.founderPage.attempting;
const getSectorsError = (state) => state.founderPage.errorCode;

export const getSectorsSelector = createSelector(getSectorsSuccess, (sectors) => sectors);
export const getSectorsAttemptingSelector = createSelector(getSectorsAttempting, (attempting) => attempting);
export const getSectorsErrorSelector = createSelector(getSectorsError, (errorCode) => errorCode);

const getPrimarySectorsSuccess = (state) => state.founderPage.primarySectors;
const getPrimarySectorsAttempting = (state) => state.founderPage.attempting;
const getPrimarySectorsError = (state) => state.founderPage.errorCode;

export const getPrimarySectorsSelector = createSelector(getPrimarySectorsSuccess, (primarySectors) => primarySectors);
export const getPrimarySectorsAttemptingSelector = createSelector(
  getPrimarySectorsAttempting,
  (attempting) => attempting
);
export const getPrimarySectorsErrorSelector = createSelector(getPrimarySectorsError, (errorCode) => errorCode);
