import React, { useEffect, useRef } from 'react';

// redux components and actions and selectors
import { getAttemptingDashboardSelector, getDashboardSelector, getLastFetchTimeSelector } from './selectors';
import { useSelector, useDispatch } from 'react-redux';
import { getUserSelector } from '../App/selectors';
import { getDashboardAttempt } from './actions';

// components and containers
import InvestmentOpportunitiesPage from '../InvestmentOpportunities/InvestmentOpportunitiesPage';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import Portfolio from './Portfolio';

const ONE_MINUTE = 60000;

const Dashboard = (props) => {
  const dispatch = useDispatch();

  const dashboardLoading = useSelector(getAttemptingDashboardSelector);
  const lastFetchTime = useSelector(getLastFetchTimeSelector);
  const dashboard = useSelector(getDashboardSelector);

  const lastFetchTimeRef = useRef(lastFetchTime);
  const dashboardRef = useRef(dashboard);

  const user = useSelector(getUserSelector);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - lastFetchTimeRef.current;

    if (user && (!dashboardRef.current || timeElapsed > ONE_MINUTE)) {
      dispatch(getDashboardAttempt());
    }
  }, [user, dispatch]);

  return (
    <>
      {dashboardLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Portfolio />
          <InvestmentOpportunitiesPage />
          {user && (
            <div className="g4-c f-w-400 f-f-gl f-size-18 mt-40 mb-50">
              To see iAngels portfolio companies, <a href="/startups">click here</a>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
