import React from 'react';
const FounderSubmitted = () => {
  return (
    <div align="center">
      <h1>Thank you for submitting your application to iAngels</h1>
      <h4>A member of our investment team will reach out accordingly</h4>
    </div>
  );
};
export default FounderSubmitted;
