import {
  IDENTITY_REGISTER_ATTEMPT,
  IDENTITY_REGISTER_FAILURE,
  IDENTITY_REGISTER_SUCCESS,
  CLEAR_SIGNUP_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  errorCode: null,
  attempting: false,
  registerSuccess: null,
};

export const IdentityRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case IDENTITY_REGISTER_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        registerSuccess: null,
      };

    case IDENTITY_REGISTER_FAILURE:
      return {
        ...state,
        attempting: false,
        registerSuccess: null,
        errorCode: action.errorCode,
      };
    case IDENTITY_REGISTER_SUCCESS:
      return {
        ...state,
        attempting: false,
        registerSuccess: action.registerSuccess,
        errorCode: null,
      };
    case CLEAR_SIGNUP_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
