import { createSelector } from 'reselect';

const getIdentityRegisterAttempting = (state) => state.identityRegister.attempting;
const getIdentityRegisterSuccess = (state) => state.identityRegister.registerSuccess;
const getError = (state) => state.identityRegister.errorCode;

export const getIdentityRegisterAttemptingSelector = createSelector(
  getIdentityRegisterAttempting,
  (attempting) => attempting
);

export const getIdentityRegisterSuccessSelector = createSelector(
  getIdentityRegisterSuccess,
  (registerSuccess) => registerSuccess
);

export const getErrorSelector = createSelector(getError, (errorCode) => errorCode);
