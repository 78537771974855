import React, { useState } from 'react';


// mui utils
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { getCommaSeparatedString } from '../../helpers/iangels';
import IsMobileDevice from '../Generic/IsMobileDevice';


const RolloverInfo = ({ rollovers }) => {
  const isMobile = IsMobileDevice();
  let totalAmountForEach = 0;
  rollovers.forEach((item) => {
    totalAmountForEach += item.amount;
  });
  const some = rollovers.length > 1;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const CellTotalAmount = () => {
  return (<TableCell className="c-border-bottom">
            <span className="f-f-gl f-size-12 lh-16 f-w-700 ">${getCommaSeparatedString(Math.round(totalAmountForEach))}</span>
          </TableCell>)
  }

  const open = Boolean(anchorEl);
  return (
    <>
     <div className="toPdf">
         <br className="hidden" />
      </div>
    <Table
      className="rollover-info-more"
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
     
      <TableBody>
        <TableRow>
          {isMobile ? null : <TableCell align="center" className="c-border-bottom">
            <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%" className="p-5">
              <CurrencyExchangeIcon className="g4-c  f-size-14" />
            </Box>
          </TableCell>}
          <TableCell className="c-border-bottom">
            <span className="f-f-g f-size-12 f-w-700 lh-16 g4-c bold">Reinvested: </span>
          </TableCell>
          {some && !isMobile ? <TableCell className="c-border-bottom"></TableCell> : null}
          {!isMobile ? CellTotalAmount() : null}
        </TableRow>
        {isMobile ? <TableRow>{CellTotalAmount()}</TableRow> : null}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
         
        >
          
          <TableBody className="rollover-info-more">
            {rollovers.map((rollover, rowIndex) => (
              <TableRow key={rowIndex}>
              
                <TableCell className="c-border-bottom lh-0-6 pt-10 pb-5">
                  {rowIndex === 0 ? (
                    <span className="f-f-gl f-size-12 f-w-700">{rollover.from ? 'From' : 'To'}:</span>
                  ) : (
                    <span />
                  )}
                </TableCell>
                <TableCell className="c-border-bottom lh-0-6 pt-10 pb-5">
                  <span className="f-f-g f-size-12 f-w-700  g3-c bold underline ">
                    {rollover.startupRolloveredName}
                  </span>
                </TableCell>
                <TableCell className="c-border-bottom lh-0-6 pt-10 pb-5">
                  {some ? <span className="f-f-gl f-size-12 f-w-700 ">$ {getCommaSeparatedString(Math.round(rollover.amount))}</span> : null}
                </TableCell>
              </TableRow>
            ))
          }
          </TableBody>
        </Popover>
      </TableBody>
    </Table>
    </>
  );
};

export default RolloverInfo;
