import React from 'react';

// iangels utils
import { useNavigate } from 'react-router-dom';
import Text from './text';
import { track } from '../services/analytics';

// material ui utils
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';

const SignNda = ({ slug, isNdaModalShown, handleClose, isMobile }) => {
  const navigate = useNavigate();

  return (
    <Slide direction="up" in={isNdaModalShown} mountOnEnter unmountOnExit>
      <div className={`sign-nda ${isMobile ? ' sign-nda-mobile' : ''}`}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton aria-label="close" size="large" onClick={handleClose}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Text
            text="To access all relevant financials and projections, please click here to sign an NDA"
            classes="f-f-g w-c"
            style={{ margin: isMobile ? 0 : 20, textAlign: 'center', lineHeight: isMobile ? 1.3 : 1.7 }}
          />
        </div>
        <Button
          variant="contained"
          className="btn-sign-nda f-f-g"
          startIcon={<ModeEditOutlineIcon />}
          onClick={() => {
            track('NDA Section Button Click', { category: 'Startup', startup: slug });
            navigate(`/startup/${slug}/nda`);
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Sign here</span>
        </Button>
      </div>
    </Slide>
  );
};

export default SignNda;
