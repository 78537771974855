import { useEffect } from 'react';

// third party utils
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// iangels utils
import { getIdentifyUserFailureSelector, getUserSelector ,getAuthResultSelector } from '../App/selectors';
import { identifyUserAttempt, setAuthResult, clearState } from '../App/actions';
import { getFirstLoadSelector, getIsImpersonatedSelector } from './selectors';
import { setFirstLoad,setImpersonated, setImpersonatedUserSuccess} from './actions';

const Impersonate = () => {
  // hooks
  
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // selectors
  const error = useSelector(getIdentifyUserFailureSelector);
  const user = useSelector(getUserSelector);
  const isFirstLoad = useSelector(getFirstLoadSelector);
  const isImpersonated = useSelector(getIsImpersonatedSelector);
  const authResult=useSelector(getAuthResultSelector);
 
  const parseJwt = (token) => {
  // Split the JWT string into three parts
  const base64Url = token.split('.')[1]; // Get the payload part
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert base64url to base64
  const jsonPayload = decodeURIComponent(
    window.atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  ); // Decode base64 and escape characters to get the JSON
  return JSON.parse(jsonPayload); // Parse the JSON string and return the object
};

  useEffect(() => {
    if (isFirstLoad) {
      dispatch(clearState());
      dispatch(setFirstLoad(false)); // Set firstLoad to false after clearing state
     }
    }, [dispatch,isFirstLoad]);

  useEffect(() => {
    if (!authResult ) {
      const params = {};
      searchParams.forEach((value, key) => {
        params[key === 'id_token' ? '__raw' : key === 'access_token' ? 'accessToken' : key] = value;
      });
      if (params.__raw && params.accessToken) {
        dispatch(setImpersonated(true));
        dispatch(setAuthResult(params));
        const imperUser = parseJwt(params.__raw);
        console.log('imperUser', imperUser);
        dispatch(setImpersonatedUserSuccess(imperUser));
        dispatch(identifyUserAttempt());

      }
    }
  }, [searchParams, dispatch, authResult]);

  useEffect(() => {
    if (user && isImpersonated && !isFirstLoad) {
     navigate('/dashboard');
     dispatch(setFirstLoad(true));
    
    }
  }, [navigate, user, isFirstLoad ,dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearState());
      navigate('/');
    }
  }, [dispatch, error, navigate]);

  return null;
};

export default Impersonate;
