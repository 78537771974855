// third party lib
import { call, all, put, takeLatest } from 'redux-saga/effects';

// iangels lib
import { SUBMIT_FORM_ATTEMPT, GET_SECTORS_ATTEMPT, GET_PRIMARY_SECTORS_ATTEMPT } from './actionTypes';
import { getDataFromService, postData } from '../../services/apiGateway';
import {
  submitFormSuccess,
  submitFormFailure,
  getSectorsSuccess,
  getSectorsFailure,
  getPrimarySectorsSuccess,
  getPrimarySectorsFailure,
} from './actions';

function* submitFormAttempt(action) {
  try {
    const submitFormRes = yield call(postData, `/iangels/startups/lead`, action.data, true);
    yield put(submitFormSuccess(submitFormRes?.data));
  } catch (errorCode) {
    yield put(submitFormFailure(errorCode.response || errorCode));
  }
}

function* getSectorsAttempt() {
  try {
    const sectors = yield call(getDataFromService, `/iangels/startups/sectors`);
    yield put(getSectorsSuccess(sectors?.data));
  } catch (errorCode) {
    yield put(getSectorsFailure(errorCode.response || errorCode));
  }
}

function* getPrimarySectorsAttempt() {
  try {
    const primarySectors = yield call(getDataFromService, `/iangels/startups/primary-sectors`);
    yield put(getPrimarySectorsSuccess(primarySectors?.data));
  } catch (errorCode) {
    yield put(getPrimarySectorsFailure(errorCode.response || errorCode));
  }
}

function* founderFormSaga() {
  yield all([takeLatest(SUBMIT_FORM_ATTEMPT, submitFormAttempt)]);
  yield all([takeLatest(GET_SECTORS_ATTEMPT, getSectorsAttempt)]);
  yield all([takeLatest(GET_PRIMARY_SECTORS_ATTEMPT, getPrimarySectorsAttempt)]);
}

export default founderFormSaga;
