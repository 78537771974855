import React from 'react';
import { useNavigate } from 'react-router-dom';

// mui utils
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

// iangels utils
import { getSquareFace } from '../../helpers/cloudinary';
import { openInNewTab } from '../../helpers/iangels';
import MobileMenuItem from '../Menu/MobileMenuItem';
import { loadConfig } from '../../env/config_util';

const MobileListMenu = ({
  anchor,
  user,
  investmentItems,
  aboutUsItems,
  userSettingsItems,
  handleLogOut,
  toggleDrawer,
  setAnchorElUser,
  anchorElUser,
  handleCloseUserMenu,
}) => {
  const config = loadConfig();
  const navigate = useNavigate();
  const handleProfile = () => {
    navigate('/profile');
    setAnchorElUser(false);
  };
  const marketing_website_base_url = config.config_module.marketing_website_base_url;
  return (
    <Drawer anchor={anchor} open={anchorElUser} onClose={handleCloseUserMenu}>
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 270 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        {user ? (
          <List style={{ marginTop: 10 }}>
            {user?.user_metadata?.full_name || user?.name ? (
              <ListItem onClick={() => handleProfile()}>
                <ListItemIcon>
                  <Avatar alt={user?.name} src={getSquareFace(user?.user_metadata?.avatar, 32) || user?.picture} />
                </ListItemIcon>
                <ListItemText
                  primary={<span className="table-company-label">{user?.user_metadata?.full_name || user?.name}</span>}
                />
              </ListItem>
            ) : (
              <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress className="g-c" />
              </ListItem>
            )}
          </List>
        ) : null}
        <Divider />
        <MobileMenuItem title={'Invest'} sectionList={investmentItems} setAnchorElUser={setAnchorElUser} />
        {user ? null : (
          <>
            <Divider />
            <MobileMenuItem
              title="Investors"
              onClick={() => openInNewTab(marketing_website_base_url + 'investors')}
              setAnchorElUser={setAnchorElUser}
            />
            <Divider />
            <MobileMenuItem
              title="Entrepreneurs"
              onClick={() => openInNewTab(marketing_website_base_url + 'entrepreneurs')}
              setAnchorElUser={setAnchorElUser}
            />
            <Divider />
          </>
        )}

        <MobileMenuItem title={'About Us'} sectionList={aboutUsItems} setAnchorElUser={setAnchorElUser} />

        {user ? (
          <MobileMenuItem title={'Settings'} sectionList={userSettingsItems} setAnchorElUser={setAnchorElUser} />
        ) : (
          <>
            <Divider />
            <MobileMenuItem
              title="Knowledge Center"
              onClick={() => openInNewTab(marketing_website_base_url + 'knowledge-center/')}
              setAnchorElUser={setAnchorElUser}
            />
            <Divider />
            <MobileMenuItem
              title="Blog"
              onClick={() => openInNewTab(marketing_website_base_url + 'blog/')}
              setAnchorElUser={setAnchorElUser}
            />
          </>
        )}
        <Divider />
        <List>
          <ListItem button key={'log-out'} onClick={handleLogOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={<span className="f-gl-400 f-size-14">Log Out</span>} />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default MobileListMenu;
