import {
  RESET_PASSWORD_CLEAR_STATE,
  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  status: null,
  errorCode: null,
  attempting: false,
  email: '',
};

export const ResetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        status: null,
        email: action.email,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        attempting: false,
        errorCode: null,
        status: action.status,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        attempting: false,
        status: null,
        errorCode: action.errorCode,
      };
    case RESET_PASSWORD_CLEAR_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
