import { updateEmailVerificationSuccess, updateEmailVerificationFailure } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { postData } from '../../services/apiGateway';
import { UPDATE_EMAIL_VERIFICATION_ATTEMPT } from './actionTypes';

function* updateEmailVerification(action) {
  try {
    const updateStatus = yield call(
      postData,
      '/iangels/users/update/email-verification',
      {
        email: action.email,
        update_auth0: action.update_auth0,
      },
      true
    );
    yield put(updateEmailVerificationSuccess(updateStatus?.data));
  } catch (errorCode) {
    console.log(errorCode.response || errorCode);
    yield put(updateEmailVerificationFailure(errorCode.response || errorCode));
  }
}

function* emailVerificationSaga() {
  yield all([takeLatest(UPDATE_EMAIL_VERIFICATION_ATTEMPT, updateEmailVerification)]);
}

export default emailVerificationSaga;
