import { call, all, put, takeLatest } from 'redux-saga/effects';
import { GET_ROUNDS_ATTEMPT, SUBMIT_ATTEMPT } from './actionTypes';
import { getRoundsSuccess, getRoundsFailure, submitSuccess, submitFailure } from './actions';
import { getDataFromService, postData } from '../../services/apiGateway';

function* getRoundsAttempt() {
  try {
    const rounds = yield call(getDataFromService, `/iangels/startups/rounds`);
    yield put(getRoundsSuccess(rounds?.data));
  } catch (errorCode) {
    yield put(getRoundsFailure(errorCode.response || errorCode));
  }
}

function* submitAttempt(action) {
  try {
    let data = new FormData();
    Object.keys(action.data.form).map((key) => data.append(key, action.data.form[key]));
    const submitRes = yield call(postData, `/images/uploader`, data);
    yield put(submitSuccess(submitRes?.data));
  } catch (errorCode) {
    yield put(submitFailure(errorCode.response || errorCode));
  }
}

function* documentsUploaderSaga() {
  yield all([takeLatest(GET_ROUNDS_ATTEMPT, getRoundsAttempt)]);
  yield all([takeLatest(SUBMIT_ATTEMPT, submitAttempt)]);
}

export default documentsUploaderSaga;
