import React, { useEffect, useState } from 'react';

// mui utils
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { IMaskInput } from 'react-imask';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';

// third party lib
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

//components and json
import { clearErrorAndSuccessState, getIdentityDetailsAttempt, identityUpdateAttempt, setAvatar } from './actions';
import { getAuthResultSelector, getProviderSelector, getUserSelector } from '../App/selectors';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import { getCircleFace } from '../../helpers/cloudinary';
import useIsMounted from '../../components/IsMounted';
import { uploadFile } from '../../helpers/iangels';
import { setForceUserInfo } from '../App/actions';
import Text from '../../components/text';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

import { TEXT_MAX_LENGTH, EMAIL_MAX_LENGTH, FILES_TYPES, MOBILE_WIDTH_499, PROVIDERS } from '../../helpers/constants';

import {
  getAvatarSelector,
  getErrorSelector,
  getIdentityDetailsAttemptingSelector,
  getIdentityDetailsSelector,
  getUpdateSuccessSelector,
} from './selectors';

// json files
import countries from '../../components/SharedData/countries.json';
import states from '../../components/SharedData/states.json';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="+ 00000000000000000000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const MyProfile = () => {
  // hooks
  const matches_499 = useMediaQuery(MOBILE_WIDTH_499);
  const isMobile = IsMobileDevice();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // selectors
  const loading = useSelector(getIdentityDetailsAttemptingSelector);
  const updateSuccess = useSelector(getUpdateSuccessSelector);
  const authResult = useSelector(getAuthResultSelector);
  const user = useSelector(getIdentityDetailsSelector);
  const provider = useSelector(getProviderSelector);
  const userMetaData = useSelector(getUserSelector);
  const avatar = useSelector(getAvatarSelector);
  const error = useSelector(getErrorSelector);

  const isEmailReadonly = provider !== PROVIDERS.AUTH0;

  // state
  const [fullNameError, setFullNameError] = useState('');
  const [linkedInError, setLinkedInError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [cityError, setCityError] = useState('');

  // avatar state
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState(null);

  // fields state
  const [profileFieldsValues, setProfileFieldsValues] = useState({
    address: '',
    city: '',
    countryCode: '',
    email: '',
    fullName: '',
    linkedin: '',
    phone: '',
    stateCode: '',
    country: '',
    state: '',
  });

  useEffect(() => {
    if (updateSuccess) {
      dispatch(clearErrorAndSuccessState());
      navigate('/dashboard');
    }
  }, [updateSuccess, navigate, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearErrorAndSuccessState());
      navigate('/error');
    }
  }, [error, navigate, dispatch]);

  const handleSave = () => {
    const fullNameValid = isFullNameValid();
    const emailValid = isEmailValid();
    const phoneValid = isPhoneValid();
    const cityValid = isCityValid();
    const linkedinValid = isLinkedInValid();
    const addressValid = isAddressValid();

    if (!fullNameValid || !emailValid || !phoneValid || !cityValid || !linkedinValid || !addressValid) {
      return;
    }

    // deep copy
    let data = JSON.parse(JSON.stringify(profileFieldsValues));
    data.upaProvider = !(provider !== PROVIDERS.AUTH0);
    data.avatar = avatar || userMetaData?.user_metadata?.avatar;
    data.countryCode = profileFieldsValues.countryCode ? profileFieldsValues.countryCode : '';
    data.country = profileFieldsValues.countryCode ? profileFieldsValues.country.name : null;
    data.address = profileFieldsValues.address;
    data.linkedin = profileFieldsValues.linkedin;
    data.phone = profileFieldsValues.phone.replace(/\s/g, '');
    data.stateCode =
      profileFieldsValues.stateCode && profileFieldsValues.stateCode !== '' ? profileFieldsValues.stateCode : null;

    delete data.state;

    dispatch(identityUpdateAttempt(data));
    dispatch(setForceUserInfo(true));
  };

  const handleChange = (event) => {
    setProfileFieldsValues({
      ...profileFieldsValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleCountryChange = (event) => {
    setProfileFieldsValues({
      ...profileFieldsValues,
      country: event.target.value,
      countryCode: event.target.value.code || null,
      stateCode: null,
    });
  };

  const handleStateChange = (event) => {
    setProfileFieldsValues({
      ...profileFieldsValues,
      state: event.target.value,
      stateCode: event.target.value.abbreviation,
    });
  };

  const handleFileChange = (file) => {
    setFileLoading(true);
    setFileError(null);

    if (file?.size > 2000000) {
      setFileError('File size is too large, max size is 2 mb');
      setFileLoading(false);

      return;
    }

    uploadFile(
      file,
      authResult.__raw,
      (response) => {
        if (isMounted()) {
          dispatch(setAvatar(response.data.imageId));
          setFileLoading(false);
        }
      },
      (error) => {
        if (isMounted()) {
          setFileError('Error uploading picture, please try again later');
          setFileLoading(false);
        }
      }
    );
  };

  const hasState = profileFieldsValues.country?.code === 'US';

  useEffect(() => {
    if (user) {
      let currUser = {
        address: user.address || '',
        city: user.city || '',
        countryCode: user.countryCode || '',
        email: user.email || '',
        fullName: user.fullName || '',
        linkedin: user.linkedin || '',
        phone: user.phone || '',
        stateCode: user.stateCode || '',
        country: _.find(countries, { code: user.countryCode?.toUpperCase() }) || '',
        state: user.stateCode ? _.find(states, { abbreviation: user.stateCode.toUpperCase() }) || '' : '',
      };

      setProfileFieldsValues(currUser);
    }
  }, [user]);

  useEffect(() => {
    if (userMetaData) {
      dispatch(getIdentityDetailsAttempt());
    }
  }, [dispatch, userMetaData]);

  const handleMyTeamClick = () => {
    window.location.href = 'mailto:myteam@iangels.com';
  };

  const isCityValid = () => {
    if (profileFieldsValues.city.length > EMAIL_MAX_LENGTH) {
      setCityError('City field is too long.');
      return false;
    }

    setCityError('');
    return true;
  };

  const isLinkedInValid = () => {
    if (profileFieldsValues.linkedin.length > TEXT_MAX_LENGTH) {
      setLinkedInError('LinkedIn field is too long.');
      return false;
    }

    setLinkedInError('');
    return true;
  };

  const isAddressValid = () => {
    if (profileFieldsValues.address.length > TEXT_MAX_LENGTH) {
      setAddressError('Address field is too long.');
      return false;
    }

    setAddressError('');
    return true;
  };

  const isFullNameValid = () => {
    if (profileFieldsValues.fullName.length === 0) {
      setFullNameError('Full name is required.');
      return false;
    }

    if (profileFieldsValues.fullName.trim().indexOf(' ') === -1) {
      setFullNameError('Please enter your first and last name.');
      return false;
    }

    if (profileFieldsValues.fullName.trim().length > EMAIL_MAX_LENGTH) {
      setFullNameError('Name is too long');
      return false;
    }

    setFullNameError('');
    return true;
  };

  const isPhoneValid = () => {
    if (profileFieldsValues.phone.length === 0) {
      setPhoneError('Phone number is required.');
      return false;
    }

    if (profileFieldsValues.phone.trim().length < 7) {
      setPhoneError('Phone number is too short');
      return false;
    }

    setPhoneError('');
    return true;
  };

  const isEmailValid = () => {
    if (profileFieldsValues.email.length === 0) {
      setEmailError('Email is required.');
      return false;
    }

    const lastAtPos = profileFieldsValues.email.lastIndexOf('@');
    const lastDotPos = profileFieldsValues.email.lastIndexOf('.');

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        profileFieldsValues.email.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        profileFieldsValues.email.length - lastDotPos > 2
      )
    ) {
      setEmailError('Email is not valid.');
      return false;
    }

    if (profileFieldsValues.email.trim().length > EMAIL_MAX_LENGTH) {
      setEmailError('Email is too long');
      return false;
    }

    setEmailError('');
    return true;
  };

  return (
    <div>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Container
          style={{
            padding: 0,
            marginTop: 40,
            width: '100%',
          }}
          maxWidth="sm"
        >
          <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
            <FileUploader
              classes="drop_zone"
              handleChange={handleFileChange}
              name="file"
              types={FILES_TYPES}
              onTypeError={() => setFileError(true)}
            >
              {fileLoading ? (
                <Stack className="primary-c" spacing={2} alignItems="center" justifyContent="center">
                  <CircularProgress className="g-c" />
                </Stack>
              ) : (
                <div className="df-c fdr-c">
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <EditIcon
                        sx={{
                          color: '#4cb9ba',
                          backgroundColor: 'white',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          border: '1px solid #4cb9ba',
                        }}
                      />
                    }
                  >
                    <Avatar
                      alt={user?.fullName}
                      src={
                        avatar
                          ? getCircleFace(avatar, 70)
                          : userMetaData?.user_metadata?.avatar
                          ? getCircleFace(userMetaData.user_metadata.avatar, 70)
                          : null
                      }
                      sx={{ width: 65, height: 65 }}
                    />
                  </Badge>
                  {fileError ? <Text text={fileError} classes="mt-10 r-c" /> : null}
                </div>
              )}
            </FileUploader>
            <Typography gutterBottom variant="h5" component="div" className="f-f-gl f-w-700">
              My Profile
            </Typography>
          </Stack>
          <div style={{ width: '100%' }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              autoComplete="off"
              style={{ width: ' -webkit-fill-available' }}
            >
              <TextField
                required
                error={fullNameError !== ''}
                id="full-name"
                label="Full Name"
                variant="standard"
                style={{ width: 'inherit' }}
                className="text-field"
                value={profileFieldsValues.fullName}
                name="fullName"
                onChange={handleChange}
                helperText={fullNameError}
              />
              <Stack width={'inherit'} direction={isMobile ? 'column' : 'row'}>
                <TextField
                  required
                  error={emailError !== ''}
                  id="email"
                  label="Email"
                  variant="standard"
                  style={{ width: 'inherit' }}
                  className="text-field"
                  value={profileFieldsValues.email}
                  name="email"
                  onChange={handleChange}
                  disabled={isEmailReadonly}
                  helperText={
                    isEmailReadonly
                      ? 'You signed up with social connection. To change, please contact myteam@iangels.com'
                      : emailError
                  }
                  FormHelperTextProps={{
                    className: 'gray-700',
                  }}
                />
                <FormControl
                  error={phoneError !== ''}
                  variant="standard"
                  style={{ width: 'inherit', margin: 8 }}
                  className="text-field"
                >
                  <InputLabel htmlFor="formatted-text-mask-input">Phone*</InputLabel>
                  <Input
                    value={profileFieldsValues.phone}
                    onChange={handleChange}
                    name="phone"
                    id="formatted-text-mask-input"
                    inputComponent={TextMaskCustom}
                  />
                  <FormHelperText>{phoneError}</FormHelperText>
                </FormControl>
              </Stack>
              <Stack width={'inherit'} direction={isMobile ? 'column' : 'row'}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  style={{ width: 'inherit' }}
                  className="text-field"
                >
                  <InputLabel id="country-select-standard-label">Country</InputLabel>
                  <Select
                    labelId="country-select-standard-label"
                    id="country-select"
                    value={profileFieldsValues.country}
                    onChange={handleCountryChange}
                    name="country"
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.name} value={country}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="city"
                  error={cityError !== ''}
                  label="City"
                  variant="standard"
                  style={{ width: 'inherit' }}
                  className="text-field"
                  name="city"
                  value={profileFieldsValues.city}
                  helperText={cityError}
                  onChange={handleChange}
                />
              </Stack>
              <Stack width={'inherit'} direction={isMobile ? 'column' : 'row'}>
                {hasState ? (
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120 }}
                    style={{ width: 'inherit' }}
                    className="text-field"
                  >
                    <InputLabel id="state-select-standard-label">State</InputLabel>
                    <Select
                      labelId="state-select-standard-label"
                      id="state-select"
                      value={profileFieldsValues.state}
                      onChange={handleStateChange}
                      name="state"
                    >
                      {states.map((state) => (
                        <MenuItem key={state.name} value={state}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null}

                <TextField
                  id="address"
                  label="Address"
                  error={addressError !== ''}
                  variant="standard"
                  style={{ width: 'inherit' }}
                  className="text-field"
                  name="address"
                  value={profileFieldsValues.address}
                  onChange={handleChange}
                  helperText={addressError}
                />
              </Stack>
              <TextField
                id="linkedin"
                error={linkedInError !== ''}
                label="Linkedin"
                variant="standard"
                style={{ width: 'inherit' }}
                className="text-field"
                name="linkedin"
                value={profileFieldsValues.linkedin}
                onChange={handleChange}
                helperText={linkedInError}
              />
            </Box>
          </div>

          <Stack width={'inherit'} direction={matches_499 ? 'column' : 'row'} alignItems="center">
            <Text
              classes="f-gl-400 gray-600"
              style={{ marginTop: 20, marginBottom: 30, marginLeft: 5 }}
              variant="subtitle1"
              text="To contact your portfolio managers, please email "
              extraText="myteam@iangels.com"
              handleClick={handleMyTeamClick}
              extraClasses="g-c bold pointer"
            />
            <Button
              variant="contained"
              className="btn form-btn"
              style={
                matches_499
                  ? {
                      marginTop: 20,
                      minHeight: 40,
                      borderRadius: 0,
                      minWidth: 180,
                    }
                  : { marginLeft: 10, minWidth: 120, minHeight: 40, borderRadius: 0, marginRight: 10 }
              }
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </Container>
      )}
    </div>
  );
};

export default MyProfile;
