import {
  CLEAN_IDENTITY_ERROR_AND_SUCCESS,
  GET_IDENTITY_DETAILS_ATTEMPT,
  GET_IDENTITY_DETAILS_SUCCESS,
  GET_IDENTITY_DETAILS_FAILURE,
  IDENTITY_UPDATE_ATTEMPT,
  IDENTITY_UPDATE_FAILURE,
  IDENTITY_UPDATE_SUCCESS,
  CLEAR_STATE,
  SET_AVATAR,
} from './actionTypes';

export const initialState = {
  identityDetails: null,
  errorCode: null,
  attempting: false,
  updateSuccess: null,
  avatar: null,
};

export const IdentityDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IDENTITY_DETAILS_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        identityDetails: null,
        updateSuccess: null,
      };
    case IDENTITY_UPDATE_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        updateSuccess: null,
      };
    case GET_IDENTITY_DETAILS_SUCCESS:
      return {
        ...state,
        attempting: false,
        errorCode: null,
        identityDetails: action.identityDetails,
      };
    case IDENTITY_UPDATE_SUCCESS:
      return {
        ...state,
        attempting: false,
        errorCode: null,
        updateSuccess: action.updateSuccess,
      };
    case GET_IDENTITY_DETAILS_FAILURE:
      return {
        ...state,
        attempting: false,
        identityDetails: null,
        errorCode: action.errorCode,
      };
    case IDENTITY_UPDATE_FAILURE:
      return {
        ...state,
        attempting: false,
        updateSuccess: null,
        errorCode: action.errorCode,
      };
    case CLEAN_IDENTITY_ERROR_AND_SUCCESS:
      return {
        ...state,
        attempting: false,
        updateSuccess: null,
        errorCode: null,
      };
    case SET_AVATAR:
      return {
        ...state,
        avatar: action.avatar,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
