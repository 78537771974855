import React from 'react';

const EntityInfo = ({ entity }) => {
  return (
    <>
      <br />
      <span className="g-c f-size-12">KYC:</span>
      <br />
      <span className="f-size-12 underline">{entity}</span>
    </>
  );
};

export default EntityInfo;
