// material ui utils
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// iangels utils
import LoadingIndicator from '../Generic/LoadingIndicator';
import Text from '../text';

const InvestEntitySelectionMobile = ({
  params,
  handleEntityChange,
  handleNext,
  isDisabled,
  loading,
  entity,
  setEntity,
  entities,
}) => {
  return (
    <Stack style={{ marginTop: 60, minHeight: 800 }} spacing={2} direction="column">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Text
            variant="h5"
            classes="f-tgf-400 kyc-title"
            style={{ marginLeft: '7%', marginTop: 0 }}
            text="Investing Entity"
          />

          <Stack
            style={{
              margin: 25,
              marginTop: 0,
              marginLeft: '7%',
              width: '100%',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
            spacing={2}
          >
            <Text classes={'bold g4-c f-f-tgf f-w-500 mt-19 f-size-09'} text="Select/Create investing entity name" />
            <div style={{ minWidth: '52%' }}>
              <Autocomplete
                id="autocomplete-invest"
                options={entities ? entities : []}
                getOptionLabel={(option) => option.name}
                disableClearable
                freeSolo
                className="f-tgf-400"
                onChange={(event, selectedName) => {
                  setEntity(selectedName);
                }}
                renderInput={(renderParams) => (
                  <TextField
                    {...renderParams}
                    id="entity"
                    style={{ width: '85%', margin: 0, marginTop: '2%' }}
                    variant="outlined"
                    className="text-field"
                    label="KYC Name"
                    value={entity}
                    onChange={handleEntityChange}
                    name="entity"
                  />
                )}
              />
            </div>
            <Button
              variant="contained"
              className={isDisabled() ? '' : 'entities-next-btn'}
              style={{
                width: '75%',
                float: 'right',
                marginLeft: '5%',
                marginTop: '80%',
                borderRadius: 8,
              }}
              onClick={handleNext}
              disabled={isDisabled()}
            >
              <span className="bold f-size-16" style={{ letterSpacing: 1 }}>
                Next
              </span>
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default InvestEntitySelectionMobile;
