import { Base64 } from './base64';
import { Nonce } from './nonce';
import { loadConfig } from '../env/config_util';

const config = loadConfig();

export class iAngelsState {
  constructor() {
    this.base64 = new Base64();
  }

  getState(data) {
    var nonce = new Nonce();

    var _state = {
      roles: data?.roles || [],
      applications: data?.applications || [],
      source: 'site',
      siteUrl: config.config_module.redirectUrl,
      nonce: nonce.generator(),
    };

    // if (data.isFounder) {
    //   _.extend(_state, { skipDetails: true });
    // }
    // if (data.referrer) {
    //   _.extend(_state, { referrer: data.referrer });
    // }

    return this.base64.encode(JSON.stringify(_state));
  }

  getRoles(isFounder, additionalRole) {
    var roles = isFounder ? ['founder'] : ['investor'];

    if (additionalRole) {
      roles.push(additionalRole);
    }
    return roles;
  }
}
