const en_translate = {
  click_here: 'Click here',
  or: 'or',
  buttons: {
    iangels_login: 'Log In',
    iangels_signup: 'Sign Up',
    facebook_login: 'Login with Facebook',
    google_login: 'Login with Google',
    linkedin_login: 'Login with Linkedin',
    facebook_signup: 'Sign up with Facebook',
    google_signup: 'Sign up with Google',
    linkedin_signup: 'Sign up with Linkedin',
  },
  titles: {
    marketTitle: 'Market Place',
    login: 'Log in with your iAngels Account',
  },
  tabs: {
    login: 'Log In',
    signup: 'Sign Up',
  },
  questions: {
    forget_password: "Don't remember your password?",
  },
  terms_and_service: {
    agreement: 'By signing up, you agree to our terms of service and privacy policy.',
  },
  portfolio: {
    title: 'Your Portfolio',
    shared: {
      sharedWith: 'Shared with',
      sharedBy: 'Shared by',
    },
    activeRows: {
      fundsNotYetReceived: 'Funds not yet received',
    },
    pricingTerms: {
      several: 'Several',
      converted: 'Converted',
      discount: 'Discount',
      capUS: 'Cap US$',
      US: 'US$',
      pre: 'Pre',
    },
  },
  investmentOpportunities: {
    activelyManagedTitle: 'Actively Managed Offerings',
    activelyManagedSubtitle:
      "Invest in a diversified portfolio, managed by iAngels' portfolio team and gain priority access to oversubscribed deals and expert management support.",
    iangelsOpportunitiesSubtitle:
      'Invest in startups that have passed our investment committee and propriety due diligence process.',
    syndicateOpportunitiesTitle: 'Syndicate Opportunities',
    syndicateOpportunitiesSubtitle:
      'Invest with professional lead Angels, who conduct independent due diligence that iAngels has vetted and endorsed.',
  },
};
export default en_translate;
