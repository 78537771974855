export const GET_IDENTITY_DETAILS_ATTEMPT = 'GET_IDENTITY_DETAILS_ATTEMPT';
export const GET_IDENTITY_DETAILS_SUCCESS = 'GET_IDENTITY_DETAILS_SUCCESS';
export const GET_IDENTITY_DETAILS_FAILURE = 'GET_IDENTITY_DETAILS_FAILURE';

export const IDENTITY_UPDATE_ATTEMPT = 'IDENTITY_UPDATE_ATTEMPT';
export const IDENTITY_UPDATE_SUCCESS = 'IDENTITY_UPDATE_SUCCESS';
export const IDENTITY_UPDATE_FAILURE = 'IDENTITY_UPDATE_FAILURE';
export const SET_AVATAR = 'SET_AVATAR';

export const CLEAN_IDENTITY_ERROR_AND_SUCCESS = 'CLEAN_IDENTITY_ERROR_AND_SUCCESS';
export const CLEAR_STATE = 'CLEAR_STATE';
