import { select } from 'redux-saga/effects';
import { createHttpClient } from './http_request';
import { getAuthResultSelector, getUserSelector } from '../containers/App/selectors';

export function* postData(context, params, byPass = false) {
  const api = yield createHttpClient();
  const authResult = yield select(getAuthResultSelector);
  const user = yield select(getUserSelector);
  let userName = user?.user_metadata?.full_name || user?.name || authResult?.name;
  if (!/^[A-Za-z0-9@\-_ ()/."\\]*$/.test(userName)) userName = '';
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'User-Email': user?.email || authResult?.email,
    'User-Name': userName,
  };

  if (!byPass) {
    headers.Authorization = 'Bearer ' + authResult.__raw;
  }

  try {
    return yield api
      .post(context, params, {
        headers: headers,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        throw error;
      });
  } catch (error) {
    throw error;
  }
}

export function* getDataFromService(context = {}) {
  const authResult = yield select(getAuthResultSelector);
  const user = yield select(getUserSelector);
  let userName = user?.user_metadata?.full_name || user?.name || authResult?.name;
  if (!/^[A-Za-z0-9@\-_ ()/."\\]*$/.test(userName)) userName = '';
  try {
    const api = yield createHttpClient();

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Email': user?.email || authResult?.email,
      'User-Name': userName,
    };

    if (authResult) {
      headers.Authorization = 'Bearer ' + authResult.__raw;
    }

    return yield api
      .get(context, {
        headers: headers,
        //credentials: 'include',
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  } catch (err) {
    throw err;
  }
}
