import { createSelector } from 'reselect';

const getInvestmentDataAttempting = (state) => state.investmentData.attempting;
const getInvestmentDataSuccess = (state) => state.investmentData.investmentData;
const getInvestmentDataError = (state) => state.investmentData.errorCode;

export const getInvestmentDataAttemptingSelector = createSelector(
  getInvestmentDataAttempting,
  (attempting) => attempting
);

export const getInvestmentDataSuccessSelector = createSelector(
  getInvestmentDataSuccess,
  (investmentData) => investmentData
);

export const getInvestmentDataErrorSelector = createSelector(getInvestmentDataError, (errorCode) => errorCode);
