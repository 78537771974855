import { all, fork } from 'redux-saga/effects';

import accreditationSaga from '../containers/AccreditationForm/sagas';
import accreditationAffirmationSaga from '../containers/Affirmation/sagas';
import authSaga from '../containers/App/sagas';
import dashboardSaga from '../containers/Dashboard/sagas';
import documentsSaga from '../containers/Documents/sagas';
import startupPageSage from '../containers/StartupPage/sagas';
import kycStatusSaga from '../containers/KycStatus/sagas';
import kycSubmitSaga from '../containers/KycSubmitDocuments/sagas';
import identityDetailsSaga from '../containers/MyProfile/sagas';
import ndaPageSage from '../containers/Nda/sagas';
import resetPasswordSaga from '../containers/ResetPassword/sagas';
import identityRegisterSaga from '../containers/SignUpModel/sagas';
import startupsSaga from '../containers/Startups/sagas';
import investmentSaga from '../containers/Invest/sagas';
import investmentDataSaga from '../containers/Kyc/sagas';
import layoutSaga from '../containers/Layouts/MainLayout/sagas';
import emailVerificationSaga from '../containers/EmailVerification/sagas';
import founderFormSaga from '../containers/FounderPage/sagas';
import documentsUploaderSaga from '../containers/DocumentsUploader/sagas';
import syncSaga from '../containers/Sync/sagas';

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(dashboardSaga),
    fork(identityDetailsSaga),
    fork(resetPasswordSaga),
    fork(startupsSaga),
    fork(kycStatusSaga),
    fork(kycSubmitSaga),
    fork(documentsSaga),
    fork(startupPageSage),
    fork(ndaPageSage),
    fork(identityRegisterSaga),
    fork(accreditationSaga),
    fork(accreditationAffirmationSaga),
    fork(investmentSaga),
    fork(investmentDataSaga),
    fork(layoutSaga),
    fork(emailVerificationSaga),
    fork(founderFormSaga),
    fork(documentsUploaderSaga),
    fork(syncSaga)
  ]);
}
