import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionMobileSummary from './AccordionMobileSummary';

const MobileAccordion = (props) => {
  const {
    title,
    investmentSummary,
    accordionDetails,
    valueTitle,
    changeTitle,
    iconDisabled,
    accordionClass,
    textClass,
  } = props;

  return (
    <Accordion className={accordionClass} style={{ boxShadow: 'none' }}>
      <AccordionMobileSummary
        title={title}
        valueTitle={valueTitle}
        changeTitle={changeTitle}
        investmentSummary={investmentSummary}
        iconDisabled={iconDisabled}
        textClass={textClass ? textClass : ''}
      />
      {accordionDetails?.map((investment, index) => (
        <div key={index}>{investment}</div>
      ))}
    </Accordion>
  );
};

export default MobileAccordion;
