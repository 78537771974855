import React from 'react';
import parse from 'html-react-parser';

const MemoHtmlParser = ({ content, classes }) => {
  return (
    <div className={'content-box memo-formatting ' + classes}>
      {parse(content.replace(/contenteditable="false"/, ''))}
    </div>
  );
};

export default MemoHtmlParser;
