import {
  GET_KYC_SUBMITTED_DOCUMENTS_ATTEMPT,
  GET_KYC_SUBMITTED_DOCUMENTS_FAILURE,
  GET_KYC_SUBMITTED_DOCUMENTS_SUCCESS,
  DOWNLOAD_KYC_TEMPLATE_ATTEMPT,
  DOWNLOAD_KYC_TEMPLATE_FAILURE,
  DOWNLOAD_KYC_TEMPLATE_SUCCESS,
  FINISH_INVESTMENT_ATTEMPT,
  FINISH_INVESTMENT_FAILURE,
  FINISH_INVESTMENT_SUCCESS,
  SAVE_DOCUMENTS_ATTEMPT,
  SAVE_DOCUMENTS_FAILURE,
  SAVE_DOCUMENTS_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  CLEAR_FILE,
  CLEAR_KYC_DOCUMENTS,
  CLEAR_TEMPLATE,
  CLEAR_FILES,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  submittedDocuments: null,
  template: null,
  saveDocumentsRes: null,
  finishInvestmentRes: null,
  attempting: false,
  errorCode: null,
  files: {},
};

export const KycSubmitReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DOCUMENTS_ATTEMPT:
      return {
        ...state,
        saveDocumentsRes: null,
        attempting: true,
        errorCode: null,
      };
    case SAVE_DOCUMENTS_FAILURE:
      return {
        ...state,
        saveDocumentsRes: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case SAVE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        saveDocumentsRes: action.saveDocumentsRes,
        errorCode: null,
        attempting: false,
      };
    case FINISH_INVESTMENT_ATTEMPT:
      return {
        ...state,
        finishInvestmentRes: null,
        attempting: true,
        errorCode: null,
      };
    case FINISH_INVESTMENT_FAILURE:
      return {
        ...state,
        finishInvestmentRes: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case FINISH_INVESTMENT_SUCCESS:
      return {
        ...state,
        finishInvestmentRes: action.finishInvestmentRes,
        errorCode: null,
        attempting: false,
      };
    case GET_KYC_SUBMITTED_DOCUMENTS_ATTEMPT:
      return {
        ...state,
        submittedDocuments: null,
        attempting: true,
        errorCode: null,
      };
    case GET_KYC_SUBMITTED_DOCUMENTS_FAILURE:
      return {
        ...state,
        submittedDocuments: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_KYC_SUBMITTED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        submittedDocuments: action.submittedDocuments,
        errorCode: null,
        attempting: false,
      };

    case DOWNLOAD_KYC_TEMPLATE_ATTEMPT:
      return {
        ...state,
        template: null,
        attempting: true,
        errorCode: null,
      };
    case DOWNLOAD_KYC_TEMPLATE_FAILURE:
      return {
        ...state,
        template: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case DOWNLOAD_KYC_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: action.template,
        errorCode: null,
        attempting: false,
      };
    case UPLOAD_FILE_SUCCESS:
      let files = { ...state.files };
      files[action.key] = action.imageId;

      return {
        ...state,
        files: files,
      };
    case CLEAR_FILE:
      return {
        ...state,
        files: { ...state.files }.filter(function (key, value) {
          return key !== action.key;
        }),
      };
    case CLEAR_TEMPLATE:
      return {
        ...state,
        template: null,
      };
    case CLEAR_FILES:
      return {
        ...state,
        files: {},
      };
    case CLEAR_KYC_DOCUMENTS:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
