import React from 'react';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

// iAngels utils
import WingsLogo from '../../assets/iAngels_green_wings_logo.png';
import Text from '../../components/text';

const AffirmationDenied = () => {
  return (
    <Container
      style={{
        padding: 0,
        marginTop: 40,
        minHeight: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={3}
        style={{
          maxWidth: 700,
          borderRadius: 0,
          padding: '60px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={WingsLogo} className="pointer" alt="logo" style={{ marginBottom: 10 }} />
        <Text
          classes="f-f-tgf f-f-500 f-size-16 mt-20 g3-c"
          style={{ fontSize: 24 }}
          text="Change in your accreditation status"
        />
        <Text
          classes="f-f-g f-w-400 f-size-14 g4-c"
          style={{ marginTop: 10, maxWidth: 500, textAlign: 'center', lineHeight: 1.5 }}
          variant="body2 f-size-16"
          text="Due to the change in your accreditation status, we cannot enable you to continue the investment process."
        />
      </Paper>
      <Text
        style={{
          maxWidth: 500,
        }}
        text="If you clicked not accredited by mistake and would like to reinstate your accreditation status, please contact your portfolio manager directly or reach out to us via chat "
        extraText=" here."
        classes="g4-c mt-20 f-size-12 italic f-f-pmp"
        handleClick={() => window.Intercom('show')}
        extraClasses="g3-c pointer"
      />
    </Container>
  );
};

export default AffirmationDenied;
