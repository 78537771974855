import { createSelector } from 'reselect';

const getUpdateVerificationAttempting = (state) => state.emailVerification.attempting;
const getUpdateVerificationSuccess = (state) => state.emailVerification.updateEmailVerification;
const getUpdateVerificationlError = (state) => state.emailVerification.errorCode;

export const getUpdateVerificationAttemptingSelector = createSelector(
  getUpdateVerificationAttempting,
  (attempting) => attempting
);
export const getUpdateVerificationSuccessSelector = createSelector(
  getUpdateVerificationSuccess,
  (updateEmailVerification) => updateEmailVerification
);
export const getUpdateVerificationlErrorSelector = createSelector(
  getUpdateVerificationlError,
  (errorCode) => errorCode
);
