export const GET_KYC_SUBMITTED_DOCUMENTS_ATTEMPT = 'GET_KYC_SUBMITTED_DOCUMENTS_ATTEMPT';
export const GET_KYC_SUBMITTED_DOCUMENTS_FAILURE = 'GET_KYC_SUBMITTED_DOCUMENTS_FAILURE';
export const GET_KYC_SUBMITTED_DOCUMENTS_SUCCESS = 'GET_KYC_SUBMITTED_DOCUMENTS_SUCCESS';

export const DOWNLOAD_KYC_TEMPLATE_ATTEMPT = 'DOWNLOAD_KYC_TEMPLATE_ATTEMPT';
export const DOWNLOAD_KYC_TEMPLATE_FAILURE = 'DOWNLOAD_KYC_TEMPLATE_FAILURE';
export const DOWNLOAD_KYC_TEMPLATE_SUCCESS = 'DOWNLOAD_KYC_TEMPLATE_SUCCESS';

export const SAVE_DOCUMENTS_ATTEMPT = 'SAVE_DOCUMENTS_ATTEMPT';
export const SAVE_DOCUMENTS_FAILURE = 'SAVE_DOCUMENTS_FAILURE';
export const SAVE_DOCUMENTS_SUCCESS = 'SAVE_DOCUMENTS_SUCCESS';

export const FINISH_INVESTMENT_ATTEMPT = 'FINISH_INVESTMENT_ATTEMPT';
export const FINISH_INVESTMENT_FAILURE = 'FINISH_INVESTMENT_FAILURE';
export const FINISH_INVESTMENT_SUCCESS = 'FINISH_INVESTMENT_SUCCESS';

export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';

export const CLEAR_FILE = 'CLEAR_FILE';

export const CLEAR_FILES = 'CLEAR_FILES';
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_KYC_DOCUMENTS = 'CLEAR_KYC_DOCUMENTS';
