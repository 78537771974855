import React from 'react';

// mui utils
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';

// third party utils
import { useNavigate } from 'react-router-dom';

// iangels utils
import { getCloudinaryLogo } from '../../helpers/cloudinary';
import CardBadge from '../Generic/CardBadge';
import Text from '../text';

const MemoCompanyCard = (props) => {
  const navigate = useNavigate();

  const getCompanyLogo = () => {
    return getCloudinaryLogo(props.card?.logo, 80);
  };

  const getCompanyOnClick = () => {
    return props.card?.portfolioCompany
      ? '/startup/' + props.card.portfolioCompany.slug
      : '/child-startup/' + props.card?.slug;
  };

  const getCompanyName = () => {
    return props.card?.portfolioCompany ? props.card.portfolioCompany.name : props.card?.name;
  };

  return (
    <div style={{ width: 165, marginBottom: 15 }}>
      <Card className="memo-company-card">
        <CardActionArea onClick={() => navigate(getCompanyOnClick())} className="memo-company-action">
          <CardBadge
            badgeText={
              props.card?.acquired || props.card?.portfolioCompany?.acquired
                ? 'Acquired'
                : props.card?.portfolioCompany && !props.card?.portfolioCompany.acquired && !props.card?.acquired
                ? 'Follow-on'
                : ''
            }
            badgeClass="badge-green badge-reduce-text"
          />
          <div style={{ width: '100%' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img src={getCompanyLogo()} alt="company-logo" className="memo-company-logo" />
            </div>
            <CardContent>
              <div className="memo-card-content">
                <Text variant="h6" classes="f-size-16" text={getCompanyName()} />
              </div>
            </CardContent>
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default MemoCompanyCard;
