import { call, all, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService } from '../../services/apiGateway';
import { getStartupPageSuccess, getStartupPageFailure } from './actions';

import { GET_STARTUP_PAGE_ATTEMPT } from './actionTypes';

function* getStartupPageSage(action) {
  try {
    const startupPage = yield call(
      getDataFromService,
      '/iangels/startups/' + (action.isChild ? 'child-startup-page' : 'startup-page') + `/${action.slug}`
    );
    yield put(getStartupPageSuccess(startupPage?.data));
  } catch (errorCode) {
    yield put(getStartupPageFailure(errorCode.response || errorCode));
  }
}

function* startupPageSage() {
  yield all([takeLatest(GET_STARTUP_PAGE_ATTEMPT, getStartupPageSage)]);
}

export default startupPageSage;
