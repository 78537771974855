import {
  SUBMIT_FORM_ATTEMPT,
  SUBMIT_FORM_FAILURE,
  SUBMIT_FORM_SUCCESS,
  GET_SECTORS_ATTEMPT,
  GET_SECTORS_SUCCESS,
  GET_SECTORS_FAILURE,
  GET_PRIMARY_SECTORS_ATTEMPT,
  GET_PRIMARY_SECTORS_SUCCESS,
  GET_PRIMARY_SECTORS_FAILURE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  submitFormRes: null,
  sectors: null,
  primarySectors: null,
  attempting: false,
  errorCode: null,
};

export const FounderPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_FORM_ATTEMPT:
      return {
        ...state,
        submitFormRes: null,
        attempting: true,
        errorCode: null,
      };
    case SUBMIT_FORM_FAILURE:
      return {
        ...state,
        submitFormRes: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        submitFormRes: action.submitFormRes,
        errorCode: null,
        attempting: false,
      };
    case GET_SECTORS_ATTEMPT:
      return {
        ...state,
        sectors: null,
        attempting: true,
        errorCode: null,
      };
    case GET_SECTORS_FAILURE:
      return {
        ...state,
        sectors: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_SECTORS_SUCCESS:
      return {
        ...state,
        sectors: action.sectors,
        errorCode: null,
        attempting: false,
      };
    case GET_PRIMARY_SECTORS_ATTEMPT:
      return {
        ...state,
        primarySectors: null,
        attempting: true,
        errorCode: null,
      };
    case GET_PRIMARY_SECTORS_FAILURE:
      return {
        ...state,
        primarySectors: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_PRIMARY_SECTORS_SUCCESS:
      return {
        ...state,
        primarySectors: action.primarySectors,
        errorCode: null,
        attempting: false,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
