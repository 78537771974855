import React from 'react';
import { getCommaSeparatedString } from '../../helpers/iangels';

// mui utils
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';

// iangels utils
import Text from '../text';

import AccordionItem from '../Accordion/AccordionItem';
import AccordionItemTitle from '../Accordion/AccordionItemTitle';
import AccordionItemValue from '../Accordion/AccordionItemValue';
import EntityName from '../Accordion/EntityName';
import IsMobileDevice from '../Generic/IsMobileDevice';

const SummaryRow = (props) => {
  const {
    total,
    totalCommitted,
    lastValue,
    change,
    cashBalance,
    totalTitle,
    changeTitle,
    valueTitle,
    showTotalText,
    icon,
    title,
    subTitle,
    hasTableTitle,
    entityName,
    classes,
    setOpen,
    open,
    accordionExpanded,
    iconDisabled,
  } = props;
  const isMobile = IsMobileDevice();
  const classCell = `${hasTableTitle ? 'pt-5 ' : ' '}  ${classes}  border-none`;
  const classCellSmall = `${classCell} w-13  pb-5`;
  const classCellLarge = 'w-20 pb-0' + classCell;
  const classCellXLarge = 'w-25 pb-0' + classCell;
  const getDesktopSum = () => {
    return (
      <>
        {isMobile ? null : (
          <TableRow style={{ height: hasTableTitle ? 45 : '' }}>
            {getFirstCell(2)}
            <TableCell className={classCellLarge}></TableCell>

            <TableCell className={classCellSmall}>
              {totalCommitted ? (
                <AccordionItemTitle title="Total committed" />
              ) : cashBalance ? (
                <AccordionItemTitle title="Cash Balance" />
              ) : (
                <AccordionItemTitle title="" />
              )}
            </TableCell>
            <TableCell className={classCellSmall}>
              {totalTitle ? <AccordionItemTitle title={totalTitle} /> : ''}
            </TableCell>
            <TableCell className={classCellSmall}>
              {valueTitle ? <AccordionItemTitle title={valueTitle} /> : ''}
            </TableCell>
            <TableCell className={classCellSmall}>
              {changeTitle ? <AccordionItemTitle title={changeTitle} /> : ''}
            </TableCell>
            <TableCell className={classCellSmall} rowSpan={2}>
              {setOpen ? (
                <AccordionItem>
                  <Button
                    aria-label="expand row"
                    className="f-gl-400 f-size-14 lh-18 g3-c"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <>
                        <Text variant="body" text="Close Table" classes="nowrap" />
                        <KeyboardArrowUpIcon />
                      </>
                    ) : (
                      <>
                        <Text variant="body" text="Open Table" classes="nowrap" />
                        <KeyboardArrowDownIcon />
                      </>
                    )}
                  </Button>
                </AccordionItem>
              ) : (
                <AccordionItem className={iconDisabled ? 'pr-52' : ''}>
                  {
                    <Button aria-label="expand row" className="f-gl-400 f-size-14 w-c nowrap" size="small">
                      {iconDisabled ? null : (
                        <>
                          <Text variant="body" text={accordionExpanded ? 'Close all' : 'Open all'} classes=" pr-20" />
                          {accordionExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </>
                      )}
                    </Button>
                  }
                </AccordionItem>
              )}
            </TableCell>
          </TableRow>
        )}
        <TableRow className={showTotalText && isMobile ? 'gr-bg ' : ''} style={{ height: hasTableTitle ? 45 : '' }}>
          {isMobile ? getFirstCell(1) : <TableCell className={classCellLarge}></TableCell>}
          {isMobile ? null : (
            <TableCell className={classCellSmall}>
              {totalCommitted ? (
                <AccordionItemValue subTitle={'$' + getCommaSeparatedString(totalCommitted)} />
              ) : cashBalance ? (
                <AccordionItemValue subTitle={'$' + getCommaSeparatedString(cashBalance)} />
              ) : (
                <AccordionItemValue />
              )}
            </TableCell>
          )}
          <TableCell className={classCellSmall}>
            {!isNaN(total) ? <AccordionItemValue subTitle={'$' + getCommaSeparatedString(total)} /> : null}
          </TableCell>
          <TableCell className={classCellSmall}>
            {!isNaN(lastValue) ? <AccordionItemValue subTitle={'$' + getCommaSeparatedString(lastValue)} /> : null}
          </TableCell>
          <TableCell className={classCellSmall}>
            {changeTitle ? <AccordionItemValue subTitle={(change || 0)?.toString() + '%'} /> : null}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const getFirstCell = (rowspan) => {
    return (
      <TableCell className={`d-f fdr-r ${isMobile ? ' f-size-12 ' : ' nowrap '} ${classCellXLarge} `} rowSpan={rowspan}>
        {entityName && <EntityName name={entityName}></EntityName>}
        <br className="hidden" />
        {showTotalText ? (
          <AccordionItem className="p-10">
            <Text
              classes={`${isMobile ? 'f-size-12' : 'f-size-16 mt-28'}  f-f-tgf f-w-500 lh-22`}
              text={`Total ${isMobile ? subTitle.replace(/ /g, '\n') : ''}`}
            />
          </AccordionItem>
        ) : icon ? (
          <AccordionItem className="p-10 pt-0">
            {hasTableTitle ? (
              <span className="toPdf nowrap">
                <img src={icon} alt="entityName icon" margin={0} />
                <span className="hidden">iAngels&trade; {subTitle?.replace(/^iAngels/, '')}</span>
              </span>
            ) : isMobile ? null : (
              <img src={icon} alt="active" height={50} width={50} />
            )}
            <Text
              variant="h6"
              classes="f-w-400 f-f-tgf f-size-16 pt-10 "
              text={title}
              style={{ marginLeft: 10, marginTop: 15 }}
            />
          </AccordionItem>
        ) : (
          <AccordionItem className="p-10 pt-0"> </AccordionItem>
        )}
      </TableCell>
    );
  };
  return getDesktopSum();
};

export default SummaryRow;
