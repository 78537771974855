import {
  RESEND_EMAIL_ATTEMPT,
  RESEND_EMAIL_FAILURE,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_CLEAR,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  errorCode: null,
  attempting: false,
  resendEmail: null,
};

export const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESEND_EMAIL_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        resendEmail: null,
      };
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        attempting: false,
        errorCode: null,
        resendEmail: action.resendEmail,
      };

    case RESEND_EMAIL_FAILURE:
      return {
        ...state,
        attempting: false,
        resendEmail: null,
        errorCode: action.errorCode,
      };
    case RESEND_EMAIL_CLEAR:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
