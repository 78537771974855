import { createSelector } from 'reselect';

const getIdentityDetailsAttempting = (state) => state.identityDetails.attempting;
const getIdentityDetails = (state) => state.identityDetails.identityDetails;
const getUpdateSuccess = (state) => state.identityDetails.updateSuccess;
const getError = (state) => state.identityDetails.errorCode;
const getAvatar = (state) => state.identityDetails.avatar;

export const getIdentityDetailsAttemptingSelector = createSelector(
  getIdentityDetailsAttempting,
  (attempting) => attempting
);

export const getIdentityDetailsSelector = createSelector(getIdentityDetails, (identityDetails) => identityDetails);
export const getUpdateSuccessSelector = createSelector(getUpdateSuccess, (updateSuccess) => updateSuccess);
export const getErrorSelector = createSelector(getError, (errorCode) => errorCode);
export const getAvatarSelector = createSelector(getAvatar, (avatar) => avatar);
