import React from 'react';

// iangels utils
import CompanyPageDesktop from './CompanyPageDesktop';
import CompanyPageMobile from './CompanyPageMobile';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

const CompanyPage = ({ slug }) => {
  const isMobile = IsMobileDevice();

  return <>{isMobile ? <CompanyPageMobile slug={slug} /> : <CompanyPageDesktop slug={slug} isMobile={isMobile} />}</>;
};

export default CompanyPage;
