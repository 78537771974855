import { call, put, all, takeLatest } from 'redux-saga/effects'
import { SYNC_ATTEMPT } from './actionTypes';
import { syncSuccess, syncFailure } from './actions';
import { getDataFromService } from '../../services/apiGateway';

function* syncAttempt(action) {
    try {
        const response = yield call(getDataFromService, 'images/sync-objects');
        yield put(syncSuccess(response.statusText));
    }
    catch (errorCode) {
        yield put(syncFailure(errorCode.response || errorCode));
    }
}

function* syncSagas() {
    yield all([takeLatest(SYNC_ATTEMPT, syncAttempt)]);
}

export default syncSagas;