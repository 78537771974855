import React from 'react';
import { useParams } from 'react-router-dom';

import Logo from '../../assets/iAngels-black-logo-small.png';

// iangels utils
import Text from '../../components/text';

const AccreditationCountries = {
  IL: (
    <div style={{ maxWidth: 800 }}>
      <div className="mt-20 justify">
        Under Israeli law, an Accredited Investor meets at least one of the criteria below:
      </div>
      <ul className="justify" style={{ lineHeight: 1.5 }}>
        <li className="mt-5">
          The total value of my cash, deposits, financial assets and securities exceeds NIS 8,095,444
        </li>
        <li className="mt-5">My income was NIS 1,214,317 or more in each of the last two years</li>
        <li className="mt-5">My spouse and I had income of NIS 1,821,475 or more in each of the last two years</li>
        <li className="mt-5">
          The total value of my cash, deposits, financial assets and securities exceeds NIS 5,059,652, AND in each of
          the last two years either: (1) my income was NIS 607,158 or more, or (2) my spouse and I had income of of NIS
          910,737 or more
        </li>
      </ul>
    </div>
  ),
  US: (
    <div style={{ maxWidth: 800 }}>
      <div className="mt-20 justify">
        Under US law, an Accredited Investor meets at least one of the criteria below:
      </div>
      <ul className="justify" style={{ lineHeight: 1.5 }}>
        <li className="mt-5">
          My income was $200,000 or more in each of the two last years and I expect the same income level this year
        </li>
        <li className="mt-5">
          My spouse and I had an income that exceeded $300,000 in each of the last 2 years and we expect the same joint
          income level this year
        </li>
        <li className="mt-5">
          Excluding my primary residence, my net worth (or joint net worth with my spouse) exceeds $1,000,000 excluding
          my primary residence
        </li>
      </ul>
    </div>
  ),
  AU: (
    <div style={{ maxWidth: 800 }}>
      <div className="mt-20 justify">
        Under Australian law, a sophisticated investor meets at least one of the criteria below:
      </div>
      <ul className="justify" style={{ lineHeight: 1.5 }}>
        <li className="mt-5">My net assets are at least AUD$2.5 million</li>
        <li className="mt-5">My gross income for each of the last 2 financial years was at least AUD$250,000</li>
      </ul>
    </div>
  ),
};

const Accreditation = () => {
  const params = useParams();

  const accreditationStatement = params?.countryCode ? AccreditationCountries[params.countryCode] : null;
  return (
    <div style={{ padding: 30 }}>
      <img src={Logo} className="pointer" alt="logo" style={{ marginBottom: 10 }} />
      <Text classes="f-tgf-400 mt-20 g-c " style={{ fontSize: 20 }} text="Accreditation Requirements" />
      <div className="mt-20 justify" style={{ maxWidth: 800 }}>
        I understand that iAngels is providing me access to opportunities based on the accuracy of my accreditation
        statement.
      </div>
      {accreditationStatement}
    </div>
  );
};

export default Accreditation;
