import React from 'react';

// mui utils
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableContainer from '@mui/material/TableContainer';
import Container from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';

// third party utils
import { useSelector } from 'react-redux';
import cloudinary from 'cloudinary-core';
import moment from 'moment';

// iangels utils

import { documentsTypeNamesMap } from '../../helpers/constants';
import { getAuthResultSelector } from '../App/selectors';
import { loadConfig } from '../..//env/config_util';
import Text from '../../components/text';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

const DocumentsTable = ({ filteredDocuments, matches }) => {
  //hooks
  const isMobile = IsMobileDevice();
  const config = loadConfig();
  const authResult = useSelector(getAuthResultSelector);

  const getUrlByType = (url, groupType, isCloudinary) => {
    if (!url) {
      return;
    }

    if (isCloudinary) {
      return cloudinary.Cloudinary.new({
        cloud_name: config.config_module.cloudinaryCloudName,
      }).url(url);
    }

    return `${url}?access_token=${authResult.__raw}`;
  };

  const viewDocUrl = (fileElement) => {
    const viewLink = !fileElement.missingDocument
      ? fileElement.url && getUrlByType(fileElement.url, fileElement.type, fileElement.isCloudinary)
      : fileElement.templateUrl &&
      getUrlByType(fileElement.templateUrl, fileElement.type, fileElement.templateIsCloudinary);

    fetch(viewLink)
      .then(function (response) {
        return response.blob();
      })
      .then(function (myBlob) {
        var fileURL = URL.createObjectURL(myBlob);
        window.open(fileURL);
      });
  };

  const downloadDocUrl = (fileElement) => {
    const link = document.createElement('a');

    link.href = !fileElement.missingDocument
      ? fileElement.downloadUrl && getUrlByType(fileElement.downloadUrl, fileElement.type, fileElement.isCloudinary)
      : fileElement.templateDownloadUrl &&
      getUrlByType(fileElement.templateDownloadUrl, fileElement.type, fileElement.templateIsCloudinary);

    link.click();
  };

  const isPending = (row) => {
    return row.isPending;
  }

  const getRowDate = (row) => {
    return (
      row.dateCreated &&
      (row.type === 'k1' || row.type === 'tax_letter'
        ? moment(row.dateCreated).format('YYYY')
        : row.type === 'capital_statements_venture' || row.type === 'financial_statements_venture' || row.type === 'quarterly_updates'
          ? 'Q' + moment(row.dateCreated).quarter() + ', ' + moment(row.dateCreated).format('YYYY')
          : moment(row.dateCreated).format('MMM, YYYY'))
    );
  };
  return (
    <Container
      style={{
        padding: 0,
        marginTop: 40,
        width: '100%',
        minHeight: 800,
      }}
    >
      <Box>
        {Object.keys(filteredDocuments).map((key, index) => (
          <div style={{ marginBottom: 25 }} key={index}>
            <TableContainer component={Paper} style={{ borderRadius: 0, borderTop: '#4CBABB solid 3px' }}>
              <div style={{ padding: 15 }}>
                <Text classes="f-f-tgf f-w-500 g4-c f-size-16" text={documentsTypeNamesMap[key]} />
              </div>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="g1-bg">
                    <TableCell width="20%" className="f-w-700">
                      Company Name
                      {isMobile && <div>Round Name</div>}
                    </TableCell>
                    {!isMobile && (
                      <TableCell width="20%" className="f-w-700">
                        Round Name
                      </TableCell>
                    )}
                    <TableCell width="20%" className="f-w-700" style={{ verticalAlign: 'top' }}>
                      Entity
                      {isMobile && (
                        <div>
                          <br />
                          Date
                        </div>
                      )}
                    </TableCell>
                    {!isMobile && (
                      <TableCell width="20%" className="f-w-700">
                        Date
                      </TableCell>
                    )}
                    <TableCell width="10%"></TableCell>
                    <TableCell width="10%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredDocuments[key].map((row, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={`f-f-g ${isPending(row) ? ' gr-bg' : ''}`}>
                      <TableCell className="f-f-tgf f-w-500 f-size-12 g4-c">
                        {row.startup}
                        {isMobile && <div className="f-f-g f-w-400 f-size-12">{row.roundName}</div>}
                      </TableCell>
                      {!isMobile && <TableCell className="f-f-g f-w-400 f-size-12">{row.roundName}</TableCell>}
                      <TableCell className="f-f-g f-w-400 f-size-12">
                        {row.entity} {isMobile && <div>{getRowDate(row)}</div>}
                      </TableCell>
                      {!isMobile && <TableCell className="f-f-g f-w-400 f-size-12">{getRowDate(row)}</TableCell>}
                      <TableCell colspan={isPending(row) ? 2 : null}>
                        {isPending(row) ? `${documentsTypeNamesMap[key]} not yet uploaded` : <Button
                          variant="text"
                          style={{ color: 'black', minWidth: 10, padding: 0 }}
                          startIcon={<VisibilityIcon className="g-c" />}
                          onClick={() => viewDocUrl(row)}
                        >
                          {!isMobile && <span>VIEW</span>}
                        </Button>}
                      </TableCell>
                      {isPending(row) ? null : <TableCell>
                        <Button
                          variant="text"
                          style={{ color: 'black' }}
                          startIcon={<FileDownloadIcon className="g-c" />}
                          onClick={() => downloadDocUrl(row)}
                        >
                          {!isMobile && <span>DOWNLOAD</span>}
                        </Button>
                      </TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </Box>
    </Container>
  );
};

export default DocumentsTable;
