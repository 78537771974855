import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Text from '../text';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { track } from '../../services/analytics';

const MobileMenuItem = ({ title, sectionList, onClick, setAnchorElUser }) => {
  const classMenuItem = 'f-f-tgf f-w-500 f-size-12 g4-c';

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return onClick ? (
    <div className="mobileMenuItem mobileMenuLink">
      <Link
        onClick={() => {
          onClick();
          track('Top Nav', { category: 'Navigation', item: title });
          setAnchorElUser(false);
        }}
        className={classMenuItem}
        underline="none"
      >
        {title}
      </Link>
    </div>
  ) : (
    <Accordion className="mobileMenuItem" expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={expanded ? 'mobileMenuItemExpand' : ''}
      >
        <Text classes={classMenuItem} text={title} />
      </AccordionSummary>
      <AccordionDetails sx={{ my: 0, py: 0 }} className={expanded ? 'mobileMenuDetailsExpand' : ''}>
        {sectionList.map((item, index) =>
          item.shown ? (
            <ListItem
              button
              key={item.title}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => {
                sectionList[index].func();
                track(item.event, { category: 'Navigation', item: item.title });
                setAnchorElUser(false);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={<span className="f-f-gl f-w-600 f-size-14 g4-c">{item.title}</span>} />
            </ListItem>
          ) : null
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default MobileMenuItem;
